import {StockSearchDto} from '@typedefs/stock-rest';
import {WarehouseSearch, warehouseSearchByWarehouse} from '@model/search/warehouse-search';
import {ArticleSearch} from '@model/search/article-search';
import {Warehouse} from '@model/warehouse';
import { Zone } from "@model/zone";
import { Preparation } from "@model/preparation";

export interface StockSearch extends StockSearchDto {

  warehouseSearch?: WarehouseSearch;
  articleSearch?: ArticleSearch;
  preparationZone?: Zone
  excludedPreparation?: Preparation

}

export function stockSearchByWarehouse(warehouse: Warehouse | undefined): StockSearch {
  return {
    warehouseSearch: warehouseSearchByWarehouse(warehouse)
  };
}

export function stockSearchByLocationAndWarehouse(location: string, warehouse: Warehouse): StockSearch {
  return {
    location,
    warehouseSearch: warehouseSearchByWarehouse(warehouse)
  };
}

export function stockSearchByPreparationZoneAndWarehouse(zone: Zone, warehouse: Warehouse): StockSearch {
  return {
    preparationZone: zone,
    warehouseSearch: warehouseSearchByWarehouse(warehouse)
  };
}
