import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppLayoutComponent} from '@components/layout/app.layout.component';
import {UsersComponent} from '@components/users/users.component';
import {StockListComponent} from '@components/stock/stock-list.component';
import {ArticleListComponent} from '@components/article/article-list.component';
import {SupplierListComponent} from '@components/supplier/supplier-list.component';
import {FeadPlanningSummaryComponent} from '@components/fead-planning/summary/fead-planning-summary.component';
import {FeadPlanningOrganizationComponent} from '@components/fead-planning/organization/fead-planning-organization.component';
import {FeadPlanningArticleComponent} from '@components/fead-planning/article/fead-planning-article.component';
import {FeadPreparationListComponent} from '@components/fead/preparation/list/fead-preparation-list.component';
import {FeadPlanningComponent} from '@components/fead/planning/fead-planning.component';
import {FeadPreparationNewComponent} from '@components/fead/preparation/new/fead-preparation-new.component';
import {ReceptionListComponent} from '@components/reception/reception-list/reception-list.component';
import {FeadPreparationDetailsStandaloneComponent} from '@components/fead/preparation/detail/fead-preparation-details-standalone.component';
import {InterWarehouseReceptionComponent} from '@components/inter-warehouse-reception/inter-warehouse-reception.component';
import {PreparationNewComponent} from '@components/preparation/new/preparation-new.component';
import {MovementComponent} from "@components/movement/movement.component";

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent
  },
  {
    path: 'users/list',
    component: UsersComponent
  },
  {
    path: 'fead/planning/summary',
    component: FeadPlanningSummaryComponent
  },
  {
    path: 'fead/planning/organization',
    component: FeadPlanningOrganizationComponent
  },
  {
    path: 'fead/planning/article',
    component: FeadPlanningArticleComponent
  },
  {
    path: 'fead/planning',
    component: FeadPlanningComponent
  },
  {
    path: 'fead/preparation/list',
    component: FeadPreparationListComponent,
  },
  {
    path: 'fead/expedition/list',
    component: FeadPreparationListComponent,
    data: {
      expedition: true,
    }
  },
  {
    path: 'fead/preparation/new',
    component: FeadPreparationNewComponent,
  },
  {
    path: 'fead/preparation/:preparationId',
    component: FeadPreparationDetailsStandaloneComponent
  },
  {
    path: 'receptions/manual',
    component: ReceptionListComponent
  },
  {
    path: 'receptions/inter-warehouse',
    component: InterWarehouseReceptionComponent
  },
  {
    path: 'preparation/list',
    component: FeadPreparationListComponent,
  },
  {
    path: 'preparation/new',
    component: PreparationNewComponent,
  },
  {
    path: 'stocks/list',
    component: StockListComponent
  },
  {
    path: 'stocks/bulk/interwarehouse-transfer',
    component: StockListComponent,
    data: {
      bulkMovementActive: true,
      bulkMovementKind: 'INTER_WAREHOUSE_TRANSFER',
    }
  },
  {
    path: 'articles/list',
    component: ArticleListComponent
  },
  {
    path: 'articles/bulk/bulkfood',
    component: ArticleListComponent,
    data: {
      bulkMovementActive: true,
      bulkMovementKind: 'RECEIPT',
      bulkMovementCategory: 'BULK_FOOD',
    }
  },
  {
    path: 'suppliers/list',
    component: SupplierListComponent
  },
  {
    path: 'movements/list',
    component: MovementComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
        bindToComponentInputs: true
      }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
