import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {ZoneDto, ZoneSearchDto} from '@typedefs/stock-rest';
import {Zone} from '@model/zone';
import {ZoneSearch} from '@model/search/zone-search';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  constructor(private httpClient: HttpClient) {
  }

  find$(value: string): Observable<Zone> {
    return this.httpClient.get<ZoneDto>(`${environment.apiUrl}/zones/${value}`).pipe(shareReplay())
  }

  findZones$(zoneSearch: ZoneSearch): Observable<Zone[]> {
    const stockSearchDto = this.mapToZoneSearchDto(zoneSearch);
    return this.httpClient.post<Page<ZoneDto>>(`${environment.apiUrl}/zones/search`, stockSearchDto)
      .pipe(
        map(zonesPage => zonesPage.content),
        shareReplay()
      );
  }

  mapToZoneSearchDto(zoneSearch: ZoneSearch): ZoneSearchDto {
    return {
      warehouseId: zoneSearch?.warehouse?.id,
      companyId: zoneSearch?.company?.id,
      available: zoneSearch?.available
    }
  }
}
