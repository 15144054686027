<p-table #dt
         [value]="(supplierPage()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="supplierPage()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Suppliers</h2>
      <span class="report-block mt-2 md:mt-0 p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" placeholder="Search..." class="w-full sm:w-auto"/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="NAME">
        <span i18n>Name</span>
        <p-sortIcon field="NAME"></p-sortIcon>
      </th>
      <th pSortableColumn="ARTICLE_CATEGORY_ID">
        <span i18n>Category</span>
        <p-sortIcon field="ARTICLE_CATEGORY_ID"></p-sortIcon>
      </th>
      <th pSortableColumn="ESF_PLUS">
        <span i18n>ESF+</span>
        <p-sortIcon field="ESF_PLUS"></p-sortIcon>
      </th>
      <th pSortableColumn="EMAIL">
        <span i18n>📧 Email</span>
        <p-sortIcon field="EMAIL"></p-sortIcon>
      </th>
      <th pSortableColumn="PHONE">
        <span i18n>📞 Phone</span>
        <p-sortIcon field="PHONE"></p-sortIcon>
      </th>
      <th pSortableColumn="ADDRESS">
        <span i18n>🌍 Address</span>
        <p-sortIcon field="ADDRESS"></p-sortIcon>
      </th>
      <th pSortableColumn="COMPANY">
        <span i18n>🏭 Company</span>
        <p-sortIcon field="COMPANY"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th>
        <p-columnFilter field="suppliers" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <foodbank-suppliers-selection
              ngDefaultControl
              [(ngModel)]="selectedSuppliers"
              [supplierSearch]="supplierFilterSearch()"
            ></foodbank-suppliers-selection>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="categories" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <foodbank-supplier-category-selection
              ngDefaultControl
              [(ngModel)]="selectedSupplierCategories"
              [supplierCategorySearch]="supplierCategoryFilterSearch()"
            ></foodbank-supplier-category-selection>
          </ng-template>
        </p-columnFilter>
      </th>
      <th colspan="5">
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-supplier>
    <ng-container *ngIf="identity(supplier) as supplier">
      <tr>
        <td>
          <foodbank-supplier ngDefaultControl [ngModel]="supplier" [showId]="false"></foodbank-supplier>
        </td>
        <td>
          <foodbank-supplier-category
            ngDefaultControl
            *ngIf="supplier.supplierCategory$; else noCategory"
            [ngModel]="(supplier.supplierCategory$ | async) || undefined">
          </foodbank-supplier-category>
          <ng-template #noCategory>
            <span class="text-gray-500">No category</span>
          </ng-template>
        </td>
        <td>
          <p-checkbox [value]="supplier.fead" [disabled]="true"/>
        </td>
        <td>
          <a href="mailto:{{ supplier.email }}">{{ supplier.email }}</a>
        </td>
        <td>
          <ng-container *ngIf="supplier.phone">
            <a href="tel:{{ supplier.phone | phone}}">{{ supplier.phone }}</a>
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="getAddress(supplier) as address">
            <a href="http://maps.google.com/maps?q={{ address }}" target="_blank">
              {{ address }}
            </a>
          </ng-container>
        </td>
        <td>
          {{ supplier.companyId }}
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
