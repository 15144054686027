import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {Returnable} from '@model/returnable';
import {ReturnableSearch} from '@model/search/returnable-search';
import {ReturnableDto, ReturnableSearchDto} from '@typedefs/stock-rest';
import {BankService} from '@services/bank.service';

@Injectable({
  providedIn: 'root'
})
export class ReturnableService {

  constructor(private httpClient: HttpClient,
              private bankService: BankService) {
  }

  public getReturnable$(id: string): Observable<Returnable> {
    return this.httpClient.get<ReturnableDto>(`${environment.apiUrl}/returnables/${id}`)
      .pipe(
        map(returnableDto => this.mapToReturnable$(returnableDto)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  public findReturnable$(returnableSearch: ReturnableSearch, pagination?: Pagination): Observable<Page<Returnable>> {
    const returnableSearchDto = this.mapToReturnableSearchDto(returnableSearch);
    return this.httpClient.post<Page<ReturnableDto>>(`${environment.apiUrl}/returnables/search`, returnableSearchDto, {params: pagination})
      .pipe(map(returnablePage => this.loadReturnablePage(returnablePage)));
  }

  private loadReturnablePage(returnableDtoPage: Page<ReturnableDto>): Page<Returnable> {
    return {
      ...returnableDtoPage,
      content: this.loadReturnableDetailsList(returnableDtoPage.content)
    };
  }

  public loadReturnableDetailsList(returnableDtos: ReturnableDto[]) {
    return returnableDtos.map(returnable => this.mapToReturnable$(returnable));
  }

  updateReturnable(returnable: ReturnableDto): Observable<ReturnableDto> {
    return this.httpClient.put<ReturnableDto>(`${environment.apiUrl}/returnables/${returnable.id}`, returnable)
  }

  public mapToReturnable$(returnableDto: ReturnableDto): Returnable {
    return {
      ...returnableDto,
      bank$: this.bankService.getBank$(returnableDto.bankId),
    };
  }

  mapToReturnableSearchDto(returnableSearch: ReturnableSearch): ReturnableSearchDto {
    return {
      nameContains: returnableSearch.nameContains,
      companyId: returnableSearch.company?.id,
      returnableId: returnableSearch.returnable?.id,
    }
  }

}
