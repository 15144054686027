import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {SupplierSearch} from '@model/search/supplier-search';
import {Page} from '@typedefs/page';
import {SupplierDto, SupplierSearchDto} from '@typedefs/stock-rest';
import {Supplier} from '@model/supplier';
import {SupplierCategoryService} from '@services/supplier-category.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private httpClient: HttpClient,
              private supplierCategoryService: SupplierCategoryService) {
  }

  public getSupplier$(id: string): Observable<Supplier> {
    return this.httpClient.get<SupplierDto>(`${environment.apiUrl}/suppliers/${id}`)
      .pipe(map(supplierDto => this.loadSupplier(supplierDto)));
  }

  public findSuppliers$(supplierSearch: SupplierSearch, pagination?: Pagination): Observable<Page<Supplier>> {
    const supplierSearchDto = this.convertToSearchDto(supplierSearch);
    return this.httpClient.post<Page<SupplierDto>>(`${environment.apiUrl}/suppliers/search`, supplierSearchDto, {params: pagination})
      .pipe(map(supplierPage => this.loadSupplierPage(supplierPage)));
  }

  public convertToSearchDto(supplierSearch: SupplierSearch): SupplierSearchDto {
    return {
      nameContains: supplierSearch.nameContains,
      supplierCategorySearchDto: supplierSearch.supplierCategorySearch && this.supplierCategoryService.convertToSearchDto(supplierSearch.supplierCategorySearch),
      supplierIds: supplierSearch.suppliers?.map(supplier => supplier.id),
      companyId: supplierSearch.company?.id,
      includeCountrySuppliers: supplierSearch.includeCountrySuppliers,
      includeESFPlus: supplierSearch.includeESFPlus
    };
  }

  private loadSupplierPage(supplierPage: Page<SupplierDto>): Page<Supplier> {
    return {
      ...supplierPage,
      content: supplierPage.content.map(supplier => this.loadSupplier(supplier))
    };
  }

  public loadSupplier(supplierDto: SupplierDto): Supplier {
    return {
      ...supplierDto,
      supplierCategory$: supplierDto.supplierCategoryId ? this.supplierCategoryService.findSupplierCategory$(supplierDto.supplierCategoryId) : undefined
    };
  }
}
