import {Component, computed, effect, inject, input, model, signal, Signal, WritableSignal} from '@angular/core';
import {ArticleService} from '@services/article.service';
import {of, pipe, switchMap} from "rxjs";
import {TableLazyLoadEvent} from "primeng/table";
import {Page} from '@typedefs/page';
import {ArticleSearch} from '@model/search/article-search';
import {Article} from '@model/article';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ArticleEditComponent} from '@components/article/article-edit/article-edit.component';
import {FeadCampaignComponent} from '@components/fead-campaign/fead-campaign.component';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Pagination} from '@services/pagination';
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {ArticleStorageCondition} from "@model/article-storage-condition";
import {ArticleCategory} from "@model/article-category";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {SupplierSearch} from "@model/search/supplier-search";
import {MovementType} from "@model/movement-type";
import {Supplier} from "@model/supplier";
import {Warehouse} from "@model/warehouse";
import {UserService} from "@services/user.service";
import {Movement} from "@model/movement";
import {MovementKind} from "@typedefs/stock-rest";
import {toSignal} from "@angular/core/rxjs-interop";
import {MovementTypeSearch} from "@model/search/movement-type-search";
import {MovementCategory} from "@model/movement-category";
import {MovementTypeService} from "@services/movement-type.service";
import {MessageService} from "primeng/api";
import {StockAdjustment} from "@model/stock-adjustment";
import {StockAdjustmentService} from "@services/stock-adjustment.service";
import {ArticleStorageConditionSearch} from "@model/search/article-storage-condition-search";
import {ArticleCategorySearch} from "@model/search/article-category-search";

@Component({
  selector: 'foodbank-articles',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss'],
  providers: [DialogService]
})
export class ArticleListComponent {

  bulkMovementType = model<MovementType>();
  bulkMovementKind = input<MovementKind>();
  bulkMovementCategory = model<MovementCategory>();
  bulkMovementActive = model(false);
  bulkMovementNeedsTargetWarehouse: Signal<boolean>;
  bulkMovementNeedsSupplier: Signal<boolean>;
  bulkMovementWarehouse = model<Warehouse>();
  bulkMovementTargetWarehouse = model<Warehouse>();
  bulkMovementComment = model<string>();
  bulkMovementSupplier = model<Supplier>();
  bulkMovementDate = model(new Date());

  bulkMovementTypeSearch: Signal<MovementTypeSearch>;
  bulkMovementWarehouseSearch: Signal<WarehouseSearch>;
  bulkMovementTargetWarehouseSearch: Signal<WarehouseSearch>;
  bulkMovementSupplierSearch: Signal<SupplierSearch>;

  bulkMovementValid: Signal<boolean>;

  pagination: WritableSignal<Pagination>;
  articlePage: Signal<Page<Article> | undefined>;

  articleSearch: Signal<ArticleSearch>;

  bulkFood = signal(false);

  filterArticlesSearch: Signal<ArticleSearch>;
  filterArticleCategorySearch: Signal<ArticleCategorySearch>;
  filterArticleStorageConditionSearch: Signal<ArticleStorageConditionSearch>;

  articlesFilter = signal<Article[]>([]);
  articleStorageConditionsFilter = signal<ArticleStorageCondition[]>([]);
  articleCategoriesFilter = signal<ArticleCategory[]>([]);

  #dialogRef?: DynamicDialogRef;
  #articleService = inject(ArticleService);
  #dialogService = inject(DialogService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #stockAdjustmentService = inject(StockAdjustmentService);
  #movementTypeService = inject(MovementTypeService);

  #messageService = inject(MessageService);

  constructor() {
    const defaultPagination = this.#paginationService.getDefaultPagination();
    this.pagination = signal(defaultPagination);

    this.articleSearch = computed(() => ({
      articles: this.articlesFilter(),
      articleCategorySearch: {
        articleCategories: this.articleCategoriesFilter(),
      },
      articleStorageConditionSearch: {
        articleStorageConditions: this.articleStorageConditionsFilter(),
      },
      bulkFood: this.bulkFood()
    }));

    const articleSearchParams: Signal<[ArticleSearch, Pagination]> = computed(() => [this.articleSearch(), this.pagination()]);
    this.articlePage = pipeSignal(articleSearchParams, pipe(
      switchMap(([articleSearch, pagination]) => this.#articleService.findArticles$(articleSearch, pagination))
    ));

    this.filterArticlesSearch = computed(() => ({
      bulkFood: this.bulkFood(),
      articleCategorySearch: {
        articleCategories: this.articleCategoriesFilter(),
      },
      articleStorageConditionSearch: {
        articleStorageConditions: this.articleStorageConditionsFilter(),
      },
    }));

    this.filterArticleCategorySearch = computed(() => ({
    }));

    this.filterArticleStorageConditionSearch = computed(() => ({
    }));

    const currentUserCompany$ = this.#userService.getCurrentUserCompany$();
    const currentUserCompany = toSignal(currentUserCompany$);

    this.bulkMovementTypeSearch = computed(() => ({
      forBulkFood: this.bulkFood()
    }));

    this.bulkMovementWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.bulkMovementTargetWarehouseSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.bulkMovementSupplierSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.bulkMovementNeedsTargetWarehouse = computed(() => {
      const movementKind = this.bulkMovementType()?.movementKind;
      return movementKind === 'INTER_WAREHOUSE_TRANSFER';
    });

    this.bulkMovementNeedsSupplier = computed(() => {
      const movementKind = this.bulkMovementType()?.movementKind;
      return movementKind === 'RECEIPT' || movementKind === 'RECEIPT_CORRECTION';
    });

    this.bulkMovementValid = computed(() => {
      return !!(
        this.bulkMovementType() &&
        this.bulkMovementWarehouse() &&
        (!this.bulkMovementNeedsSupplier() || this.bulkMovementSupplier()) &&
        (!this.bulkMovementNeedsTargetWarehouse() || this.bulkMovementTargetWarehouse())
        // &&        (!this.bulkMovementNeedsOrganization || this.bulkMovementOrganization())
      );
    });

    effect(() => {
      const movementKind = this.bulkMovementKind();
      if (movementKind) {
        this.#movementTypeService.getMovementType$(movementKind)
          .subscribe(inputMovementType => this.bulkMovementType.set(inputMovementType));
      }
    });

    effect(() => {
      const bulkFood = this.bulkMovementCategory() == 'BULK_FOOD';
      this.bulkFood.set(bulkFood);
    }, {allowSignalWrites: true});
  }

  openEditDialog(article: Article) {
    this.#dialogRef = this.#dialogService.open(ArticleEditComponent, {
      header: 'Edit article',
      width: '40%',
      data: {...article}
    });

    this.#dialogRef.onClose.subscribe(_ => this.reload());
  }

  openFeadCampaignDialog(article: Article) {
    this.#dialogRef = this.#dialogService.open(FeadCampaignComponent, {
      header: 'Fead campaign ' + article.id,
      width: '70%',
      data: {...article}
    });

    this.#dialogRef.onClose.subscribe(_ => this.reload());
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  private reload() {
    // reinstantiate any dependency to retrigger a search
    this.pagination.set({...this.pagination()});
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  saveArticleQuantity(article: Article) {
    if (!article.quantity || !this.bulkMovementValid()) {
      return;
    }
    const movementType = this.bulkMovementType();
    const warehouse = this.bulkMovementWarehouse();
    if (!movementType || !warehouse) {
      return;
    }
    const stockAdjustment: StockAdjustment = {
      quantity: article.quantity,
      movementType$: of(movementType),
      targetWarehouse$: of(this.bulkMovementTargetWarehouse()),
      supplier$: of(this.bulkMovementSupplier()),
      organization$: of(undefined), // TODO implement (needed for Expedition only)
      comment: this.bulkMovementComment(),
      dateTime: this.bulkMovementDate()
    };
    this.#stockAdjustmentService.createStockAdjustmentForArticle(article, warehouse, stockAdjustment)
      .subscribe(movement => this.#notifyMovement(movement));
  }

  #notifyMovement(movement: Movement) {
    this.#messageService.add({severity: 'success', summary: 'Bulk movements created', detail: `Created movement ${movement.id}.`});
  }

  typing(article: Article): Article {
    return article;
  }
}
