<p-dropdown [ngModel]="ngModel"
            (onChange)="select($event.value)"
            [options]="(suppliers$ | async) || []"
            optionLabel="name"
            placeholder="None"
            [required]="required"
            [filter]="true"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="id"
            appendTo="body">
  <ng-template pTemplate="filter">
    <input type="text" pInputText pAutoFocus [autofocus]="true" placeholder="search"
           [(ngModel)]="searchFilter"
           (keyup)="handleSearchFilter()"/>
  </ng-template>
  <ng-template pTemplate="selectedItem">
    <foodbank-supplier ngDefaultControl [ngModel]="ngModel"></foodbank-supplier>
  </ng-template>
  <ng-template let-supplier pTemplate="item">
    <foodbank-supplier ngDefaultControl [ngModel]="supplier"></foodbank-supplier>
  </ng-template>
</p-dropdown>
