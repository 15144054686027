import {Page} from "@typedefs/page";

export function enrichListWithSelection<T>(items: T[], selectedItem?: T, comparator?: (a: T, b: T) => boolean): T[] {
  if (selectedItem) {
    const included = comparator ? items.some(item => comparator(item, selectedItem)) : items.includes(selectedItem);
    if (!included) {
      items.unshift(selectedItem);
    }
  }
  return items;
}

export function enrichPageWithSelection<T>(page: Page<T>, selectedItem?: T, comparator?: (a: T, b: T) => boolean): Page<T> {
  const items = page.content;
  const itemsWithSelection = enrichListWithSelection(items, selectedItem, comparator);
  return {
    ...page,
    content: itemsWithSelection,
  };
}
