import { map, Observable, of, take } from "rxjs";
import { PreparationStockSelection } from "@model/preparation-stock-selection";

export interface PreparationSelectionItem extends PreparationStockSelection {
  valid$: Observable<boolean>;
}

export function toPreparationSelectionItem(preparationSelection: PreparationStockSelection, proposePalletWeights = true): Observable<PreparationStockSelection> {
  if (!proposePalletWeights) {
    return of(preparationSelection)
  }

  return preparationSelection.stock$.pipe(
    take(1),
    map(stock => {
      const dispatchWeight =  stock.quantity;
      const dispatchUnitCount = dispatchWeight * 1000 / stock.unitWeight;
      const dispatchParcelCount = dispatchUnitCount / stock.unitsPerParcel;
      return {
        ...preparationSelection,
        dispatchWeight,
        dispatchUnitCount,
        dispatchParcelCount
      };
    })
  );
}
