import {Component, Input, model} from '@angular/core';
import {MovementType} from "@model/movement-type";

@Component({
  selector: 'foodbank-movement-type',
  templateUrl: './movement-type.component.html',
  styleUrls: ['./movement-type.component.scss']
})
export class MovementTypeComponent {

  ngModel = model<MovementType>();
}
