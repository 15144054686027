<span [ngSwitch]="userType" [class]="userType" [pTooltip]="userType">
  <ng-container *ngSwitchCase="'Bank'">
    <span class="material-icons">account_balance</span>
  </ng-container>
  <ng-container *ngSwitchCase="'Admin_Banq'">
    <span class="material-icons" >admin_panel_settings</span>
  </ng-container>
  <ng-container *ngSwitchCase="'Asso'">
    <span class="material-icons" >diversity_1</span>
  </ng-container>
  <ng-container *ngSwitchCase="'Admin_Asso'">
    <span class="material-icons" >diversity_2</span>
  </ng-container>
<!--  <span class="material-icons" *ngSwitchCase="'Admin_Bank'">diversity_1</span>-->
  <ng-container *ngSwitchDefault>{{ userType }}</ng-container>
</span>
<i class="fa-light fa-chevron-down"></i>
