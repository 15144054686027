import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FeadCampaignService } from '@services/fead-campaign.service';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { Page } from '@typedefs/page';
import { FeadCampaign } from '@model/fead-campaign';
import { FeadCampaignSearch } from '@model/search/fead-campaign-search';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: 'app-fead-campaign',
  templateUrl: './fead-campaign.component.html',
  styleUrls: ['./fead-campaign.component.scss']
})
export class FeadCampaignComponent {

  public feadCampaignPage$: Observable<Page<FeadCampaign>> | undefined;
  totalQuantity$ = new BehaviorSubject(0);
  totalShippedQuantity$ = new BehaviorSubject(0);
  totalStockQuantity$ = new BehaviorSubject(0);
  totalWaitingQuantity$ = new BehaviorSubject(0);
  totalRefusedQuantity$ = new BehaviorSubject(0);
  loading$ = new BehaviorSubject(false);
  rowsPerPage = 10000;
  feadCampaignSearch: FeadCampaignSearch = { }

  constructor(private ref: DynamicDialogRef,
              private config: DynamicDialogConfig,
              private feadCampaignService: FeadCampaignService) {
    this.feadCampaignSearch = {
        year: config.data.year,
        articleId: config.data.id
      };
  }

  loadFeadCampaignList(event: TableLazyLoadEvent) {
    this.loading$.next(true);
    const pagination = this.paginate(event.first || 0, event.rows || this.rowsPerPage, event.sortField, event.sortOrder);
    const feadCampaignPage$ = this.feadCampaignService.findFeadCampaigns$(pagination, { ...this.feadCampaignSearch }).pipe(shareReplay(1));

    const resultContent$ = feadCampaignPage$.pipe(map(page => page.content), shareReplay(1));

    resultContent$.pipe(map(items => items.map(item => item.quantity).reduce((acc, val)=> acc + val, 0))).subscribe(q => this.totalQuantity$.next(q));
    resultContent$.pipe(map(items => items.map(item => item.stockQuantity).reduce((acc, val)=> acc + val, 0))).subscribe(q => this.totalStockQuantity$.next(q));
    resultContent$.pipe(map(items => items.map(item => item.waitingQuantity).reduce((acc, val)=> acc + val, 0))).subscribe(q => this.totalWaitingQuantity$.next(q));
    resultContent$.pipe(map(items => items.map(item => item.refusedQuantity).reduce((acc, val)=> acc + val, 0))).subscribe(q => this.totalRefusedQuantity$.next(q));
    resultContent$.pipe(map(items => items.map(item => item.shippedQuantity).reduce((acc, val)=> acc + val, 0))).subscribe(q => this.totalShippedQuantity$.next(q));
  }

  private paginate(first: number, rows: number, sortField: string | string[] | null | undefined, sortOrder: number | undefined | null) {
    return {
      page: (first || 0) / rows,
      size: rows || this.rowsPerPage,
      sort: sortField ? [`${sortField},${(sortOrder || 1) > 0 ? 'asc' : 'desc'}`] : ['']
    };
  }
}
