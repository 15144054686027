import {Component, Input} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Stock} from '@model/stock';

@Component({
  selector: 'foodbank-pallet-labels',
  templateUrl: './pallet-labels.component.html',
  styleUrl: './pallet-labels.component.scss',
})
export class PalletLabelsComponent {

  @Input()
  stocks?: Stock[];

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<Stock[]>) {
    this.stocks = this.config.data as Stock[];
  }

  close() {
    this.ref.close({});
  }
}
