<div class="layout-topbar">
  <div class="flex">
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
      <i class="pi pi-bars"></i>
    </button>

    <a class="layout-topbar-logo" routerLink="">
      <!--        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo">-->
      <img src="./assets/layout/images/foodbank.png" alt="Foodbank">
      <span>Federation of Foodbanks</span>
    </a>

    <ng-container *ngIf="(breadCrumbItems$ | async) as breadCrumbItems">
      <p-breadcrumb
        class="layout-topbar-breadcrumbs"
        [styleClass]="'p-breadcrumb-lg'"
        [model]="breadCrumbItems"
        [home]="{ label: 'Home', icon: 'pi pi-home mr-1 text-xl', routerLink: '/' }">
        <ng-template pTemplate="item" let-item>
          <ng-container *ngIf="item.routerLink; else elseBlock">
            <a [routerLink]="item.routerLink" class="p-menuitem-link">
              <span [ngClass]="[item.icon ? item.icon : '', 'text-primary']"></span>
              <span class="text-primary font-semibold ml-1">{{ item.label }}</span>
            </a>
          </ng-container>
          <ng-template #elseBlock>
            <a [href]="item.url">
              <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
              <span class="text-color ml-1">{{ item.label }}</span>
            </a>
          </ng-template>
        </ng-template>
        <ng-template pTemplate="separator">
          <span *ngIf="breadCrumbItems.length > 0" class="mx-2"> > </span>
        </ng-template>

      </p-breadcrumb>
    </ng-container>
  </div>

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>

  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">

    <button class="p-link layout-topbar-button border-noround w-auto" (click)="profileMenu.toggle($event)">
      <span class="ml-2 mr-2 inline font-medium">{{ currentUserName$ | async }}</span>
      <i class="pi pi-user mr-2"></i>
      <span>Profile</span>
    </button>
    <p-menu #profileMenu [style]="{width: 'auto'}" [popup]="true" [model]="userMenuItems">
    </p-menu>

    <button class="p-link layout-topbar-button" (click)="languageMenu.toggle($event)">
      <i class="{{(selectedLanguage$ | async)?.icon}}"></i>
    </button>
    <p-menu #languageMenu [popup]="true" [model]="languageMenuItems"></p-menu>

    <button class="p-link layout-topbar-button" (click)="preferencesMenu.toggle($event)">
      <i class="pi pi-cog"></i>
      <span>Preferences</span>
    </button>
    <p-menu #preferencesMenu [style]="{width: 'auto'}" [popup]="true" [model]="[{ label: 'App config' }]">
      <ng-template pTemplate="item" let-item>
        <div class="p-3">
          <app-config></app-config>
        </div>
      </ng-template>
    </p-menu>
  </div>
</div>
