import {Component, computed, inject, input, model, Signal} from '@angular/core';
import {mergeMap} from 'rxjs';
import {SupplierSearch} from '@model/search/supplier-search';
import {SupplierService} from '@services/supplier.service';
import {Supplier} from '@model/supplier';
import {UserService} from '@services/user.service';
import {toSignal} from "@angular/core/rxjs-interop";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {Page} from "@typedefs/page";
import {Pagination} from "@services/pagination";
import {PaginationService} from "@services/pagination.service";

@Component({
  selector: 'foodbank-suppliers-selection',
  templateUrl: './suppliers-selection.component.html',
  styleUrls: ['./suppliers-selection.component.scss']
})
export class SuppliersSelectionComponent {

  ngModel = model<Supplier[] | undefined>();
  supplierSearch = input<SupplierSearch>({
    includeCountrySuppliers: true,
    suppliers: [],
    supplierCategorySearch: {supplierCategoryList: []}
  });

  supplierPage: Signal<Page<Supplier> | undefined>;

  private supplierService = inject(SupplierService);
  private userService = inject(UserService);
  private paginationService = inject(PaginationService);

  constructor() {
    const currentUserCompany$ = this.userService.getCurrentUserCompany$();
    const currentUserCompany = toSignal(currentUserCompany$);

    const actualSupplierSearch = computed(() => ({
      ...this.supplierSearch(),
      company: currentUserCompany(),
    }));
    const pagination = this.paginationService.getDefaultPaginationSignal(200);

    const paginationParams: Signal<[SupplierSearch, Pagination]> = computed(() => [actualSupplierSearch(), pagination()])

    this.supplierPage = pipeSignal(paginationParams,
      mergeMap(([supplierSearch, pagination]) => this.supplierService.findSuppliers$(supplierSearch, pagination)),
    );
  }

}
