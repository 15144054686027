import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PreparationItemSearch} from '@model/search/preparation-item-search';
import {PreparationItemService} from '@services/preparation-item.service';
import {PaginationService} from '@services/pagination.service';
import {PreparationItem} from '@model/preparation-item';
import {BehaviorSubject, combineLatest, distinctUntilChanged, forkJoin, map, Observable, shareReplay, switchMap} from 'rxjs';
import {Organization} from '@model/organization';
import {Returnable} from '@model/returnable';
import {ReturnableService} from '@services/returnable.service';
import {Company} from '@model/company';
import {Preparation} from '@model/preparation';
import {ReturnableSearch} from '@model/search/returnable-search';
import {isSameOrganization} from '@model/comparator/organization-comparator';
import {isSameCompany} from '@model/comparator/company-comparator';

// import _ from 'lodash';

interface PreparationOwner {
  organization: Organization;
  company: Company;
}

@Component({
  selector: 'fead-preparation-organization-report',
  templateUrl: './fead-organization-report.component.html',
  styleUrls: ['./fead-organization-report.component.scss']
})
export class FeadOrganizationReportComponent implements OnInit, OnChanges {

  @Input()
  preparationItemSearch!: PreparationItemSearch;
  showBarCode = true;

  preparationItemSearchSubject$!: BehaviorSubject<PreparationItemSearch>;

  preparationItems$!: Observable<PreparationItem[]>;
  ownerPreparationItemsMap$!: Observable<Map<PreparationOwner, PreparationItem[]>>;
  owners$!: Observable<PreparationOwner[]>;
  ownerReturnablesMap$!: Observable<Map<PreparationOwner, Returnable[]>>;

  constructor(private preparationItemService: PreparationItemService,
              private returnableService: ReturnableService,
              private paginationService: PaginationService,
  ) {
  }

  ngOnInit(): void {
    this.preparationItemSearchSubject$ = new BehaviorSubject<PreparationItemSearch>(this.preparationItemSearch);
    const reportPagination = this.paginationService.getReportPagination();
    this.preparationItems$ = this.preparationItemSearchSubject$.pipe(
      distinctUntilChanged(this.isSameSearch),
      switchMap(preparationItemSearch => this.preparationItemService.find(preparationItemSearch, reportPagination)),
      map(page => page.content),
      shareReplay(),
    );
    this.ownerPreparationItemsMap$ = this.preparationItems$.pipe(
      switchMap(preparationItems => forkJoin(this.splitPreparationItemsByOwner$(preparationItems))),
      map((value: [PreparationOwner, PreparationItem][]) => this.groupByOwner(value)),
      shareReplay(),
    );
    this.owners$ = this.ownerPreparationItemsMap$.pipe(
      map(organizationPreparationItemsEntries => Array.from(organizationPreparationItemsEntries.keys())),
      shareReplay(),
    );
    this.ownerReturnablesMap$ = this.owners$.pipe(
      switchMap(owners => forkJoin(this.getReturnablesForEachOwner(owners))),
      map(tuples => new Map(tuples)),
      shareReplay(),
    );
  }

  private isSameSearch(search1: PreparationItemSearch, search2: PreparationItemSearch): boolean {
    const json1 = JSON.stringify(search1);
    const json2 = JSON.stringify(search2);
    return json1===json2;
  }

  private getReturnablesForEachOwner(owners: PreparationOwner[]): Observable<[PreparationOwner, Returnable[]]>[] {
    return owners.map(owner => this.getOwnerReturnables(owner));
  }

  private getOwnerReturnables(owner: PreparationOwner): Observable<[PreparationOwner, Returnable[]]> {
    return this.getReturnables(owner).pipe(
      map(returnables => [owner, returnables])
    );
  }

  private getReturnables(owner: PreparationOwner) {
    const returnableSearch: ReturnableSearch = {
      company: owner.company,
    };
    return this.returnableService.findReturnable$(returnableSearch).pipe(
      map(returnablePage => returnablePage.content));
  }

  private splitPreparationItemsByOwner$(preparationItems: PreparationItem[]): Observable<[PreparationOwner, PreparationItem]>[] {
    return preparationItems.map(preparationItem => {
      return this.splitPreparationItemByOwner$(preparationItem);
    })
  }

  private splitPreparationItemByOwner$(preparationItem: PreparationItem): Observable<[PreparationOwner, PreparationItem]> {
    const owner$ = this.getOwnerFromPreparationItem$(preparationItem);
    return owner$.pipe(map(owner => [owner, preparationItem]));
  }

  private getOwnerFromPreparationItem$(preparationItem: PreparationItem): Observable<PreparationOwner> {
    return combineLatest([preparationItem.preparation$, preparationItem.organization$]).pipe(
      switchMap(([preparation, organization]) => this.getOwnerFromPreparation$(preparation, organization))
    )
  }

  private getOwnerFromPreparation$(preparation: Preparation, organization: Organization): Observable<PreparationOwner> {
    return preparation.company$.pipe(
      map((company: Company) => ({
        organization: organization,
        company: company
      }))
    );
  }

  ngOnChanges() {
    if (this.preparationItemSearchSubject$) {
      this.preparationItemSearchSubject$.next(this.preparationItemSearch);
    }
  }

  private groupByOwner<T>(preparationItemWithOwnerArray: [PreparationOwner, T][]): Map<PreparationOwner, T[]> {
    const ownerPreparationItemsMap = new Map<PreparationOwner, T[]>();
    const uniqueOwners: PreparationOwner[] = [];

    preparationItemWithOwnerArray.forEach(([owner, preparationItem]) => {
      let uniqueOwner = uniqueOwners.find(existingOwner => this.isSameOwner(existingOwner, owner));
      if (!uniqueOwner) {
        uniqueOwner = owner;
        uniqueOwners.push(owner);
      }
      const preparationItems = ownerPreparationItemsMap.get(uniqueOwner) || [];
      preparationItems.push(preparationItem);
      ownerPreparationItemsMap.set(uniqueOwner, preparationItems);
    });

    return ownerPreparationItemsMap;
  }

  private isSameOwner(owner1: PreparationOwner, owner2: PreparationOwner) {
    const organization1 = owner1.organization;
    const organization2 = owner2.organization;
    const company1 = owner1.company;
    const company2 = owner2.company;
    return isSameOrganization(organization1, organization2) && isSameCompany(company1, company2);
  }

}
