<p-multiSelect [ngModel]="selectedArticles"
               [options]="(articles$ | async) || []"
               placeholder="Any"
               filterMatchMode="contains"
               (onChange)="select($event.value);"
               (onClear)="clear()"
               [showClear]="true"
               filterBy="id,nameFr,nameNl"
               appendTo="body"
               dataKey="id">
  <ng-template let-selectedArticles pTemplate="selectedItems">
    <ng-container *ngIf="selectedArticles?.length > 0">
      <ng-container *ngFor="let selectedArticle of selectedArticles.slice(0,3)">
        <foodbank-article ngDefaultControl [ngModel]="selectedArticle"/>
        <br/>
      </ng-container>
      <ng-container *ngIf="selectedArticles.length > 3" i18n>... {{ selectedArticles.length - 3 }} more item(s) selected</ng-container>
    </ng-container>
    <ng-container *ngIf="!selectedArticles?.length" i18n>Any</ng-container>
  </ng-template>
  <ng-template let-article pTemplate="item">
    <foodbank-article ngDefaultControl [ngModel]="article"/>
  </ng-template>
</p-multiSelect>
