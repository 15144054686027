import {Component, model} from '@angular/core';
import {Zone} from '@model/zone';

@Component({
  selector: 'foodbank-preparation-zone',
  templateUrl: './preparation-zone.component.html',
  styleUrls: ['./preparation-zone.component.scss']
})
export class PreparationZoneComponent {

  ngModel = model<Zone>();

}
