import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {Observable} from 'rxjs';
import {PalletTypeService} from '@services/pallet-type.service';
import {PalletType} from '@model/pallet-type';

@Component({
  selector: 'foodbank-pallet-type-single-selection',
  templateUrl: './pallet-type-single-selection.component.html',
  styleUrl: './pallet-type-single-selection.component.scss'
})
export class PalletTypeSingleSelectionComponent implements OnInit {

  @Input() selectedPalletType?: PalletType;
  @Input() disabled: boolean = false;
  @Output() onPalletTypeSelected: EventEmitter<PalletType> = new EventEmitter<PalletType>();

  palletTypes$: Observable<PalletType[]> | undefined;

  loading: boolean = true;

  constructor(private palletTypeService: PalletTypeService) {
  }

  ngOnInit(): void {
    this.loading = true
    this.palletTypes$ = this.palletTypeService.getPalletTypes$();
    this.palletTypes$.subscribe(() => this.loading = false);
  }

  select(selectedPalletType: PalletType | undefined) {
    this.selectedPalletType = selectedPalletType;
    this.onPalletTypeSelected.emit(selectedPalletType);
  }

  clear() {
    this.select(undefined);
  }
}
