import {Component, Input} from '@angular/core';
import {Returnable} from '@model/returnable';
import {map} from 'rxjs';
import {LanguageService} from '@services/language.service';

@Component({
  selector: 'foodbank-returnable',
  templateUrl: './returnable.component.html',
  styleUrls: ['./returnable.component.scss']
})
export class ReturnableComponent {
  @Input() returnable: Returnable | undefined;

  @Input()
  showId = true;

  showId$ = this.languageService.isCurrentLanguageDisplayable$(['nl', 'fr'])
    .pipe(map(isDisplayable => {
      if (!isDisplayable) {
        return true;
      } else {
        return this.showId;
      }
    }));

  constructor(private languageService: LanguageService) {
  }

}
