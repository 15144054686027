import {Component, ElementRef, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {LayoutService} from "./service/app.layout.service";
import {LanguageService} from '@services/language.service';
import {AuthenticationService} from '@services/authentication.service';
import {UserService} from '@services/user.service';
import {combineLatest, map, mergeMap} from 'rxjs';
import {User} from '@model/user';
import {Company} from '@model/company';
import {Warehouse} from '@model/warehouse';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  currentUser$ = this.userService.getCurrentUser$();
  currentUserName$ = this.currentUser$.pipe(map(user => user?.name));
  currentUserCompanyName$ = this.currentUser$.pipe(
    mergeMap(user => user?.company$),
    map(company => company?.compName)
  );

  selectedLanguage$ = this.languageService.getLanguage$();

  userMenuItems: MenuItem[] = [
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => this.authenticationService.logout()
    }
  ];

  languageMenuItems: MenuItem[] = this.languageService.getLanguageOptions().map(language => {
    return {
      label: language.label,
      icon: language.icon,
      command: () => this.languageService.setLanguage(language.code)
    }
  });

  preferencesMenuItems: MenuItem[] = [
  {
      label: 'Preferences',
      icon: 'pi pi-cog'
    }
  ];

  breadCrumbItems$ = this.layoutService.breadCrumbItems$;

  constructor(public layoutService: LayoutService,
              public authenticationService: AuthenticationService,
              public userService: UserService,
              public languageService: LanguageService) {
    const companyObservable = this.currentUser$.pipe(
      mergeMap(user => user?.company$),
    );
    const warehouseObservable = this.currentUser$.pipe(
      mergeMap(user => user?.warehouse$),
    );

    combineLatest([this.currentUser$, companyObservable, warehouseObservable])
      .subscribe(([user, company, warehouse]) => {
        const {userDetails, companyDetails, warehouseDetails} = this.getUserAndCompanyMenuItems(user, company, warehouse);

        this.userMenuItems = [
          ...userDetails,
          ...companyDetails,
          ...warehouseDetails,
          {
            separator: true,
            items: [{
              label: 'Logout',
              icon: 'pi pi-sign-out',
              command: () => this.authenticationService.logout()
            }]
          }];
      })
  }

  private getUserAndCompanyMenuItems(user: User, company: Company, warehouse?: Warehouse) {
    const userDetails = [{
      label: 'User',
      items: [
        {
          label: `
        <div class="grid -mr-3 ml-0 w-100">
            <div class="col-2">
              <label for="user-id" class="mr-2 font-semibold">Id:</label>
            </div>
            <div class="col-10">
              <span id="user-id">${user?.id}</span>
            </div>
            <div class="col-2">
              <label for="user-name" class="mr-2 font-semibold">Name:</label>
            </div>
            <div class="col-10">
              <span id="user-name">${user?.name}</span>
            </div>
        </div>
`,
          escape: false,
        }
      ]
    }];

    const companyDetails = [{
      label: 'Company',
      items: [
        {
          label: `
        <div class="grid -mr-3 ml-0">
          <div class="col-2">
            <label for="company-id" class="mr-2 font-semibold">Id:</label>
          </div>
          <div class="col-10">
            <span>${company?.id}</span>
          </div>
          <div class="col-2">
            <label for="company-name" class="mr-2 font-semibold">Name:</label>
          </div>
          <div class="col-10">
            <span >${company?.compName}</span>
           </div>
          </div>
        </div>
`,
          escape: false,
        }
      ]
    }];

    const warehouseDetails = warehouse ? [{
      label: 'Warehouse',
      items: [
        {
          label: `
        <div class="grid -mr-3 ml-0">
          <div class="col-2">
            <label for="warehouse-id" class="mr-2 font-semibold">Id:</label>
          </div>
          <div class="col-10">
            <span>${warehouse.id}</span>
          </div>
          <div class="col-2">
            <label for="warehouse-name" class="mr-2 font-semibold">Name:</label>
          </div>
          <div class="col-10">
            <span >${warehouse.name}</span>
           </div>
          </div>
        </div>
`,
          escape: false,
        }
      ]
    }] : []

    return { userDetails, companyDetails, warehouseDetails };
  }

  protected readonly length = length;
}
