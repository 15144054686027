import {Injectable, Injector} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable, shareReplay, take} from "rxjs";
import {WarehouseDto, WarehouseSearchDto} from '@typedefs/stock-rest';
import {environment} from '@environments/environment';
import {Page} from '@typedefs/page';
import {Pagination} from './pagination';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {Warehouse} from '@model/warehouse';
import {CompanyService} from '@services/company.service';
import {PreparationService} from '@services/preparation.service';
import {StockService} from '@services/stock.service';
import {StockPrevisionService} from "@services/stock-prevision.service";

type warehousePagination = Pagination;

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  constructor(private httpClient: HttpClient,
              private companyService: CompanyService,
              private injector: Injector) {
  }

  public getWarehouse$(id: number): Observable<Warehouse> {
    return this.httpClient.get<WarehouseDto>(`${environment.apiUrl}/warehouses/${id}`)
      .pipe(
        map(warehouse => this.loadWarehouse(warehouse)),
        shareReplay()
      );
  }

  findWarehouses$(warehouseSearch: WarehouseSearch, pagination?: warehousePagination): Observable<Page<Warehouse>> {
    const warehouseSearchDto = this.mapToWarehouseSearchDto(warehouseSearch);
    return this.httpClient.post<Page<WarehouseDto>>(`${environment.apiUrl}/warehouses/search`, warehouseSearchDto, {params: pagination})      .pipe(
        map(warehousesPage => {
          const warehouses: Warehouse[] = warehousesPage.content.map(warehouse => this.loadWarehouse(warehouse));
          return {
            ...warehousesPage,
            content: warehouses
          }
        }),
      shareReplay()
      );
  }

  private loadWarehouse(warehouseDto: WarehouseDto): Warehouse {
    return {
      ...warehouseDto,
      company$: this.companyService.getCompany$(warehouseDto.companyId)
    }
  }

  public mapToWarehouseSearchDto(warehouseSearch: WarehouseSearch): WarehouseSearchDto {
    const preparationService = this.injector.get(PreparationService);
    const stockService = this.injector.get(StockService);
    const stockPrevisionService = this.injector.get(StockPrevisionService);
    return {
      companyId: warehouseSearch.company?.id,
      preparationSearchDto: warehouseSearch.preparationSearch && preparationService.mapToPreparationSearchDto(warehouseSearch.preparationSearch),
      warehouseIds: warehouseSearch.warehouses?.map(warehouse => warehouse.id),
      stockSearchDto: warehouseSearch.stockSearch && stockService.mapToStockSearchDto(warehouseSearch.stockSearch),
      sourceWarehouseFromStockPrevisionSearchDto: warehouseSearch.sourceStockPrevisionSearch && stockPrevisionService.mapToStockPrevisionSearchDto(warehouseSearch.sourceStockPrevisionSearch),
      destinationWarehouseFromStockPrevisionSearchDto: warehouseSearch.destinationStockPrevisionSearch && stockPrevisionService.mapToStockPrevisionSearchDto(warehouseSearch.destinationStockPrevisionSearch),
    }

  }
}
