import {Component, Input, OnInit} from '@angular/core';
import {EMPTY, map, mergeMap, Observable, of} from 'rxjs';
import {Article} from '@model/article';
import {Supplier} from '@model/supplier';
import {ArticleCategory} from '@model/article-category';
import {ReceptionPalletClusterSummary} from '@model/reception-pallet-cluster-summary';
import {Stock} from '@model/stock';

@Component({
  selector: 'foodbank-pallet-label',
  templateUrl: './pallet-label.component.html',
  styleUrl: './pallet-label.component.scss'
})
export class PalletLabelComponent implements OnInit {

  @Input()
  stock?: Stock

  barcodeValue?: string;
  article$?: Observable<Article | undefined>;
  articleCategory$?: Observable<ArticleCategory | undefined>;
  supplier$?: Observable<Supplier | undefined>;
  summary$?: Observable<ReceptionPalletClusterSummary | undefined>;
  unitsPerParcel$?: Observable<number>;

  ngOnInit() {
    if (!this.stock) {
      return;
    }

    this.barcodeValue = String(this.stock.palletId);

    this.supplier$ = this.stock.supplier$ || EMPTY;
    this.article$ = this.stock.article$ || EMPTY;
    this.summary$ = this.stock.palletSummary$ || EMPTY;

    this.articleCategory$ = this.article$.pipe(mergeMap(article => article?.articleCategory$ || of(undefined)));
    this.unitsPerParcel$ = this.article$.pipe(map(article => article?.feadUnitsPerParcel || 0));
  }
}
