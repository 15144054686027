import { Component, Input } from '@angular/core';
import { ArticleCategoryDto, ArticleDto, ArticleStorageConditionDto } from '@typedefs/stock-rest';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EMPTY, Observable } from 'rxjs';
import { Article } from '@model/article';
import { ArticleService } from '@services/article.service';

@Component({
  selector: 'foodbank-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss']
})
export class ArticleEditComponent {

  @Input()
  article: ArticleDto | undefined;
  articleCategory$: Observable<ArticleCategoryDto>;
  articleStorageCondition$: Observable<ArticleStorageConditionDto>;

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<Article>,
              public articleService: ArticleService) {
    this.article = this.config.data;
    this.articleCategory$ = this.config.data?.articleCategory$ || EMPTY;
    this.articleStorageCondition$ = this.config.data?.articleStorageCondition$ || EMPTY;
  }

  save(article: ArticleDto) {
    this.articleService.updateArticle(article)
      .subscribe(article => this.ref.close(article));
  }

  close() {
    this.ref.close({});
  }

  updateArticleCategoryId($event: ArticleCategoryDto) {
    if (this.article) {
      this.article.articleCategoryId = $event.id;
    }
  }

  updateArticleStorageConditionId($event: ArticleStorageConditionDto) {
    if (this.article) {
      this.article.articleStorageConditionId = $event.id;
    }
  }
}
