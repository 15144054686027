import {Component, Input} from '@angular/core';
import {Preparation} from '@model/preparation';

@Component({
  selector: 'foodbank-fead-preparation',
  templateUrl: './fead-preparation.component.html',
  styleUrls: ['./fead-preparation.component.scss']
})
export class FeadPreparationComponent {

  @Input()
  preparation!: Preparation;
  @Input()
  showDetails = true;
  @Input()
  showId = true;

}
