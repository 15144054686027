import { Component, inject, Input, OnChanges, OnInit } from '@angular/core';
import { ReceptionItem, ReceptionItemFormInput } from '@model/reception-item';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Article } from '@model/article';
import { Returnable } from '@model/returnable';
import { BehaviorSubject, map, mergeMap, Observable, of, shareReplay, take, combineLatest, debounceTime, filter } from 'rxjs';
import { ReceptionItemService } from "@services/reception-item.service";
import { ReceptionItemSummary } from "@model/reception-item-summary";
import { ArticleSearch } from '@model/search/article-search';
import { FoodbankDatePickerEvent } from '@components/date/date-picker/date-picker.component';
import { ComponentChanges } from "@util/component-change";

@Component({
  selector: 'foodbank-reception-item-edit',
  templateUrl: './reception-item-edit.component.html',
  styleUrls: ['./reception-item-edit.component.scss']
})
export class ReceptionItemEditComponent implements OnInit, OnChanges {

  @Input() receptionItem: ReceptionItem;
  @Input() isNew: boolean = false;

  receptionItemInput$: Observable<ReceptionItemFormInput>;
  receptionItem$: BehaviorSubject<ReceptionItem>;
  receptionItemSummary$: Observable<ReceptionItemSummary | undefined>;
  articleSearch$: Observable<ArticleSearch>
  receptionItemDetailsAreValid$: Observable<boolean>;

  private initialized = false;
  private validateReceptionItemSink$ = new BehaviorSubject<ReceptionItemFormInput | undefined>(undefined);
  private saveReceptionItemSink$ = new BehaviorSubject<ReceptionItemFormInput | undefined>(undefined);

  private refreshTrigger$ = new BehaviorSubject<void>(undefined);
  private receptionItemService = inject(ReceptionItemService);

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<{ isNew: boolean, receptionItem: ReceptionItem }>,
  ) {
    if (this.config.data) {
      this.receptionItem = {
        ...this.config.data.receptionItem,
        unitWeight: this.config.data.receptionItem.unitWeight ?? 0,
      };
      this.isNew = this.config.data.isNew || false;
    } else {
      this.receptionItem = {palletQuantity: 1} as ReceptionItem;
    }
    this.receptionItem$ = new BehaviorSubject<ReceptionItem>({...this.receptionItem, unitWeight: this.receptionItem.unitWeight ?? 0});
    this.articleSearch$ = this.receptionItem$.pipe(
      mergeMap(receptionItem => receptionItem.reception$),
      mergeMap(reception => reception.supplier$),
      map(supplier => ({fead: supplier.fead}))
    );
    this.receptionItemSummary$ = this.receptionItem$.pipe(mergeMap(receptionItem => receptionItem.summary$))
    this.receptionItemInput$ = combineLatest([
      this.receptionItem$,
      this.receptionItem$.pipe(mergeMap(receptionItem => receptionItem.article$)),
      this.receptionItem$.pipe(mergeMap(receptionItem => receptionItem.returnable$)),
      this.receptionItem$.pipe(mergeMap(receptionItem => receptionItem.reception$)),
      this.receptionItem$.pipe(mergeMap(receptionItem => receptionItem.summary$)),
      this.refreshTrigger$
    ]).pipe(
      map(([receptionItem, article, returnable, reception, summary, _]) => ({
        ...receptionItem, article, returnable, reception, summary
      })),
      shareReplay()
    );

    this.receptionItemInput$.pipe(take(1)).subscribe(input => this.validateReceptionItemSink$.next(input));
    this.receptionItemDetailsAreValid$ = combineLatest([this.validateReceptionItemSink$, this.receptionItemSummary$]).pipe(
      map(([input, summary]) => this.isReceptionItemValid(input, summary)),
      shareReplay()
    );
  }

  ngOnInit() {
    this.receptionItem$.next({...this.receptionItem, unitWeight: this.receptionItem.unitWeight ?? 0});

    this.initialized = true;

    this.saveReceptionItemSink$
      .pipe(
        filter((receptionItemFormInput) => this.areArticleDetailsValid(receptionItemFormInput)),
        map((receptionItemFormInput) => this.mapToReceptionItem(receptionItemFormInput!)),
        debounceTime(1000)
      )
      .subscribe(receptionItemFormInput => this.save(receptionItemFormInput!));
  }

  ngOnChanges(changes: ComponentChanges<ReceptionItemEditComponent>) {
    if (!this.initialized) {
      return;
    }

    if (changes.receptionItem) {
      const receptionItem = changes.receptionItem.currentValue;
      this.receptionItem$.next(receptionItem);
      this.refresh();
    }
  }

  close() {
    if (!this.isNew) {
      this.ref.close({});
      return;
    }

    this.receptionItemService.deleteReceptionItem$(this.receptionItem)
      .subscribe(_ => this.ref.close({}));
  }

  selectArticle(selectedArticle: Article, receptionItemInput: ReceptionItemFormInput) {
    receptionItemInput.unitWeight = selectedArticle?.feadUnitWeight || 0;
    receptionItemInput.unitGrossWeight = selectedArticle?.feadUnitGrossWeight || 0;
    receptionItemInput.unitsPerParcel = selectedArticle?.feadUnitsPerParcel || 0;

    if (!this.receptionItem.article$) {
      this.saveFormInput(receptionItemInput);
    } else {
      this.receptionItem.article$.pipe(take(1)).subscribe(article => {
        if (article?.id !== selectedArticle?.id) {
          this.saveFormInput(receptionItemInput);
        }
      })
    }
  }

  selectReturnable(selectedReturnable: Returnable, receptionItemInput: ReceptionItemFormInput) {
    if (!this.receptionItem.returnable$) {
      this.saveFormInput(receptionItemInput);
    } else {
      this.receptionItem.returnable$.pipe(take(1)).subscribe(returnable => {
        if (returnable?.id !== selectedReturnable?.id) {
          this.saveFormInput(receptionItemInput);
        }
      })
    }
  }

  saveFormInput(receptionItemFormInput: ReceptionItemFormInput | undefined) {
    this.validateReceptionItemSink$.next(receptionItemFormInput);
    this.saveReceptionItemSink$.next(receptionItemFormInput);
  }

  private mapToReceptionItem(receptionItemFormInput: ReceptionItemFormInput) {
    const receptionItem: ReceptionItem = {
      ...receptionItemFormInput,
      article$: of(receptionItemFormInput.article),
      returnable$: of(receptionItemFormInput.returnable),
      reception$: of(receptionItemFormInput.reception),
      summary$: of(receptionItemFormInput.summary),
      expirationDate: receptionItemFormInput.expirationDate as Date,
      bestBeforeDate: receptionItemFormInput.bestBeforeDate as Date,
    }
    return receptionItem;
  }

  private save(receptionItem: ReceptionItem) {
    const result = this.receptionItemService.saveReceptionItem$(receptionItem)
      .pipe(shareReplay())

    result
      .subscribe(receptionItem => {
        this.isNew = false;
        this.receptionItem = receptionItem;
        this.receptionItem$.next(receptionItem);
        this.refresh()
      });

    return result;
  }

  saveExpirationDate(event: FoodbankDatePickerEvent, receptionItemFormInput: ReceptionItemFormInput) {
    receptionItemFormInput.expirationDate = event.utcValue;
    this.saveFormInput(receptionItemFormInput);
  }

  saveBestBeforeDate(event: FoodbankDatePickerEvent, receptionItemFormInput: ReceptionItemFormInput) {
    receptionItemFormInput.bestBeforeDate = event.utcValue;
    this.saveFormInput(receptionItemFormInput);
  }

  saveAndClose(receptionItemFormInput: ReceptionItemFormInput) {
    const receptionItem = this.mapToReceptionItem(receptionItemFormInput);
    this.save(receptionItem)
      .subscribe(receptionItem => this.ref.close(receptionItem));
  }

  refreshSummary(receptionItem: ReceptionItem) {
    receptionItem.summary$ = this.receptionItemService.mapToReceptionItemDto$(receptionItem)
      .pipe(mergeMap(receptionItemDto => this.receptionItemService.getSummary$(receptionItemDto)));

    this.receptionItem$.next(receptionItem);
  }

  refresh() {
    this.refreshTrigger$.next();
  }

  private isReceptionItemValid(input: ReceptionItemFormInput | undefined, summary: ReceptionItemSummary | undefined): boolean {
    console.log('input', input);
    console.log('summary', summary);
    const areArticleDetailsValid = this.areArticleDetailsValid(input);
    if (!areArticleDetailsValid) {
      return false;
    }

    if (!summary) {
      return false;
    }

    return summary.palletCount > 0 && summary.unitCount > 0;
  }

  areArticleDetailsValid(receptionItemFormInput: ReceptionItemFormInput | undefined): boolean {
    if (!receptionItemFormInput) {
      return false;
    }

    const noDateIsProvided = !receptionItemFormInput.expirationDate && !receptionItemFormInput.bestBeforeDate;
    return !receptionItemFormInput.article || !noDateIsProvided
  }

}
