import {Component, input, model} from '@angular/core';
import {SupplierCategory} from "@model/supplier-category";

@Component({
  selector: 'foodbank-supplier-category',
  templateUrl: './supplier-category.component.html',
  styleUrls: ['./supplier-category.component.scss']
})
export class SupplierCategoryComponent {

  ngModel = model<SupplierCategory>();
  showId = input(true);

  constructor() {
  }

}
