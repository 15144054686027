<p-cellEditor>
  <ng-template pTemplate="input">
    <input class="quantity-edit not-preferred-week" pInputText type="number"
           *ngIf="!isPreferredWeekForDelivery; else preferredWeekInput"
           [pTooltip]="organization?.preferredSupplyWeek" tooltipPosition="top"
           [ngModel]="quantity"
           (ngModelChange)="updateQuantity($event)"/>
    <ng-template #preferredWeekInput>
      <input class="quantity-edit" pInputText type="number"
             [ngModel]="quantity"
             (ngModelChange)="updateQuantity($event)"/>
    </ng-template>
  </ng-template>
  <ng-template pTemplate="output">
    <div class="editable not-preferred-week"
         *ngIf="!isPreferredWeekForDelivery; else preferredWeekOutput"
         [pTooltip]="organization?.preferredSupplyWeek" tooltipPosition="top">
      <span>
        <span *ngIf="!quantity">&nbsp;&nbsp;&nbsp;&nbsp;</span>
        {{ quantity | number: '1.0-0' }}
      </span>
    </div>
    <ng-template #preferredWeekOutput>
      <div class="editable">{{ (quantity | number: '1.0-0') || '&nbsp;' }}</div>
    </ng-template>
  </ng-template>
</p-cellEditor>
