<ng-container *ngIf="(receptionPalletClusterPage$ | async)?.content as receptionPalletClusters">

  <p-table [value]="receptionPalletClusters"
           styleClass="p-datatable-sm"
           responsiveLayout="scroll"
           [rowHover]="true"
           [resizableColumns]="true"
           columnResizeMode="expand"
           [autoLayout]="true"
           dataKey="id"
           editMode="cell"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-column md:flex-row md:align-items-center">
        <div class="grid">
        <span class="col-fixed m-0 mt-2">
          <span i18n>Pallet clusters</span>
          <span class="ml-1 font-light" *ngIf="(receptionItemSummary$ | async) as receptionItemSummary">(total pallets: {{ receptionItemSummary.palletCount }}, parcels: {{ receptionItemSummary.parcelCount }}, units: {{ receptionItemSummary.unitCount }})</span>
        </span>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr *ngIf="(receptionItemSummary$ | async) as receptionItemSummary">
        <th style="width: 5%" class="text-center">#</th>
        <th style="width: 10%" i18n>No of pallets</th>
        <th style="width: 10%" *ngIf="receptionItemSummary.showParcelsPerPallet">
          <span i18n>No of parcels / pallet</span>
          <i class="pi pi-info-circle ml-1" [pTooltip]="parcelWeightInfo" tooltipPosition="top"></i>
          <ng-template #parcelWeightInfo>
            <span i18n>1 Parcel = {{ receptionItem.unitWeight * receptionItem.unitsPerParcel | number: '1.2-2' }} gr</span>
          </ng-template>
        </th>
        <th style="width: 10%" *ngIf="!receptionItemSummary.showParcelsPerPallet" i18n>Unit weight (Kg)</th>
        <th style="width: 20%">Location</th>
        <th style="width: 20%">Pallet type</th>
        <th style="width: 25%">Best before date <span *ngIf="!!receptionItem.bestBeforeDate">*</span></th>
        <th style="width: 5%" *ngIf="editingEnabled">
          <p-button *ngIf="editingEnabled" icon="pi pi-plus" [rounded]="true" [text]="true"
                    (click)="addReceptionPalletCluster(receptionItem)"></p-button>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-receptionPalletCluster let-index="rowIndex" let-editing="editing">
      <tr *ngIf="identity(receptionPalletCluster) as receptionPalletCluster">
        <td class="text-center">{{ index + 1 }}</td>
        <td pEditableColumn pEditableColumnField="numberOfPallets">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input id="receptionPalletCluster-{{index}}-number-of-pallets" name="receptionPalletCluster-{{index}}-number-of-pallets" pInputText type="text"
                     [(ngModel)]="receptionPalletCluster.numberOfPallets" (ngModelChange)="saveReceptionPalletCluster(receptionPalletCluster)" ngDefaultControl required/>
            </ng-template>
            <ng-template pTemplate="output">
              <div class="aligned-quantity" [class.editable]="editingEnabled">
                <span *ngIf="receptionPalletCluster.numberOfPallets; else emptyCell">{{ receptionPalletCluster.numberOfPallets | number: '1.0-0' }}</span>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn pEditableColumnField="quantity">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input *ngIf="editingEnabled" pInputText type="text" id="receptionPalletCluster-{{index}}-quantity" name="receptionPalletCluster-{{index}}-quantity"
                     [(ngModel)]="receptionPalletCluster.quantity" (ngModelChange)="saveReceptionPalletCluster(receptionPalletCluster)"
                     ngDefaultControl required/>
            </ng-template>
            <ng-template pTemplate="output">
              <div class="aligned-quantity" [class.editable]="editingEnabled">
                {{ receptionPalletCluster.quantity | number: '1.0-0' }}
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn pEditableColumnField="location">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input id="receptionPalletCluster-{{index}}-location" name="receptionPalletCluster-{{index}}-location"
                     pInputText type="text" [(ngModel)]="receptionPalletCluster.location" (ngModelChange)="saveReceptionPalletCluster(receptionPalletCluster)" ngDefaultControl/>
            </ng-template>
            <ng-template pTemplate="output">
              <div [class.editable]="editingEnabled">
                <span *ngIf="receptionPalletCluster.location; else emptyCell">{{ receptionPalletCluster.location }}</span>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn pEditableColumnField="type">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <foodbank-pallet-type-single-selection id="receptionPalletCluster-{{index}}-type"
                                                     [selectedPalletType]="(receptionPalletCluster.type$ | async) ?? undefined"
                                                     (onPalletTypeSelected)="updatePalletType($event, receptionPalletCluster)"></foodbank-pallet-type-single-selection>
            </ng-template>
            <ng-template pTemplate="output">
              <div [class.editable]="editingEnabled">
                <foodbank-pallet-type *ngIf="(receptionPalletCluster.type$ | async) as palletClusterType; else emptyCell"
                                      [palletType]="palletClusterType ?? undefined"></foodbank-pallet-type>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn pEditableColumnField="bestBeforeDate">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <foodbank-date-picker id="receptionPalletCluster-{{index}}-best-before-date" name="receptionPalletCluster-{{index}}-best-before-date"
                                    [ngModel]="receptionPalletCluster.bestBeforeDate"
                                    [showClear]="true" [showSelectionOptions]="false" [required]="!!receptionItem.bestBeforeDate"
                                    (onSelect)="updateBestBeforeDate(receptionPalletCluster, $event)" ngDefaultControl/>
            </ng-template>
            <ng-template pTemplate="output">
              <div [class.editable]="editingEnabled">
                <span *ngIf="receptionPalletCluster.bestBeforeDate; else emptyCell">{{ receptionPalletCluster.bestBeforeDate | date: 'yyyy-MM-dd' }}</span>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
        <td *ngIf="editingEnabled">
          <p-button icon="pi pi-times pointer-events-auto" [rounded]="true" [text]="true" severity="danger"
                    [disabled]="receptionPalletClusters.length < 2"
                    [pTooltip]="'Cannot remove all clusters'"
                    tooltipStyleClass="-mt-3"
                    [tooltipDisabled]="receptionPalletClusters.length > 1"
                    (click)="removePallet(receptionPalletCluster)"></p-button>
        </td>
      </tr>
      <ng-template #emptyCell>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </ng-template>
    </ng-template>
  </p-table>
</ng-container>
