import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {combineLatest, forkJoin, map, mergeMap, Observable, of, switchMap} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {WarehouseService} from './warehouse.service';
import {InterWarehouseRouteDto, InterWarehouseTransferDto} from '@typedefs/stock-rest';
import {StockPrevisionSearch} from '@model/search/stock-prevision-search';
import {InterWarehouseRoute} from "@model/inter-warehouse-route";
import {StockPrevisionService} from "@services/stock-prevision.service";
import {InterWarehouseTransfer} from "@model/inter-warehouse-transfer";
import {StockService} from "@services/stock.service";
import {MovementService} from "@services/movement.service";

type InterWarehousePagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class InterWarehouseTransferService {

  private httpClient = inject(HttpClient);
  private warehouseService = inject(WarehouseService);
  private movementService = inject(MovementService);
  private stockPrevisionService = inject(StockPrevisionService);
  private stockService = inject(StockService);

  createInterWarehouseTransfer$(interWarehouseTransfer: InterWarehouseTransfer): Observable<InterWarehouseTransfer> {
    return this.mapToInterWarehouseDto$(interWarehouseTransfer)
      .pipe(
        switchMap(interWarehouseTransferDto => this.httpClient.post<InterWarehouseTransferDto>(`${environment.apiUrl}/stocks/bulk/inter-warehouse`, interWarehouseTransferDto)),
        map(interWarehouseTransferDto => this.mapToInterWarehouseTransfer(interWarehouseTransferDto)),
      );
  }

  findInterWarehouseRoutes(stockPrevisionSearch: StockPrevisionSearch, pagination: InterWarehousePagination): Observable<Page<InterWarehouseRoute>> {
    const stockPrevisionSearchDto = this.stockPrevisionService.mapToStockPrevisionSearchDto(stockPrevisionSearch);
    return this.httpClient.post<Page<InterWarehouseRouteDto>>(`${environment.apiUrl}/stocks/bulk/inter-warehouse/routes/search`, stockPrevisionSearchDto, {params: pagination})
      .pipe(
        map(interWarehouseRouteDtoPage => this.mapToInterWarehouseRoutePage(interWarehouseRouteDtoPage))
      );
  }

  mapToInterWarehouseRoutePage(interWarehouseTransferDtoPage: Page<InterWarehouseRouteDto>): Page<InterWarehouseRoute> {
    const interWarehouseTransfers: InterWarehouseRoute[] = interWarehouseTransferDtoPage.content.map(stockPrevision => this.mapToInterWarehouseRoute(stockPrevision));
    return {
      ...interWarehouseTransferDtoPage,
      content: interWarehouseTransfers
    }
  }

  mapToInterWarehouseRoute(interWarehouseRouteDto: InterWarehouseRouteDto): InterWarehouseRoute {
    return {
      sourceWarehouse$: this.warehouseService.getWarehouse$(interWarehouseRouteDto.sourceWarehouseId),
      targetWarehouse$: this.warehouseService.getWarehouse$(interWarehouseRouteDto.targetWarehouseId),
    };
  }

  mapToInterWarehouseTransfer(interWarehouseTransferDto: InterWarehouseTransferDto): InterWarehouseTransfer {
    return {
      comment: interWarehouseTransferDto.comment,
      dateTime: interWarehouseTransferDto.dateTime,
      stock$: this.stockService.getStock$(interWarehouseTransferDto.stockId),
      targetWarehouse$: this.warehouseService.getWarehouse$(interWarehouseTransferDto.targetWarehouseId),
      movement$: interWarehouseTransferDto.movementId ? this.movementService.getMovement$(interWarehouseTransferDto.movementId) : of(undefined),
      stockPrevision$: interWarehouseTransferDto.stockPrevisionId ? this.stockPrevisionService.getStockPrevision$(interWarehouseTransferDto.stockPrevisionId) : of(undefined),
    };
  }

  mapToInterWarehouseDto$(interWarehouseTransfer: InterWarehouseTransfer): Observable<InterWarehouseTransferDto> {
    return forkJoin([interWarehouseTransfer.stock$, interWarehouseTransfer.targetWarehouse$, interWarehouseTransfer.stockPrevision$, interWarehouseTransfer.movement$])
      .pipe(
        map(([stock, targetWarehouse, stockPrevision, movement]) => ({
          stockId: stock.id,
          targetWarehouseId: targetWarehouse.id,
          stockPrevisionId: stockPrevision?.id,
          movementId: movement?.id,
          comment: interWarehouseTransfer.comment,
          dateTime: interWarehouseTransfer.dateTime,
        }))
      );
  }
}
