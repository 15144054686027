<p-panel
  pTooltip="click to select inter-warehouse reception"
  tooltipPosition="left"
  [toggleable]="true"
  toggler="header"
  [collapsed]="!!(interWarehouseReceptionsCollapsed$ | async)"
  (onAfterToggle)="handlePanelCollapsed($event.collapsed)"
>
  <ng-template pTemplate="header">
    <strong>Inter-warehouse receptions</strong>
    <ng-container *ngIf="(selectedInterWarehouseRoute$ | async) as selectedInterWarehouseTransfer">
      <ng-container *ngIf="(selectedInterWarehouseTransfer.sourceWarehouse$ | async) as sourceWarehouse">
        <ng-container *ngIf="(selectedInterWarehouseTransfer.targetWarehouse$ | async) as targetWarehouse">
          :&nbsp;
          <foodbank-warehouse ngDefaultControl [ngModel]="sourceWarehouse" [showDetails]="false"></foodbank-warehouse>
          &nbsp;→&nbsp;
          <foodbank-warehouse ngDefaultControl [ngModel]="targetWarehouse" [showDetails]="false"></foodbank-warehouse>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
  <p-table [value]="(((interWarehouseRoutePage$ | async)?.content) || [])"
           responsiveLayout="scroll"
           [rows]="DEFAULT_ROWS_PER_PAGE"
           selectionMode="single"
           [totalRecords]="((interWarehouseRoutePage$ | async)?.totalElements || 0)"
           [globalFilterFields]="['depot']"
           [paginator]="((interWarehouseRoutePage$ | async)?.totalElements || 0) > ((pagination$ | async)?.size || 0)"
           [rowsPerPageOptions]="[25,50,100]"
           [showCurrentPageReport]="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [lazy]="true"
           (onLazyLoad)="handleLazyLoad($event)"
           [rowHover]="true"
           [resizableColumns]="true"
           columnResizeMode="expand"
           sortMode="multiple"
           [selection]="selectedInterWarehouseRoute$ | async"
           (selectionChange)="handleSelectionChange($event)"
           [autoLayout]="true"
           [multiSortMeta]="[ { field: 'WAREHOUSE', order: 1 }, { field: 'SOURCE_WAREHOUSE', order: 1 },]"
  >
    <ng-template pTemplate="header" let-value>
      <tr>
        <th pSortableColumn="SOURCE_WAREHOUSE">
          <span i18n>Source warehouse</span>
          <p-sortIcon field="SOURCE_WAREHOUSE"></p-sortIcon>
        </th>
        <th pSortableColumn="WAREHOUSE">
          <span i18n>Target Warehouse</span>
          <p-sortIcon field="WAREHOUSE"></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-interWarehouseRoute>
      <ng-container *ngIf="identity(interWarehouseRoute) as interWarehouseRoute">
        <tr [pSelectableRow]="interWarehouseRoute">
          <td>
            <foodbank-warehouse ngDefaultControl [ngModel]="(interWarehouseRoute.sourceWarehouse$ | async) || undefined" [showDetails]="false"></foodbank-warehouse>
          </td>
          <td>
            <foodbank-warehouse ngDefaultControl [ngModel]="(interWarehouseRoute.targetWarehouse$ | async) || undefined" [showDetails]="false"></foodbank-warehouse>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
</p-panel>

<foodbank-stock-prevision-list *ngIf="(selectedInterWarehouseRoute$ | async) as selectedInterWarehouseTransfer; else showAll"
                               [sourceWarehouse]="(selectedInterWarehouseTransfer.sourceWarehouse$ | async) || undefined"
                               [targetWarehouse]="(selectedInterWarehouseTransfer.targetWarehouse$ | async) || undefined"
>
</foodbank-stock-prevision-list>
<ng-template #showAll>
  <foodbank-stock-prevision-list #test
  >
  </foodbank-stock-prevision-list>
</ng-template>
