import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, mergeMap, Observable, shareReplay, tap} from 'rxjs';
import {LocationService} from '@services/location.service';
import {LocationSearch} from "@model/search/location-search";
import {Location} from "@model/location";

@Component({
  selector: 'foodbank-location-single-selection',
  templateUrl: './location-single-selection.component.html',
  styleUrls: ['./location-single-selection.component.scss']
})
export class LocationSingleSelectionComponent implements OnInit, OnChanges {

  @Input()
  selectedLocation?: Location;
  @Input()
  locationSearch?: LocationSearch;
  @Output()
  onLocationSelected: EventEmitter<Location> = new EventEmitter<Location>();

  locationSearch$!: BehaviorSubject<LocationSearch>;
  searchFilter$!: BehaviorSubject<string>;

  locations$!: Observable<Location[]>;

  loading = true;

  searchFilter = '';

  @Input()
  warehouseId!: number;

  constructor(private locationService: LocationService) {
  }

  ngOnInit(): void {
    this.locationSearch$ = new BehaviorSubject<LocationSearch>(this.locationSearch || {});
    this.searchFilter$ = new BehaviorSubject<string>(this.searchFilter);
    const debouncedSearchFilter$ = this.searchFilter$.pipe(
      debounceTime(100),
      distinctUntilChanged()
    );

    const combinedLocationSearch$ = combineLatest([this.locationSearch$, debouncedSearchFilter$]).pipe(
      map(([locationSearch]) => ({
          ...locationSearch,
          nameContains: this.searchFilter
        })
      )
    );

    this.locations$ = combinedLocationSearch$.pipe(
      tap(() => this.loading = true),
      mergeMap(locationSearch => this.locationService.findLocations$(locationSearch, {page: 0, size: 1000})),
      tap(() => this.loading = false),
      map(page => page.content),
      shareReplay()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const locationSearchChange = changes['locationSearch'];
    if (locationSearchChange && !this.isSameSearch(locationSearchChange.previousValue, this.locationSearch)) {
      this.locationSearch$?.next(this.locationSearch || {});
    }
  }

  select(selectedLocation?: Location) {
    this.selectedLocation = selectedLocation;
    this.onLocationSelected.emit(selectedLocation);
  }

  clear() {
    this.select(undefined);
  }

  handleSearchFilter() {
    this.searchFilter$.next(this.searchFilter);
  }

  private isSameSearch<T>(search1: T, search2: T): boolean {
    const json1 = JSON.stringify(search1);
    const json2 = JSON.stringify(search2);
    return json1 === json2;
  }
}
