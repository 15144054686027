import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {StockPallet} from "@model/stock-pallet";
import {BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, mergeMap, Observable, shareReplay, tap} from "rxjs";
import {PalletSearch} from "@model/search/pallet-search";
import {StockPalletService} from "@services/stock-pallet.service";
import {ComponentChanges} from "@util/component-change";

@Component({
  selector: 'foodbank-pallet-single-selection',
  templateUrl: './pallet-single-selection.component.html',
  styleUrls: ['./pallet-single-selection.component.scss']
})
export class PalletSingleSelectionComponent implements OnInit, OnChanges {

  @Input()
  selectedPallet?: StockPallet;

  @Input()
  palletSearch?: PalletSearch;
  palletSearch$!: BehaviorSubject<PalletSearch>;

  @Output()
  onPalletSelected: EventEmitter<StockPallet> = new EventEmitter<StockPallet>();

  pallets$!: Observable<StockPallet[]>;

  loading = true;

  idContains?: string;
  idContains$!: BehaviorSubject<string | undefined>;

  constructor(private stockPalletService: StockPalletService) {
  }

  ngOnInit(): void {
    this.palletSearch$ = new BehaviorSubject<PalletSearch>(this.palletSearch || {});

    this.idContains$ = new BehaviorSubject<string | undefined>(this.idContains);
    const filteredIdContains$: Observable<string|undefined> = this.idContains$.pipe(
      map(idContains => idContains ? idContains : undefined),
    );
    const actualPalletSearch$: Observable<PalletSearch> = combineLatest([this.palletSearch$, filteredIdContains$]).pipe(
      map(([palletSearch, idContains]) => ({
        ...palletSearch,
        idContains
      })),
    );

    this.pallets$ = actualPalletSearch$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      mergeMap(palletSearch =>
        this.stockPalletService.getStockPallets$(palletSearch, {page: 0, size: 1000})),
      tap(() => this.loading = false),
      map(page => page.content),
      map(pallets => this.selectedPallet ? this.makePartOfArray(this.selectedPallet, pallets) : pallets),
      shareReplay()
    );
  }

  ngOnChanges(changes: ComponentChanges<PalletSingleSelectionComponent>): void {
    const palletSearchChange = changes.palletSearch;
    if (palletSearchChange && !this.isSameSearch(palletSearchChange.previousValue, this.palletSearch)) {
      this.palletSearch$?.next(this.palletSearch || {});
    }
  }

  select(selectedPallet?: StockPallet) {
    this.selectedPallet = selectedPallet;
    console.log("p", selectedPallet)
    this.onPalletSelected.emit(selectedPallet);
  }

  clear() {
    this.select(undefined);
  }

  handleSearchFilter() {
    this.idContains$.next(this.idContains);
  }

  private isSameSearch<T>(search1: T, search2: T): boolean {
    const json1 = JSON.stringify(search1);
    const json2 = JSON.stringify(search2);
    return json1 === json2;
  }

  private makePartOfArray(selectedPallet: StockPallet, pallets: StockPallet[]) {
    if (pallets.indexOf(selectedPallet) < 0) {
      pallets.unshift(selectedPallet);
    }
    return pallets;
  }
}
