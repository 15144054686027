<p-table [value]="(((feadCampaignPage$ | async)?.content) || [])"
         [totalRecords]="((feadCampaignPage$ | async)?.totalElements || 0)"
         [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true" (onLazyLoad)="loadFeadCampaignList($event)" [rowHover]="true" [resizableColumns]="true"
         columnResizeMode="expand" [autoLayout]="true" dataKey="id">

    <ng-template pTemplate="header">
        <tr>
            <th>Organization</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Company</th>
            <th pSortableColumn="QUANTITY">
                <span i18n>Quantity</span>
                <p-sortIcon field="QUANTITY"></p-sortIcon>
            </th>
            <th>Shipped quantity</th>
            <th>Stock quantity</th>
            <th>Waiting quantity</th>
            <th>Refused quantity</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-feadCampaign>
        <tr>
            <td class="white-space-normal">
                <foodbank-organization [organization]="(feadCampaign.organization$ | async) || undefined"></foodbank-organization>
            </td>
            <td>{{feadCampaign.startDate}}</td>
            <td>{{feadCampaign.endDate}}</td>
            <td>{{feadCampaign.companyId}}</td>
            <td>{{feadCampaign.quantity}}</td>
            <td>{{feadCampaign.shippedQuantity}}</td>
            <td>{{feadCampaign.stockQuantity}}</td>
            <td>{{feadCampaign.waitingQuantity}}</td>
            <td>{{feadCampaign.refusedQuantity}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="footer">
        <tr>
            <td colspan="3">
            <td i18n>Totals</td>
            <td>{{totalQuantity$ | async}}</td>
            <td>{{totalShippedQuantity$ | async}}</td>
            <td>{{totalStockQuantity$ | async}}</td>
            <td>{{totalWaitingQuantity$ | async}}</td>
            <td>{{totalRefusedQuantity$ | async}}</td>
        </tr>
    </ng-template>
</p-table>
