import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, mergeMap, Observable, of, shareReplay, switchMap, take } from 'rxjs';
import {PalletType} from '@model/pallet-type';
import {ReceptionPalletCluster} from '@model/reception-pallet-cluster';
import {ReceptionItem} from '@model/reception-item';
import {ReceptionPalletClusterService} from '@services/reception-pallet-cluster.service';
import {FoodbankDatePickerEvent} from '@components/date/date-picker/date-picker.component';
import {ReceptionPalletClusterSearch} from "@model/search/reception-pallet-cluster-search";
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {Page} from "@typedefs/page";
import {ReceptionItemSummary} from "@model/reception-item-summary";
import { ReceptionItemService } from "@services/reception-item.service";

@Component({
  selector: 'foodbank-reception-pallet-cluster-table',
  templateUrl: './reception-pallet-cluster-table.component.html',
  styleUrl: './reception-pallet-cluster-table.component.scss'
})
export class ReceptionPalletClusterTableComponent implements OnInit, OnChanges {

  @Input()
  receptionItem!: ReceptionItem;
  @Input()
  receptionPalletClusterSearch: ReceptionPalletClusterSearch = {};
  @Input()
  editingEnabled = false;
  @Output()
  onPalletClusterChange = new EventEmitter<ReceptionPalletCluster>();

  refreshTrigger$ = new BehaviorSubject<void>(undefined);
  receptionPalletClusterSearch$!: BehaviorSubject<ReceptionPalletClusterSearch>;
  receptionItem$!: BehaviorSubject<ReceptionItem>;
  pagination$!: BehaviorSubject<Pagination>;

  receptionPalletClusterPage$!: Observable<Page<ReceptionPalletCluster>>;
  receptionItemSummary$ = new BehaviorSubject<ReceptionItemSummary | undefined>(undefined);
  saveReceptionPalletClusterSink$ = new BehaviorSubject<ReceptionPalletCluster | undefined>(undefined);

  private init = false;
  private receptionItemService = inject(ReceptionItemService);
  private receptionPalletClusterService = inject(ReceptionPalletClusterService);
  private paginationService = inject(PaginationService);

  ngOnInit() {
    this.receptionItem$ = new BehaviorSubject<ReceptionItem>(this.receptionItem);

    this.receptionPalletClusterSearch$ = new BehaviorSubject<ReceptionPalletClusterSearch>(this.receptionPalletClusterSearch);

    const combinedReceptionPalletClusterSearch$ = combineLatest([this.receptionItem$, this.receptionPalletClusterSearch$, this.refreshTrigger$]).pipe(
      distinctUntilChanged(),
      map(([receptionItem, receptionPalletClusterSearch]) => this.overrideReceptionItemInPalletClusterSearch(receptionItem, receptionPalletClusterSearch)
      )
    );

    const pagination = this.paginationService.getDefaultPagination();
    this.pagination$ = new BehaviorSubject<Pagination>(pagination);

    this.receptionPalletClusterPage$ = combineLatest([combinedReceptionPalletClusterSearch$, this.pagination$, this.refreshTrigger$]).pipe(
      distinctUntilChanged(),
      switchMap(([receptionPalletClusterSearch, pagination]) => this.receptionPalletClusterService.findReceptionPalletClusters(receptionPalletClusterSearch, pagination)),
      shareReplay()
    );

    combineLatest([this.receptionItem$, this.refreshTrigger$]).pipe(
      switchMap(([receptionItem]) => this.receptionItemService.getSummary$(receptionItem)),
    ).subscribe(summary => this.receptionItemSummary$.next(summary));

    this.init = true;

    this.saveReceptionPalletClusterSink$
      .pipe(
        filter(receptionPalletCluster => !!receptionPalletCluster),
        debounceTime(300)
      )
      .subscribe(receptionPalletCluster => this.doSaveReceptionPalletCluster(receptionPalletCluster!));
  }

  private overrideReceptionItemInPalletClusterSearch(receptionItem: ReceptionItem, receptionPalletClusterSearch: ReceptionPalletClusterSearch): ReceptionPalletClusterSearch {
    return {
      ...receptionPalletClusterSearch,
      receptionItem: receptionItem,
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['receptionItem'] && this.init) {
      this.receptionItem$.next(this.receptionItem);
    }

    if (changes['receptionPalletClusterSearch'] && this.init) {
      this.receptionPalletClusterSearch$.next(this.receptionPalletClusterSearch);
    }
  }

  updatePalletType(palletType: PalletType, pallet: ReceptionPalletCluster) {
    pallet.type$ = of(palletType);
    this.saveReceptionPalletCluster(pallet);
  }

  identity(pallet: any): ReceptionPalletCluster {
    return pallet;
  }

  addReceptionPalletCluster(receptionItem: ReceptionItem) {
      const newReceptionPalletCluster: Partial<ReceptionPalletCluster> = {
        type$: of(undefined),
        numberOfPallets: 1,
        quantity: 1,
        receptionItem$: this.receptionItem$,
        bestBeforeDate: receptionItem.bestBeforeDate,
      };

      this.doSaveReceptionPalletCluster(newReceptionPalletCluster as ReceptionPalletCluster);
  }

  saveReceptionPalletCluster(receptionPalletCluster: ReceptionPalletCluster) {
    this.saveReceptionPalletClusterSink$.next(receptionPalletCluster);
  }

  doSaveReceptionPalletCluster(receptionPalletCluster: ReceptionPalletCluster) {
    this.receptionPalletClusterService.saveReceptionPalletCluster$(receptionPalletCluster)
      .subscribe(savedPalletCluster => {
        this.onPalletClusterChange.emit(savedPalletCluster);

        if (!receptionPalletCluster.id) {
          this.refreshPalletClusterList();
        }

        this.refreshSummaryOnly(receptionPalletCluster);
      });
  }

  private refreshSummaryOnly(receptionPalletCluster: ReceptionPalletCluster) {
    receptionPalletCluster.receptionItem$.pipe(
      mergeMap(receptionItem => this.receptionItemService.getSummary$(receptionItem)),
      take(1)
    ).subscribe(summary => this.receptionItemSummary$.next(summary));
  }

  updateBestBeforeDate(pallet: ReceptionPalletCluster, $event: FoodbankDatePickerEvent) {
    pallet.bestBeforeDate = $event.utcValue as Date;
    this.saveReceptionPalletCluster(pallet);
  }

  removePallet(receptionPalletCluster: ReceptionPalletCluster) {
    if (!receptionPalletCluster.id) { // should not happen
      return;
    }

    this.receptionPalletClusterService.deleteReceptionPalletCluster$(receptionPalletCluster)
      .subscribe(_ => {
        this.refreshPalletClusterList();
        this.onPalletClusterChange.emit(receptionPalletCluster);
      });
  }

  refreshPalletClusterList() {
    this.refreshTrigger$.next();
  }
}
