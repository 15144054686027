import {Warehouse} from "@model/warehouse";
import {Preparation} from "@model/preparation";
import {Organization} from "@model/organization";
import {Article} from "@model/article";

export const SAME_WAREHOUSE = (warehouse1?: Warehouse, warehouse2?: Warehouse) => warehouse1?.id === warehouse2?.id;
export const SAME_PREPARATION = (preparation1?: Preparation, preparation2?: Preparation) => preparation1?.id === preparation2?.id;
export const SAME_ORGANIZAION = (organization1?: Organization, organization2?: Organization) => organization1?.id === organization2?.id;
export const SAME_ARTICLE = (article1?: Article, article2?: Article) => article1?.id === article2?.id;

export function JSON_EQUAL<T>(a: T, b: T): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}
