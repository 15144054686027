import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Organization} from '@model/organization';

@Component({
  selector: 'foodbank-fead-planning-quantity-cell-editor',
  templateUrl: './fead-planning-quantity-cell-editor.component.html',
  styleUrls: ['./fead-planning-quantity-cell-editor.component.scss']
})
export class FeadPlanningQuantityCellEditorComponent implements OnInit {

  @Input() quantity!: number;
  @Input() organization: Organization | null | undefined;
  @Input() period!: string;
  @Output() quantityChange = new EventEmitter<number>();

  isPreferredWeekForDelivery: boolean = true;

  ngOnInit() {
    if (this.organization) {
    this.isPreferredWeekForDelivery = this.organization.isPreferredWeekForDelivery(this.period);
    }
  }

  updateQuantity(quantity: number) {
    this.quantity = quantity;
    this.quantityChange.emit(quantity);
  }
}
