import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LanguageCode } from '@model/languageInfo';
import { LanguageService } from '@services/language.service';

@Directive({
    selector: '[foodbankLanguage]'
})
export class LanguageDirective implements OnInit {

    @Input()
    foodbankLanguage: LanguageCode = 'en';

    constructor(private viewRef: ViewContainerRef,
                private template: TemplateRef<any>,
                private languageService: LanguageService) {
    }

    ngOnInit(): void {
        this.languageService.getLanguage$().subscribe(language => {
            if (language.code === this.foodbankLanguage) {
                this.viewRef.clear();
                this.viewRef.createEmbeddedView(this.template);
            } else {
                this.viewRef.clear();
            }
        });
    }

}
