import {map, Observable, shareReplay} from "rxjs";
import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {environment} from "@environments/environment";
import {LocationSearch} from "@model/search/location-search";
import {Pagination} from "@services/pagination";
import {Page} from "@typedefs/page";
import {LocationDto, LocationSearchDto} from "@typedefs/stock-rest";
import {Location} from "@model/location";
import {WarehouseService} from "@services/warehouse.service";

type LocationPagination = Pagination

@Injectable({
  providedIn: 'root'
})

export class LocationService {
  constructor(private httpClient: HttpClient,
              private warehouseService: WarehouseService) {
  }

  public getLocation$(warehouseId: number, locationName: string): Observable<Location> {
    return this.httpClient.get<LocationDto>(`${environment.apiUrl}/warehouses/${warehouseId}/locations/${locationName}`)
      .pipe(
        map(locationDto => this.mapToLocation$(locationDto)),
        shareReplay(1)
      );
  }

  public findLocations$(locationSearch: LocationSearch, pagination?: LocationPagination): Observable<Page<Location>> {
    const locationSearchDto = this.mapToLocationSearchDto(locationSearch);

    return this.httpClient.post<Page<LocationDto>>(`${environment.apiUrl}/locations/search`, locationSearchDto, {params: pagination})
      .pipe(map(locationPage => this.loadLocationPage(locationPage)));
  }

  private loadLocationPage(locationDtoPage: Page<LocationDto>): Page<Location> {
    return {
      ...locationDtoPage,
      content: this.loadLocationDetailsList(locationDtoPage.content)
    };
  }

  public loadLocationDetailsList(locationDtos: LocationDto[]) {
    return locationDtos.map(location => this.mapToLocation$(location));
  }

  public mapToLocation$(locationDto: LocationDto): Location {
    return {
      g1: locationDto.g1,
      g2: locationDto.g2,
      name: locationDto.name,
      warehouse$: this.warehouseService.getWarehouse$(locationDto.warehouseId).pipe(shareReplay(1))
    };
  }

  mapToLocationSearchDto(locationSearch: LocationSearch): LocationSearchDto {
    return {
      warehouseId: locationSearch.warehouse?.id,
      nameContains: locationSearch.nameContains,
    }
  }
}
