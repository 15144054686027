import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {FeadCampaign} from '@model/fead-campaign';
import {FeadCampaignSearch} from '@model/search/fead-campaign-search';
import {FeadCampaignDto} from '@typedefs/stock-rest';
import {ArticleService} from './article.service';
import {Article} from '@model/article';
import {Organization} from '@model/organization';
import {OrganizationService} from '@services/organization.service';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';

type FeadCampaignPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class FeadCampaignService {

  constructor(private httpClient: HttpClient,
              private articleService: ArticleService,
              private organizationService: OrganizationService) {
  }

  public findFeadCampaigns$(pagination?: FeadCampaignPagination, feadCampaignSearch?: FeadCampaignSearch): Observable<Page<FeadCampaign>> {
    return this.httpClient.post<Page<FeadCampaignDto>>(`${environment.apiUrl}/fead-campaigns/search`, feadCampaignSearch, {params: pagination})
      .pipe(map(feadCampainPage => this.loadFeadCampaignPage(feadCampainPage)));
  }

  public findFeadCampaignIdentifiers$(feadCampaignSearch?: FeadCampaignSearch): Observable<FeadCampaignIdentifier[]> {
    return this.httpClient.post<FeadCampaignIdentifier[]>(`${environment.apiUrl}/fead-campaigns/search/identifiers`, feadCampaignSearch).pipe(
      shareReplay()
    );
  }

  public findArticleFeadCampaignsRounds$(article: Article, year: number): Observable<number> {
    return this.httpClient.get<number>(`${environment.apiUrl}/fead-campaigns/${year}/articles/${article.id}/rounds`);
  }

  private loadFeadCampaignPage(feadCampaignDtoPage: Page<FeadCampaignDto>) {
    return {
      ...feadCampaignDtoPage,
      content: feadCampaignDtoPage.content.map(feadCampaign => this.loadFeadCampaign(feadCampaign))
    };
  }

  public loadFeadCampaign(feadCampaignDto: FeadCampaignDto): FeadCampaign {
    return {
      ...feadCampaignDto,
      article$: this.articleService.getArticle$(feadCampaignDto.articleId).pipe(shareReplay(1)),
      organization$: this.organizationService.getOrganization$(feadCampaignDto.organizationId)
    };
  }

}
