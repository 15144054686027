<div class="card p-fluid" *ngIf="formInput$ | async as formInput">
  <form (ngSubmit)="save(newReceptionForm)" #newReceptionForm="ngForm">
    <div class="field grid">
      <label class="col-12 mb-2 md:col-2 md:mb-0" for="supplier">Supplier *</label>
      <foodbank-supplier-selection ngDefaultControl class="col-12 md:col-10" id="supplier" name="supplier"
                                   *ngIf="supplierSearch$ | async as supplierSearch"
                                   [supplierSearch]="supplierSearch"
                                   [(ngModel)]="formInput.supplier"
                                   (ngModelChange)="updateSupplier($event)" ngDefaultControl [required]="true"></foodbank-supplier-selection>
    </div>
    <div class="field grid">
      <label class="col-12 mb-2 md:col-2 md:mb-0" for="warehouse">Warehouse *</label>
      <foodbank-warehouse-selection ngDefaultControl class="col-12 md:col-10" id="warehouse" name="warehouse"
                                    *ngIf="warehouseSearch$ | async as warehouseSearch"
                                    [warehouseSearch]="warehouseSearch"
                                    [(ngModel)]="formInput.warehouse"
                                    (ngModelChange)="updateWarehouse($event)" ngDefaultControl [required]="true"></foodbank-warehouse-selection>
    </div>
    <div class="field grid">
      <label class="col-12 mb-2 md:col-2 md:mb-0" for="date">Date *</label>
      <foodbank-date-picker class="col-12 md:col-10" id="date" name="date"
                            [(ngModel)]="formInput.date" ngDefaultControl [required]="true"
                            (onSelect)="updateDate($event)"
                            [showSelectionOptions]="false"></foodbank-date-picker>
    </div>
    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary pointer-events-auto" type="submit" [disabled]="!newReceptionForm.valid"
        pTooltip="All fields are required." tooltipPosition="bottom" [tooltipDisabled]="!!newReceptionForm.valid" tooltipStyleClass="mt-1"></p-button>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"></p-button>
      </div>
    </div>
  </form>
</div>
