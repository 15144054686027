<div class="flex flex-row-reverse flex-wrap mt-3 mr-4">
  <button type="button" class="p-button-rounded p-button-danger"
          pButton pRipple label="Close" (click)="close()"></button>
  <button type="button" icon="pi pi-print" class="p-button-rounded mr-2"
          *ngIf="stocks && stocks.length > 0"
          pButton pRipple label="Print"
          printTitle="Pallet Labels"
          ngxPrint [useExistingCss]="true" printSectionId="print-section"></button>
  <p *ngIf="!(stocks && stocks.length > 0)" class="text-2xl mx-auto">There are no pallets</p>
</div>
<ng-container *ngIf="stocks && stocks.length > 0">
  <div id="print-section">
    <div class="grid px-3">
      <foodbank-pallet-label *ngFor="let stock of stocks" [stock]="stock" class="col-fixed mb-8"></foodbank-pallet-label>
    </div>
  </div>
</ng-container>
