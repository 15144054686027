import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {FeadPlanningByArticleWarehouseDto, FeadPlanningSearchDto} from '@typedefs/stock-rest';
import {ArticleService} from '@services/article.service';
import {WarehouseService} from '@services/warehouse.service';
import {StockFeadPlanning, StockFeadPlanningDto} from '@model/stock-fead-planning';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';
import {FeadPlanningService} from '@services/fead-planning.service';

export type FeadPlanningPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class FeadPlanningSummaryService {

  constructor(private httpClient: HttpClient,
              private articleService: ArticleService,
              private warehouseService: WarehouseService,
              private feadPlanningService: FeadPlanningService) {
  }

  public getFeadPlanning$(feadPlanningSearch: FeadPlanningSearch, relevantOnly: boolean, pagination: FeadPlanningPagination): Observable<Page<StockFeadPlanning>> {
    const feadPlanningSearchDto: FeadPlanningSearchDto = this.feadPlanningService.mapToFeadPlanningSearchDto(feadPlanningSearch);
    return this.httpClient.post<Page<FeadPlanningByArticleWarehouseDto>>(`${environment.apiUrl}/fead/plannings/by-article-warehouse/search?relevantOnly=${relevantOnly}`, feadPlanningSearchDto, {params: pagination}).pipe(
      map((feadPlanningEntryDtoPage: Page<FeadPlanningByArticleWarehouseDto>) => ({
        ...feadPlanningEntryDtoPage,
        content: this.groupByPeriod(feadPlanningEntryDtoPage.content)
      })),
      shareReplay()
    );
  }

  private groupByPeriod(feadPlanningEntries: FeadPlanningByArticleWarehouseDto[]): StockFeadPlanning[] {
    const stockFeadPlanningDtos: StockFeadPlanningDto[] = [];

    feadPlanningEntries.forEach(feadPlanningEntry => this.getStockFeadPlanningDto(stockFeadPlanningDtos, feadPlanningEntry));

    return stockFeadPlanningDtos.map(stockFeadPlanningDto => this.mapToStockFeadPlanning(stockFeadPlanningDto));
  }

  private mapToStockFeadPlanning(stockFeadPlanningDto: StockFeadPlanningDto): StockFeadPlanning {
    return {
      ...stockFeadPlanningDto,
      article$: this.articleService.getArticle$(stockFeadPlanningDto.articleId).pipe(shareReplay(1)),
      warehouse$: this.warehouseService.getWarehouse$(stockFeadPlanningDto.warehouseId).pipe(shareReplay(1))
    };
  }

  /**
   * Get fead planning for given stock item (article/warehouse combination)
   */
  private getStockFeadPlanningDto(stockFeadPlanningDtos: StockFeadPlanningDto[], feadPlanningEntry: FeadPlanningByArticleWarehouseDto) {
    const stockFeadPlanningDto = stockFeadPlanningDtos.find(stockFeadPlanningDto => this.isForStockPlanningEntry(stockFeadPlanningDto, feadPlanningEntry)) || this.createStockFeadPlanningDto(stockFeadPlanningDtos, feadPlanningEntry);

    stockFeadPlanningDto.periodPlanningQuantityMap.set(feadPlanningEntry.period, feadPlanningEntry.planningQuantity);

    return stockFeadPlanningDto;
  }

  private isForStockPlanningEntry(periodFeadPlanning: StockFeadPlanningDto, feadPlanningEntry: FeadPlanningByArticleWarehouseDto) {
    return periodFeadPlanning.articleId===feadPlanningEntry.articleId && periodFeadPlanning.warehouseId===feadPlanningEntry.warehouseId;
  }

  private createStockFeadPlanningDto(stockFeadPlanningDtos: StockFeadPlanningDto[], feadPlanningEntry: FeadPlanningByArticleWarehouseDto) {
    const stockFeadPlanningDto: StockFeadPlanningDto = {
      warehouseId: feadPlanningEntry.warehouseId,
      articleId: feadPlanningEntry.articleId,
      stockQuantity: feadPlanningEntry.stockQuantity,
      unitStockQuantity: feadPlanningEntry.unitStockQuantity,
      periodPlanningQuantityMap: new Map<string, number>()
    };
    stockFeadPlanningDtos.push(stockFeadPlanningDto);
    return stockFeadPlanningDto;
  }
}
