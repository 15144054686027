<p-table #dt
         [value]="(preparationPage()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="preparationPage()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[50,100,200]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="lazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         [multiSortMeta]="[{ field: 'DATE', order: 1 },{ field: 'WAREHOUSE', order: 1 },{ field: 'LOCATION', order: 1 }]"
         selectionMode="single"
         [(selection)]="selectedPreparation"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div class="grid">
        <h2 class="col-fixed m-0 mt-2" i18n *ngIf="fead()">ESF preparations</h2>
        <h2 class="col-fixed m-0 mt-2" i18n *ngIf="!fead()">Preparations</h2>
        <p-button *ngIf="fead()" class="col-fixed mt-2" icon="pi pi-plus" label="New preparation" [rounded]="true" (onClick)="openNewESFPreparationDialog()"></p-button>
        <p-button *ngIf="!fead()" class="col-fixed mt-2" icon="pi pi-plus" label="New preparation" [rounded]="true" (onClick)="openNewPreparationDialog()"></p-button>
        <p-toggleButton [ngModel]="expedition()" (ngModelChange)="expedition.set($event)" class="col-fixed mt-2" offIcon="pi pi-truck" onIcon="pi pi-truck" offLabel="Expedition" onLabel="Expedition" [disabled]="!selectedPreparation()"></p-toggleButton>
      </div>
      <span class="report-block mt-2 md:mt-0 p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" placeholder="Search..." class="w-full sm:w-auto"/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="ID">
        <span i18n>Preparation</span>
        <p-sortIcon field="ID"></p-sortIcon>
      </th>
      <th pSortableColumn="DATE">
        <span i18n>Date</span>
        <p-sortIcon field="DATE"></p-sortIcon>
      </th>
      <th pSortableColumn="WAREHOUSE">
        <span i18n>Warehouse</span>
        <p-sortIcon field="WAREHOUSE"></p-sortIcon>
      </th>
      <th pSortableColumn="LOCATION">
        <span i18n *ngIf="fead()">Week</span>
        <span i18n *ngIf="!fead()">Zone</span>
        <p-sortIcon field="LOCATION"></p-sortIcon>
      </th>
      <th>
        <span i18n>Actions</span>
      </th>
    </tr>
    <tr>
      <th></th>
      <th></th>
      <th>
        <p-columnFilter field="warehouse" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <ng-container *ngIf="warehouseSearch() as warehouseSearch">
              <foodbank-warehouse-selection
                [ngModel]="warehouse()"
                (ngModelChange)="warehouse.set($event)"
                [warehouseSearch]="warehouseSearch"
                ngDefaultControl
              ></foodbank-warehouse-selection>
            </ng-container>
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-preparation>
    <tr [pSelectableRow]="preparation">
      <td class="id-col">
        {{ preparation.id }}
      </td>
      <td>
        {{ preparation.date | date: 'yyyy-MM-dd' }}
      </td>
      <td>
        <foodbank-warehouse ngDefaultControl [ngModel]="(preparation.warehouse$ | async) || undefined" [showId]="true"></foodbank-warehouse>
      </td>
      <td>
        <foodbank-preparation-zone ngDefaultControl *ngIf="!fead()" [ngModel]="(preparation.zone$ | async) || undefined"></foodbank-preparation-zone>
        <span *ngIf="fead()">{{ preparation.location }}</span>
      </td>
      <td>
        <button pButton pRipple *ngIf="!fead()" icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditPreparationDialog(preparation)"></button>
        <button pButton *ngIf="!fead()" class="p-button-rounded p-button-danger" icon="fa-solid fa-ban" label="Close"
                [pTooltip]="'Updates preparation status and removes stock from preparation zone'" tooltipPosition="top" [showDelay]="500"
                (click)="close(preparation)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-toast key="closed">
  <ng-template let-message pTemplate="message">
    <div class="p-toast-message-text ng-star-inserted" data-pc-section="text">
      <div class="p-toast-summary" data-pc-section="summary">
        {{ message.summary }}
      </div>
      <div class="p-toast-detail" data-pc-section="detail">
        <p>{{ message.detail }}</p>
        <p-button size="small" label="Rollback" (onClick)="reopenPreparation(message.data)"/>
      </div>
    </div>
  </ng-template>
</p-toast>

<!--preparation items-->
<foodbank-fead-preparation-details
  ngDefaultControl
  *ngIf="selectedPreparation() as selectedPreparation"
  [preparation]="selectedPreparation"
  [expedition]="expedition()"
  [refreshPreparationTrigger]="refreshPreparationTrigger()"
></foodbank-fead-preparation-details>
