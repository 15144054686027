import {Component, inject, Input, OnInit} from '@angular/core';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  mergeMap,
  Observable,
  shareReplay
} from 'rxjs';
import {ReceptionItem} from '@model/reception-item';
import {Page} from '@typedefs/page';
import {ReceptionPagination} from '@components/reception/reception-list/reception-list.component';
import {Reception} from '@model/reception';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ReceptionItemEditComponent} from '@components/reception/reception-item-edit/reception-item-edit.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ReceptionItemService} from "@services/reception-item.service";
import {ReceptionItemSearch} from "@model/search/reception-item-search";

@Component({
  selector: 'foodbank-reception-item-list',
  templateUrl: './reception-item-list.component.html',
  styleUrls: ['./reception-item-list.component.scss'],
  providers: [DialogService]
})
export class ReceptionItemListComponent implements OnInit {
  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  @Input() reception!: Reception;

  reception$!: BehaviorSubject<Reception>;

  receptionItemPage$!: Observable<Page<ReceptionItem>>;
  pagination$!: BehaviorSubject<ReceptionPagination>;

  dialogRef: DynamicDialogRef | undefined;

  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  private paginationService = inject(PaginationService);
  private receptionItemService = inject(ReceptionItemService);
  private confirmationService = inject(ConfirmationService);
  private messageService = inject(MessageService);
  private dialogService = inject(DialogService);

  ngOnInit() {
    this.reception$ = new BehaviorSubject(this.reception);

    const pagination = this.paginationService.getDefaultPagination();
    this.pagination$ = new BehaviorSubject<ReceptionPagination>(pagination);

    const receptionItemSearch$: Observable<ReceptionItemSearch> = combineLatest([this.reception$, this.refreshTrigger$]).pipe(
      map(([reception]) => ({
          reception: reception
        })
      )
    );

    this.receptionItemPage$ = combineLatest([receptionItemSearch$, this.pagination$, this.refreshTrigger$])
      .pipe(
        distinctUntilChanged(),
        mergeMap(([receptionItemSearch, pagination, _]) => this.receptionItemService.getReceptionItems$(receptionItemSearch, pagination)),
        shareReplay()
      );
  }

  openEditReceptionItemDialog(receptionItem: ReceptionItem) {
    if (this.reception?.valid) {
      return;
    }

    this.dialogRef = this.dialogService.open(ReceptionItemEditComponent, {
      header: 'Edit Reception Item ' + receptionItem.rank,
      width: '70%',
      data: { receptionItem }
    });

    this.dialogRef.onClose.subscribe(_ => this.refresh());
  }

  deleteReceptionItem(receptionItem: ReceptionItem) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete reception item ${receptionItem.rank} ?`,
      header: `Delete reception item ${receptionItem.rank} ?`,
      icon: 'pi pi-question-circle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.receptionItemService.deleteReceptionItem$(receptionItem)
          .subscribe(
            _ => {
              this.messageService.add({severity: 'info', summary: 'Deleted', detail: 'Reception item deleted'});
              this.refresh();
            },
          );
      }
    });
  }

  private refresh() {
    this.refreshTrigger$.next();
  }
}
