import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArticleStorageConditionDto} from '@typedefs/stock-rest';
import {ArticleStorageConditionSearch} from '@model/search/article-storage-condition-search';
import {map, Observable} from 'rxjs';
import {ArticleStorageConditionService} from '@services/article-storage-condition.service';
import {ArticleStorageCondition} from '@model/article-storage-condition';

@Component({
  selector: 'foodbank-article-storage-condition-selection',
  templateUrl: './article-storage-condition-selection.component.html',
  styleUrls: ['./article-storage-condition-selection.component.scss']
})
export class ArticleStorageConditionSelectionComponent implements OnInit {

  @Input()
  selectionMode: 'multiple' | 'single' = 'multiple';
  @Input()
  selectedArticleStorageCondition: ArticleStorageCondition | undefined | null;
  @Output()
  selectedArticleStorageConditionChange: EventEmitter<ArticleStorageCondition> = new EventEmitter<ArticleStorageCondition>();

  @Input()
  selectedArticleStorageConditions: ArticleStorageCondition[] = [];
  @Input()
  articleStorageConditionSearch: ArticleStorageConditionSearch = {};
  @Output()
  onArticleStorageConditionSelected: EventEmitter<ArticleStorageCondition[]> = new EventEmitter<ArticleStorageCondition[]>();

  articleStorageConditions$: Observable<ArticleStorageCondition[]> | undefined | null;

  constructor(private articleStorageConditionService: ArticleStorageConditionService) {
  }

  ngOnInit(): void {
    this.articleStorageConditions$ = this.articleStorageConditionService.findArticleStorageConditions$({
      page: 0,
      size: 1000
    }, this.articleStorageConditionSearch)
      .pipe(map(page => page.content));
  }

  select(selectedArticleStorageConditions: ArticleStorageCondition[]) {
    this.selectedArticleStorageConditions = selectedArticleStorageConditions;
    this.onArticleStorageConditionSelected.emit(selectedArticleStorageConditions);
  }

  selectOne(selectedArticleStorageCondition: ArticleStorageCondition) {
    this.selectedArticleStorageCondition = selectedArticleStorageCondition;
    this.selectedArticleStorageConditionChange.emit(selectedArticleStorageCondition);
  }

  clear() {
    this.selectedArticleStorageConditions = [];
    this.onArticleStorageConditionSelected.emit([]);
  }
}
