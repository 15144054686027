import {Component, computed, inject, input, signal, Signal, WritableSignal} from '@angular/core';
import {map, pipe, switchMap, tap} from "rxjs";
import {TableLazyLoadEvent} from "primeng/table";
import {Preparation} from '@model/preparation';
import {PreparationPagination} from '@services/preparation.service';
import {PreparationItemService} from '@services/preparation-item.service';
import {PreparationItemSearch} from '@model/search/preparation-item-search';
import {Page} from '@typedefs/page';
import {PreparationItem} from '@model/preparation-item';
import {ArticleSearch} from '@model/search/article-search';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {OrganizationSearch} from '@model/search/organization-search';
import {Organization} from '@model/organization';
import {Article} from '@model/article';
import {ActivatedRoute} from '@angular/router';
import {ExpeditionService} from "@services/expedition.service";
import {Stock} from "@model/stock";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {Pagination} from "@services/pagination";
import {formatDate} from "@angular/common";

@Component({
  selector: 'foodbank-fead-preparation-details',
  templateUrl: './fead-preparation-details.component.html',
  styleUrls: ['./fead-preparation-details.component.scss']
})
export class FeadPreparationDetailsComponent {

  preparation = input.required<Preparation>();
  expedition = input.required<boolean>();

  selectedPreparationItems: PreparationItem[] = [];

  // selected filters
  selectedOrganization = signal<Organization | undefined>(undefined);
  selectedArticle = signal<Article | undefined>(undefined);

  expeditionComment = signal<string | undefined>(undefined);
  // main search
  pagination: WritableSignal<PreparationPagination>;
  preparationItemSearch: Signal<PreparationItemSearch>;

  preparationItemPage: Signal<Page<PreparationItem> | undefined>;
  // searches for table filters
  organizationSearch: Signal<OrganizationSearch | undefined>; // which articles should be available in column filter?
  articleSearch: Signal<ArticleSearch | undefined>; // which articles should be available in column filter?

  fead = input(false);

  loading = false;

  refreshPreparationTrigger = input(0);

  private refreshTrigger = signal(0);
  private preparationItemService = inject(PreparationItemService);

  private paginationService = inject(PaginationService);

  private expeditionService = inject(ExpeditionService);

  private route = inject(ActivatedRoute);

  constructor() {
    // this.fead = this.route.url.pipe(map(segments => segments[0].path === 'fead'), shareReplay());
    this.pagination = this.paginationService.getDefaultPaginationSignal();

    this.articleSearch = pipeSignal(this.preparation, pipe(
        map(preparation => ({
          preparation: preparation
        })),
      )
    );

    const expeditionCommentText = this.generateExpeditionCommentText();
    this.expeditionComment = signal(expeditionCommentText);

    // all organizations are displayed right now; is this right?
    this.organizationSearch = pipeSignal(this.preparation, pipe(
        map(preparation => this.createOrganizationSearch(preparation)),
      )
    );
    this.preparationItemSearch = computed(() => {
        this.refreshPreparationTrigger(); // make sure we get updates, even if all other object instances are the same
        return {
          preparation: this.preparation(),
          article: this.selectedArticle(),
          organization: this.selectedOrganization(),
        };
      }
    );
    const searchArguments: Signal<[PreparationItemSearch, Pagination, number]> = computed(() => [this.preparationItemSearch(), this.pagination(), this.refreshTrigger()]);
    this.preparationItemPage = pipeSignal(searchArguments, pipe(
        tap(() => this.loading = true),
        switchMap(([preparationItemSearch, pagination]) => this.preparationItemService.find(preparationItemSearch, pagination)),
        tap(() => this.loading = false),
      )
    );
  }

  private needsRefresh(preparationItemSearch1: PreparationItemSearch, preparationItemSearch2: PreparationItemSearch): boolean {
    // same article / organization don't require a reload, but a new input preparation (even with same id) does, as it probably means it was reloaded in another component
    console.log('***********', preparationItemSearch1.preparation, preparationItemSearch2.preparation, preparationItemSearch1.preparation === preparationItemSearch2.preparation);
    return preparationItemSearch1.preparation === preparationItemSearch2.preparation &&
      preparationItemSearch1.article?.id === preparationItemSearch2.article?.id &&
      preparationItemSearch1.organization?.id === preparationItemSearch2.organization?.id;
  }

  private generateExpeditionCommentText(): string {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate, 'dd/MM/yyyy', 'en');

    return `Expedition of ${formattedDate}`;
  }

  private createOrganizationSearch(preparation: Preparation) {
    return {
      preparationItemSearch: {
        preparation: preparation
      }
    };
  }

  lazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  generateExpedition() {
    const preparation = this.preparation();
    const expeditionCommentText = this.expeditionComment() || '?';
    this.expeditionService.dispatchExpedition$(preparation, expeditionCommentText)
      .subscribe(_ => this.refresh());
  }

  clearSelection() {
    this.selectedPreparationItems = [];
  }

  autoFillExpedition() {
    const preparation = this.preparation();
    const updatedPreparation$ = this.expeditionService.autofillExpedition$(preparation)
      .subscribe(_ => this.refresh());
  }

  identity(preparationItem: any): PreparationItem {
    return preparationItem;
  }

  private refresh() {
    this.refreshTrigger.update(value => value + 1);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  updateDispatchWeight(preparationItem: PreparationItem, _: Stock, dispatchWeight: number) {
    preparationItem.dispatchWeight = dispatchWeight;
    preparationItem.dispatchUnitCount = undefined;
    preparationItem.dispatchParcelCount = undefined;

    this.preparationItemService.savePreparationItem$(preparationItem).subscribe()
  }

  updateDispatchUnitCount(preparationItem: PreparationItem, _: Stock, dispatchUnitCount: number) {
    preparationItem.dispatchUnitCount = dispatchUnitCount;
    preparationItem.dispatchParcelCount = undefined;
    preparationItem.dispatchWeight = undefined;

    this.preparationItemService.savePreparationItem$(preparationItem).subscribe()
  }

  updateDispatchParcelCount(preparationItem: PreparationItem, _: Stock, dispatchParcelCount: number) {
    preparationItem.dispatchParcelCount = dispatchParcelCount;
    preparationItem.dispatchUnitCount = undefined;
    preparationItem.dispatchWeight = undefined;

    this.preparationItemService.savePreparationItem$(preparationItem).subscribe();
  }

  calcDisplayDispatchWeight(preparationItem: PreparationItem, stock: Stock): number | undefined {
    const dispatchUnitCount = preparationItem.dispatchUnitCount ?? this.calcUnitCountFromParcelCount(preparationItem.dispatchParcelCount, stock.unitsPerParcel);
    return preparationItem.dispatchWeight ?? this.calcStockWeight(dispatchUnitCount, stock.unitWeight);
  }

  calcDisplayDispatchUnitCount(preparationItem: PreparationItem, stock: Stock): number | undefined {
    return preparationItem.dispatchUnitCount ?? this.calcUnitCountFromParcelCount(preparationItem.dispatchParcelCount, stock.unitsPerParcel);
  }

  calcDisplayDispatchParcelCount(preparationItem: PreparationItem, stock: Stock): number | undefined {
    return preparationItem.dispatchParcelCount ?? this.calcParcelCountFromUnitCount(preparationItem.dispatchUnitCount, stock.unitsPerParcel);
  }

  calcStockWeight(dispatchUnitCount?: number, unitWeight?: number): number | undefined {
    console.log(dispatchUnitCount, unitWeight);
    return dispatchUnitCount && unitWeight ? dispatchUnitCount * unitWeight : undefined;
  }

  calcParcelCountFromUnitCount(unitCount?: number, unitsPerParcel?: number): number | undefined {
    return unitCount && unitsPerParcel ? unitCount / unitsPerParcel : undefined;
  }

  calcUnitCountFromParcelCount(parcelCount?: number, unitsPerParcel?: number): number | undefined {
    return parcelCount && unitsPerParcel ? parcelCount * unitsPerParcel : undefined;
  }

}
