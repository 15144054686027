import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable, of, shareReplay} from "rxjs";
import {CompanyDto, WarehouseDto} from '@typedefs/stock-rest';
import {environment} from '@environments/environment';
import {Company} from '@model/company';
import {Warehouse} from '@model/warehouse';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private httpClient: HttpClient) {
  }

  public getCompany$(id: string): Observable<Company> {
    return this.httpClient.get<CompanyDto>(`${environment.apiUrl}/companies/${id}`)
      .pipe(
        map(company => this.loadCompany(company)),
        shareReplay()
      );
  }

  private loadCompany(companyDto: CompanyDto): Company {
    const warehouse$ = companyDto.defaultWarehouseId ? this.getWarehouse$(companyDto.defaultWarehouseId) : of(undefined);
    return {
      ...companyDto,
      warehouse$
    }
  }

  private getWarehouse$(id: number): Observable<Warehouse> {
    return this.httpClient.get<WarehouseDto>(`${environment.apiUrl}/warehouses/${id}`)
      .pipe(
        map(warehouse => this.loadWarehouse(warehouse)),
        shareReplay()
      );
  }

  private loadWarehouse(warehouseDto: WarehouseDto): Warehouse {
    return {
      ...warehouseDto,
      company$: this.getCompany$(warehouseDto.companyId)
    }
  }
}
