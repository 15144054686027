import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { combineLatest, forkJoin, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {PreparationStockSelectionDto, PreparationSelectionSearchDto} from '@typedefs/stock-rest';
import {PreparationService} from '@services/preparation.service';
import {StockService} from '@services/stock.service';
import {PaginationService} from '@services/pagination.service';
import {PreparationStockSelection} from "@model/preparation-stock-selection";
import {PreparationSelectionSearch} from "@model/search/preparation-selection-search";
import {Preparation} from "@model/preparation";

export type PreparationSelectionPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class PreparationSelectionService {

  constructor(private httpClient: HttpClient,
              private preparationService: PreparationService,
              private stockService: StockService,
              private paginationService: PaginationService) {
  }

  public find(preparationSelectionSearch: PreparationSelectionSearch, pagination?: PreparationSelectionPagination): Observable<Page<PreparationStockSelection>> {
    const preparationSelectionSearchDto = this.mapToPreparationSelectionSearchDto(preparationSelectionSearch);
    const preparationSelections = this.httpClient.post<Page<PreparationStockSelectionDto>>(`${environment.apiUrl}/preparations/selections/search`, preparationSelectionSearchDto, {params: pagination});
    return this.paginationService.mapResults(preparationSelections, preparationSelectionDto => this.mapToPreparationSelection(preparationSelectionDto))
      .pipe(shareReplay());
  }

  savePreparationSelection$(preparationSelection: PreparationStockSelection): Observable<PreparationStockSelection> {
    return this.mapToPreparationSelectionDto$(preparationSelection).pipe(
      switchMap(preparationSelectionDto => this.httpClient.post<PreparationStockSelectionDto>(`${environment.apiUrl}/preparations/selections`, preparationSelectionDto)),
      map(preparationSelectionDto => this.mapToPreparationSelection(preparationSelectionDto))
    );
  }

  savePreparationSelections$(preparation: Preparation, preparationSelections: PreparationStockSelection[]): Observable<PreparationStockSelection[]> {
    if (!preparationSelections || preparationSelections.length === 0) {
      return of([])
    }

    return forkJoin(preparationSelections.map(preparationSelection => this.mapToPreparationSelectionDto$(preparationSelection))).pipe(
      switchMap(preparationSelectionDtos => this.httpClient.post<PreparationStockSelectionDto[]>(`${environment.apiUrl}/preparations/${preparation.id}/selections`, preparationSelectionDtos)),
      map(preparationSelectionDtos => preparationSelectionDtos.map(preparationSelectionDto => this.mapToPreparationSelection(preparationSelectionDto)))
    )
  }

  deletePreparationSelection$(preparationSelection: PreparationStockSelection): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/preparations/selections/${preparationSelection.id}`);
  }

  public mapToPreparationSelection(preparationSelectionDto: PreparationStockSelectionDto): PreparationStockSelection {
    return {
      ...preparationSelectionDto,
      preparation$: this.preparationService.getPreparation$(preparationSelectionDto.preparationId),
      stock$: this.stockService.getStock$(preparationSelectionDto.stockId),
    };
  }

  public mapToPreparationSelectionSearchDto(preparationSelectionSearch: PreparationSelectionSearch): PreparationSelectionSearchDto {
    return {
      ...preparationSelectionSearch,
      preparationId: preparationSelectionSearch.preparation?.id,
      stockId: preparationSelectionSearch.stock?.id,
      stockSearchDto: preparationSelectionSearch.stockSearch ? this.stockService.mapToStockSearchDto(preparationSelectionSearch.stockSearch) : undefined,
    }
  }

  private mapToPreparationSelectionDto$(preparationSelection: PreparationStockSelection): Observable<PreparationStockSelectionDto> {
    const preparation$ = preparationSelection.preparation$;
    const stock$ = preparationSelection.stock$;

    return combineLatest([preparation$, stock$]).pipe(
      map(([preparation, stock]) => ({
        ...preparationSelection,
        preparationId: preparation.id,
        stockId: stock.id,
      })));
  }
}
