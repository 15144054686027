// just for type safety
export type SafeOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function copyCommonFields<T, U, K extends keyof T>(source: T, excludedKeys: K[]): T | U {
  let result = {} as T | U;
  for (const key in source) {
    const excluded = excludedKeys.includes(key as unknown as K);
    if (!excluded) {
      (result as any)[key] = (source as any)[key];
    }
  }
  return result;
}
