<p-dropdown [ngModel]="selectedPallet"
            (onChange)="select($event.value)"
            [options]="(pallets$ | async) || []"
            optionLabel="id"
            [placeholder]="loading ? 'Loading...' : 'None'"
            [filter]="true"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="id"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="filter">
    <input type="text" pInputText pAutoFocus [autofocus]="true" placeholder="search" [(ngModel)]="idContains" (keyup)="handleSearchFilter()"/>
  </ng-template>
  <ng-template pTemplate="selectedItem">
    <ng-container *ngIf="selectedPallet">
      <foodbank-pallet [pallet]="selectedPallet"></foodbank-pallet>
    </ng-container>
  </ng-template>
  <ng-template let-pallet pTemplate="item">
    <foodbank-pallet [pallet]="pallet"></foodbank-pallet>
  </ng-template>
</p-dropdown>
