<p-table #dt [value]="(((stockFeadPlanningPage$ | async)?.content) || [])" responsiveLayout="scroll" [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="((stockFeadPlanningPage$ | async)?.totalElements || 0)"
         [globalFilterFields]="['depot']" [paginator]="true" [rowsPerPageOptions]="[50,100,200]"
         [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [rowHover]="true" [resizableColumns]="true" columnResizeMode="expand" [autoLayout]="true" dataKey="id" [lazy]="true" (onLazyLoad)="paginate($event)"
         rowGroupMode="rowspan" groupRowsBy="articleId" [loading]="!(stockFeadPlanningPage$ | async)">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Planning summary</h2>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pResizableColumn>
        <span i18n>Article</span>
      </th>
      <th>
        <span i18n>Net weight</span>
      </th>
      <th>
        <span i18n>Units per parcel</span>
      </th>
      <th>
        <span i18n>Parcels per pallet</span>
      </th>
      <th>
        <span i18n>Warehouse</span>
      </th>
      <th>
        <span i18n>Stock</span>
      </th>
      <th>
        <span i18n>Unit stock</span>
      </th>
      <th *ngFor="let period of (periods$ | async)">
        <span i18n>{{ period }}</span>
        <span class="quick-action" *ngIf="!isSelectedPeriod(period)" (click)="selectPeriod(period)"><i class="pi pi-filter"></i></span>
        <span class="quick-action" *ngIf="isSelectedPeriod(period)" (click)="selectPeriod()"><i class="pi pi-filter-slash"></i></span>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-stockFeadPlanning let-rowgroup="rowgroup" let-rowspan="rowspan">
    <ng-container *ngIf="identity(stockFeadPlanning) as stockFeadPlanning">
      <tr>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="white-space-normal">
        <foodbank-article ngDefaultControl [ngModel]="(stockFeadPlanning.article$ | async) || undefined" [showId]="true"></foodbank-article>
      </td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
        <span *ngIf="(stockFeadPlanning.article$ | async) as article">{{ article.feadUnitGrossWeight }}</span>
      </td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
        <span *ngIf="(stockFeadPlanning.article$ | async) as article">{{ article.feadUnitsPerParcel }}</span>
      </td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
        <span *ngIf="(stockFeadPlanning.article$ | async) as article">{{ article.feadParcelsPerPallet }}</span>
      </td>
      <td>
        <ng-container *ngIf="(stockFeadPlanning.warehouse$ | async) as warehouse">
          <foodbank-warehouse ngDefaultControl [ngModel]="warehouse" [showId]="true"></foodbank-warehouse>
          <span class="quick-action" *ngIf="!isSelectedWarehouse(warehouse)" (click)="selectWarehouse(warehouse)"><i class="pi pi-filter"></i></span>
          <span class="quick-action" *ngIf="isSelectedWarehouse(warehouse)" (click)="selectWarehouse(undefined)"><i class="pi pi-filter-slash"></i></span>
        </ng-container>
      </td>
      <td class="decimal_quantity">
        {{ stockFeadPlanning.stockQuantity | number: '1.2-2' }}
      </td>
      <td class="decimal_quantity">
        {{ stockFeadPlanning.unitStockQuantity | number: '1.2-2' }}
      </td>
      <td *ngFor="let period of (periods$ | async)">
        {{ stockFeadPlanning.periodPlanningQuantityMap.get(period) }}
      </td>
    </tr>
    </ng-container>
  </ng-template>
</p-table>
