import {Component, Input} from '@angular/core';
import {PreparationItem} from '@model/preparation-item';

@Component({
  selector: 'foodbank-fead-preparation-report-table',
  templateUrl: './fead-preparation-report-table.component.html',
  styleUrls: ['./fead-preparation-report-table.component.scss']
})
export class FeadPreparationReportTableComponent {

  @Input()
  preparationItems!: PreparationItem[];
  @Input()
  showBarCode = true;
  @Input()
  showOrganization = true;
  @Input()
  showArticle = true;

}
