import {inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {combineLatest, map, Observable, of, shareReplay, switchMap} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {WarehouseService} from './warehouse.service';
import {ArticleService} from './article.service';
import {StockPrevisionDto, StockPrevisionSearchDto} from '@typedefs/stock-rest';
import {StockPrevision} from '@model/stock-prevision';
import {StockPrevisionSearch} from '@model/search/stock-prevision-search';
import {CompanyService} from '@services/company.service';
import {PalletType} from "@model/pallet-type";
import {Returnable} from "@model/returnable";
import {Supplier} from "@model/supplier";
import {SupplierService} from "@services/supplier.service";
import {ReturnableService} from "@services/returnable.service";
import {PalletTypeService} from "@services/pallet-type.service";
import {copyCommonFields} from "@model/mapping-utils";
import {LocationService} from "@services/location.service";
import {Location} from "@model/location";

type StockPrevisionPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class StockPrevisionService {

  private httpClient = inject(HttpClient);
  private warehouseService = inject(WarehouseService);
  private articleService = inject(ArticleService);
  private locationService = inject(LocationService);
  private companyService = inject(CompanyService);
  private supplierService = inject(SupplierService);
  private returnableService = inject(ReturnableService);
  private palletTypeService = inject(PalletTypeService);

  getStockPrevision$(id: number): Observable<StockPrevision> {
    return this.httpClient.get<StockPrevisionDto>(`${environment.apiUrl}/stock-previsions/${id}`)
      .pipe(
        map(stockPrevisionDto => this.mapToStockPrevision(stockPrevisionDto)),
        shareReplay()
      );
  }

  findStockPrevisions(stockPrevisionSearch: StockPrevisionSearch, pagination: StockPrevisionPagination): Observable<Page<StockPrevision>> {
    const stockPrevisionSearchDto = this.mapToStockPrevisionSearchDto(stockPrevisionSearch);
    return this.httpClient.post<Page<StockPrevisionDto>>(`${environment.apiUrl}/stock-previsions/search`, stockPrevisionSearchDto, {params: pagination})
      .pipe(map(stockPrevisionDtoPage => {
        const stockPrevisions: StockPrevision[] = stockPrevisionDtoPage.content.map(stockPrevision => this.mapToStockPrevision(stockPrevision));


        return {
          ...stockPrevisionDtoPage,
          content: stockPrevisions
        }


      }));
  }

  countStockPrevisions(stockPrevisionSearch: StockPrevisionSearch): Observable<number> {
    const stockPrevisionSearchDto = this.mapToStockPrevisionSearchDto(stockPrevisionSearch);
    return this.httpClient.post<number>(`${environment.apiUrl}/stock-previsions/search/count`, stockPrevisionSearchDto);
  }

  validateStockPrevisions(stockPrevisions: StockPrevision[]): Observable<void> {
    const stockPrevisionIds = stockPrevisions.map(stockPrevision => stockPrevision.id);
    return this.httpClient.post<void>(`${environment.apiUrl}/stock-previsions/receptions`, stockPrevisionIds, {}).pipe(
      shareReplay(),
    )
  }

  save(stockPrevision: StockPrevision): Observable<StockPrevision> {
    return this.mapToStockPrevisionDto$(stockPrevision).pipe(
      switchMap(stockPrevisionDto => this.httpClient.put<StockPrevisionDto>(`${environment.apiUrl}/stock-previsions/${stockPrevisionDto.id}`, stockPrevisionDto)),
      map(stockPrevisionDto => this.mapToStockPrevision(stockPrevisionDto)),
      shareReplay(),
    );
  }

  private loadArticle$(articleId?: string) {
    return articleId ? this.articleService.getArticle$(articleId) : of(undefined);
  }

  private loadWarehouse$(warehouseId?: number) {
    return warehouseId ? this.warehouseService.getWarehouse$(warehouseId) : of(undefined);
  }

  private loadCompany$(companyId?: string) {
    return companyId ? this.companyService.getCompany$(companyId) : of(undefined);
  }

  private loadPalletType(palletTypeId?: string): Observable<PalletType | undefined> {
    return palletTypeId ? this.palletTypeService.getPalletType$(palletTypeId) : of(undefined);
  }

  private loadReturnable(returnableId?: string): Observable<Returnable | undefined> {
    return returnableId ? this.returnableService.getReturnable$(returnableId) : of(undefined);
  }

  private loadSupplier(supplierId?: string): Observable<Supplier | undefined> {
    return supplierId ? this.supplierService.getSupplier$(supplierId) : of(undefined);
  }

  mapToStockPrevision(stockPrevisionDto: StockPrevisionDto): StockPrevision {
    return {
      ...stockPrevisionDto,
      article$: this.articleService.getArticle$(stockPrevisionDto.articleId),
      location$: this.loadLocation$(stockPrevisionDto),
      warehouse$: this.warehouseService.getWarehouse$(stockPrevisionDto.warehouseId),
      company$: this.companyService.getCompany$(stockPrevisionDto.companyId),
      sourceCompany$: this.loadCompany$(stockPrevisionDto.sourceCompanyId),
      sourceWarehouse$: this.loadWarehouse$(stockPrevisionDto.sourceWarehouseId),
      palletType$: this.loadPalletType(stockPrevisionDto.palletTypeId),
      returnable$: this.loadReturnable(stockPrevisionDto.returnableId),
      supplier$: this.loadSupplier(stockPrevisionDto.supplierId),
    };
  }

  mapToStockPrevisionSearchDto(stockPrevisionSearch: StockPrevisionSearch): StockPrevisionSearchDto {
    const commonFields: StockPrevisionSearchDto | StockPrevisionSearch = copyCommonFields(stockPrevisionSearch, ['warehouseSearch', 'sourceWarehouseSearch', 'articleSearch']);
    const stockPrevisionSearchDto: StockPrevisionSearchDto = {
      ...commonFields,
      warehouseSearchDto: stockPrevisionSearch.warehouseSearch && this.warehouseService.mapToWarehouseSearchDto(stockPrevisionSearch.warehouseSearch),
      sourceWarehouseSearchDto: stockPrevisionSearch.sourceWarehouseSearch && this.warehouseService.mapToWarehouseSearchDto(stockPrevisionSearch.sourceWarehouseSearch),
      articleSearchDto: stockPrevisionSearch.articleSearch && this.articleService.mapToArticleSearchDto(stockPrevisionSearch.articleSearch),
    };
    return stockPrevisionSearchDto
  }

  mapToStockPrevisionDto$(stockPrevision: StockPrevision): Observable<StockPrevisionDto> {
    const commonFields: StockPrevisionDto | StockPrevision = copyCommonFields(stockPrevision, ['article$', 'supplier$', 'company$', 'warehouse$', 'palletType$', 'sourceWarehouse$', 'sourceCompany$', 'returnable$']);
    return combineLatest(([
      stockPrevision.article$,
      stockPrevision.company$,
      stockPrevision.warehouse$,
      stockPrevision.sourceCompany$,
      stockPrevision.sourceWarehouse$,
      stockPrevision.palletType$,
      stockPrevision.returnable$,
      stockPrevision.supplier$
    ])).pipe(
      map(([article, company, warehouse, sourceCompany, sourceWarehouse, palletType, returnable, supplier]) => {
          return ({
            ...commonFields,
            articleId: article?.id,
            companyId: company?.id,
            warehouseId: warehouse.id,
            sourceCompanyId: sourceCompany?.id,
            sourceWarehouseId: sourceWarehouse?.id,
            palletTypeId: palletType?.id,
            returnableId: returnable?.id,
            supplierId: supplier?.id,
          });
        }
      )
    );
  }

  private loadLocation$(stockPrevisionDto: StockPrevisionDto): Observable<Location | undefined> {
    if (!stockPrevisionDto.location) {
      return of(undefined);
    }
    return this.locationService.getLocation$(stockPrevisionDto.warehouseId, stockPrevisionDto.location);
  }
}
