import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CompanyStatus} from '@typedefs/stock-rest';

@Component({
  selector: 'foodbank-fead-planning-organization-type-selection',
  templateUrl: './fead-planning-organization-type-selection.component.html',
  styleUrls: ['./fead-planning-organization-type-selection.component.scss']
})
export class FeadPlanningOrganizationTypeSelectionComponent {

  associationTypes = [
    { label: 'All associations', value: 'NON_PROFIT' },
    { label: 'All except CPAS', value: 'UNREGISTERED' },
    { label: 'Only CPAS', value: 'CPAS' },
  ]

  @Input() associationType: CompanyStatus | undefined;
  @Output() associationTypeChange = new EventEmitter<CompanyStatus>();
  constructor() { }

  select(associationType: CompanyStatus) {
    this.associationType = associationType;
    this.associationTypeChange.emit(associationType);
  }
}
