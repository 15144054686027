<div class="field grid">
  <label for="campaign" class="col-1" i18n>Campaign</label>
  <div class="col-3">
    <foodbank-fead-campaign-selection
      id="campaign"
      (onCampaignSelected)="selectCampaign($event)">
    </foodbank-fead-campaign-selection>
  </div>
</div>
<p>To create a preparation, we need you to select:</p>
<ng-container *ngIf="(selectedWarehouse && selectedPeriod ? 'p-disabled' : 'show') as selectionReadyClass">
  <p>
    <p-checkbox label="a warehouse" [readonly]="true" [disabled]="!!selectedWarehouse" [ngModel]="!!selectedWarehouse"
                [binary]="true" [className]="selectionReadyClass"></p-checkbox>
    <foodbank-warehouse
      ngDefaultControl
      *ngIf="selectedWarehouse"
      [ngModel]="selectedWarehouse"
    >
    </foodbank-warehouse>
  </p>
  <p>
    <p-checkbox label="a period" [readonly]="true" [disabled]="!!selectedWarehouse" [ngModel]="!!selectedPeriod"
                [binary]="true" [className]="selectionReadyClass"></p-checkbox>
    <span *ngIf="selectedPeriod">
    {{ selectedPeriod }}
  </span>
  </p>
  <p [className]="selectionReadyClass">Please select filters or click on <span class="quick-action"><i
    class="pi pi-filter"></i></span> in table below.</p>
  <p *ngIf="(existingPreparation$ | async) as existingPreparation">
    You cannot create a preparation, as one was found for this selection:
    <foodbank-fead-preparation
      ngDefaultControl
      [preparation]="existingPreparation"
      [showDetails]="false"
    >
    </foodbank-fead-preparation>.
  </p>
  <div class="field">
    <p-button
      icon="pi pi-plus"
      label="Create preparation"
      (click)="createPreparationForSelection()"
      [rounded]="true"
      [disabled]="!(canCreatePreparation$ | async)"
    ></p-button>
  </div>
</ng-container>
<foodbank-fead-planning-summary-table
  ngDefaultControl
  [feadPlanningSearch]="feadPlanningSearch"
  [relevantOnly]="relevantOnly"
  (periodsSelected)="selectPeriods($event)"
  (warehousesSelected)="selectWarehouses($event)"
>
</foodbank-fead-planning-summary-table>
