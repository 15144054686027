import {AfterViewInit, Component, computed, effect, ElementRef, HostListener, input, OnChanges, output, signal, Signal, ViewChild, WritableSignal} from '@angular/core';
import {BehaviorSubject, debounceTime, filter, forkJoin, map, Observable, pipe, switchMap, take} from 'rxjs';
import {MessageService} from 'primeng/api';
import {DialogService} from "primeng/dynamicdialog";
import {ComponentChanges} from "@util/component-change";
import {Warehouse} from "@model/warehouse";
import {PaginationService} from "@services/pagination.service";
import {UserService} from "@services/user.service";
import {StockSearch} from "@model/search/stock-search";
import {PreparationSelectionItem, toPreparationSelectionItem} from "@model/preparation-selection-item";
import {Page} from "@typedefs/page";
import {PackageDistributionOption} from "@typedefs/stock-rest";
import {Preparation} from "@model/preparation";
import {PreparationStockSelection} from "@model/preparation-stock-selection";
import {Pagination} from "@services/pagination";
import {Article} from "@model/article";
import {ArticleStorageCondition} from "@model/article-storage-condition";
import {TableLazyLoadEvent} from "primeng/table";
import {PreparationSelectionService} from "@services/preparation-selection.service";
import {Stock} from "@model/stock";

import {ArticleSearch} from "@model/search/article-search";
import {ArticleStorageConditionSearch} from "@model/search/article-storage-condition-search";
import {createSetAndRemoveDragImage} from "@util/drag-and-drop";
import {ArticleCategory} from "@model/article-category";
import {PreparationSelectionSearch} from "@model/search/preparation-selection-search";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {activeWarehouseSearchByCompany, WarehouseSearch} from "@model/search/warehouse-search";
import {ArticleCategorySearch} from "@model/search/article-category-search";

type PackageDistributionOptionLabels = { label: string, value: PackageDistributionOption; companyDefault?: boolean };

@Component({
  selector: 'foodbank-setup-preparation-table',
  templateUrl: './setup-preparation-table.component.html',
  styleUrl: './setup-preparation-table.component.scss',
  providers: [DialogService]
})
export class SetupPreparationTableComponent implements OnChanges, AfterViewInit {

  protected readonly DEFAULT_ROWS_PER_PAGE = 15;

  warehouse = input<Warehouse>();
  stockFilter = input<StockSearch>();
  preparation = input<Preparation>();
  tableSizeStyleClass = input(localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '');
  onStockSelect = output<PreparationStockSelection[]>();
  onStockDragStart = output<PreparationStockSelection[]>();
  onStockDragEnd = output();

  emptyMessage?: string;

  pagination: WritableSignal<Pagination>;
  preparationSelectionPage: Signal<Page<PreparationStockSelection> | undefined>;
  refreshTrigger = signal(0);

  selectedPreparationSelections: PreparationStockSelection[] = [];
  selectionEnabled = signal(true);

  tableHeight!: string;
  @ViewChild('tableContainer')
  tableElementRef!: ElementRef;

  savePreparationSelectionSink$: BehaviorSubject<PreparationStockSelection | undefined>;

  selectedArticles = signal<Article[]>([])
  selectedArticleCategories = signal<ArticleCategory[]>([]);
  selectedArticleStorageConditions = signal<ArticleStorageCondition[]>([]);
  selectedBestBeforeDateRange = signal<Date | Date[] | undefined>(undefined);
  selectedPackageDistribution = signal<PackageDistributionOption | undefined>('UNIT');

  // searches for filters
  filterWarehouseSearch: Signal<WarehouseSearch>;
  filterArticleCategorySearch = signal<ArticleCategorySearch | undefined>(undefined)
  filterArticleStorageConditionSearch = signal<ArticleStorageConditionSearch | undefined>(undefined)

  readonly filterArticleSearch: Signal<ArticleSearch>;
  readonly preparationSelectionSearch: Signal<PreparationSelectionSearch>;

  constructor(private paginationService: PaginationService,
              private userService: UserService,
              private preparationSelectionService: PreparationSelectionService,
              private messageService: MessageService) {
    this.pagination = this.paginationService.getDefaultPaginationSignal(15);
    const currentUserCompany = this.userService.getCurrentUserCompany();

    this.loadPackageDistributionOptions();
    this.preparationSelectionPage = this.loadPage$();

    this.savePreparationSelectionSink$ = new BehaviorSubject<PreparationStockSelection | undefined>(undefined);
    this.savePreparationSelectionSink$.pipe(
      filter(value => !!value),
      debounceTime(500),
      switchMap(value => this.preparationSelectionService.savePreparationSelection$(value!)),
    ).subscribe({
      next: value => console.log('savePreparationSelection', value),
      error: error => this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error saving preparation selection'})
    });

    this.preparationSelectionSearch = computed(() => {
      const stockSearch: StockSearch = {
        ...this.stockFilter(),
        minBestBeforeDate: this.getMinBestBeforeDate(this.selectedBestBeforeDateRange()),
        maxBestBeforeDate: this.getMaxBestBeforeDate(this.selectedBestBeforeDateRange()),
        articleSearch: {
          articles: this.selectedArticles(),
          articleCategorySearch: {
            articleCategories: this.selectedArticleCategories()
          },
          articleStorageConditionSearch: {
            articleStorageConditions: this.selectedArticleStorageConditions()
          }
        }
      };

      return {
        stockSearch,
        preparation: this.preparation()
      }
    });

    effect(() => { //TODO: replace this effect by 2 sources and create a third computed signal
      if (this.preparation()) {
        this.selectedPackageDistribution.set(this.preparation()!.packageDistributionOption);
      }
    }, {
      allowSignalWrites: true
    });

    this.filterWarehouseSearch = computed(() => {
      return activeWarehouseSearchByCompany(currentUserCompany()!);
    });

    this.filterArticleSearch = computed(() => ({
      stockSearch: {
        ...this.stockFilter(),
        warehouseSearch: this.filterWarehouseSearch()
      },
      articleCategorySearch: this.filterArticleCategorySearch(),
      articleStorageConditionSearch: this.filterArticleStorageConditionSearch(),
    }));
  }

  private loadPage$(): Signal<Page<PreparationStockSelection> | undefined> {
    const proposePalletWeights$ = computed(() => {
      return this.preparation()?.proposePalletWeights ?? true;
    });

    const triggers = computed(() => ({
      pagination: this.pagination(),
      preparationSelectionSearch: this.preparationSelectionSearch(),
      refreshTrigger: this.refreshTrigger()
    }));

    const rawPreparationSelectionPage$ = pipeSignal(triggers,
      pipe(switchMap(({pagination, preparationSelectionSearch}) =>
        this.preparationSelectionService.find(preparationSelectionSearch, pagination)))
    );

    const rawStuff = computed(() => ({
      page: rawPreparationSelectionPage$(),
      proposePalletWeights: proposePalletWeights$()
    }));

    return pipeSignal(rawStuff,
      pipe(
        filter(({page}) => !!page),
        switchMap(({page, proposePalletWeights}) => this.mapToStockPreparationItemPage(page!, proposePalletWeights)),
        map(page => ({
            ...page,
            content: page.content.map(preparationSelection => this.mapToPreparationSelectionItem(preparationSelection))
          }) as Page<PreparationStockSelection>
        ),
      ));
  }

  private loadPackageDistributionOptions() {
    const currentUserCompany = this.userService.getCurrentUserCompany();
    const packageDistributionOptions: Signal<PackageDistributionOptionLabels[]> = computed(() => {
      return currentUserCompany()!.allocationByPackage
        ? [{value: 'UNIT', label: 'unit'}, {value: 'PARCEL', label: 'parcel (company default)', companyDefault: true}]
        : [{value: 'UNIT', label: 'unit (company default)', companyDefault: true}, {value: 'PARCEL', label: 'parcel'}];
    });

    const options = packageDistributionOptions();
    const selectedPackageDistribution: PackageDistributionOption = options.find(option => option.companyDefault)?.value || 'UNIT';
    this.selectedPackageDistribution.set(selectedPackageDistribution);

    // this.selectedPackageDistribution.set(selectedPackageDistribution);
    // toObservable(packageDistributionOptions)
    //   .pipe(take(1))
    //   .subscribe(options => {
    //
    //   });
  }

  private mapToStockPreparationItemPage(preparationSelectionPage: Page<PreparationStockSelection>, proposePalletWeights = true): Observable<Page<PreparationStockSelection>> {
    const content$: Observable<PreparationStockSelection[]> = forkJoin(preparationSelectionPage.content.map(preparationSelection => toPreparationSelectionItem(preparationSelection, proposePalletWeights)));
    return content$.pipe(
      map(content => ({...preparationSelectionPage, content: content}))
    );
  }

  ngOnChanges(changes: ComponentChanges<SetupPreparationTableComponent>): void {

  }

  ngAfterViewInit() {
    this.updateTableHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateTableHeight();
  }

  updateTableHeight() {
    const tableOffsetTop = this.tableElementRef?.nativeElement?.getBoundingClientRect()?.top || 289;
    const windowHeight = window.innerHeight;
    // FIXME: can't actually use tableOffsetTop: it gets weird number after tab gets clicked, ok after page resize
    // const availableHeight = windowHeight - tableOffsetTop - 250; // Adjust for footer
    const availableHeight = windowHeight - 289 - 250; // Adjust for footer
    this.tableHeight = `${availableHeight}px`;
  }

  identity(stock: any): PreparationSelectionItem {
    return stock;
  }

  stockIdentity(stock: any): Stock {
    return stock;
  }

  createPreparationForSelection() {
    if (!this.warehouse) {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Warehouse is not set'});
      return;
    }

    // combineLatest([this.distributionList$, this.warehouse.company$, this.preparationSelectionPage$]).pipe(
    //   take(1),
    //   mergeMap(([distributionList, company, stockPage]) => {
    //     const valid = stockPage.content.every(item => this.validTakenValues(item));
    //
    //     if (!valid) {
    //       return of({severity: 'error', summary: 'Error', detail: 'Some of the values are invalid. Please correct them before creating the preparation'});
    //     }
    //
    //     // return EMPTY;
    //     // this.preparationService.saveNonFeadPreparation$({
    //     //     id: 0,
    //     //     warehouse$: of(distributionConfig!.warehouse),
    //     //     date: this.preparationDate as Date,
    //     //     closed: false,
    //     //     company$: of(distributionConfig?.company!),
    //     //     location: distributionConfig!.zone!.location,
    //     //     zone$: of(distributionConfig!.zone!),
    //     //     fead: false
    //     //   }
    //       // ,
    //       // distributions: distributionList!,
    //       // preparationItems: stockPage.content,
    //       // packageDistributionOption: this.selectedPackageDistribution,
    //       // restrictions: this.selectedParameters
    //     // }
    //     ).pipe(
    //       catchError(() => of({severity: 'error', summary: 'Error', detail: 'Error creating preparation'})),
    //       map(() => ({severity: 'success', summary: 'Success', detail: 'Preparation created'}))
    //     );
    //   })
    // )
    //   .subscribe(message => {
    //     this.messageService.add(message);
    //
    //     if (message.severity === 'success') {
    //       this.router.navigate(['/preparation/list']);
    //     }
    //   });
  }

  loadPreparationSelectionList(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  onTakenQuantityChanged(preparationSelectionItem: PreparationSelectionItem) {
    preparationSelectionItem.stock$.pipe(
      take(1)
    ).subscribe(stock => {
      const dispatchWeight = preparationSelectionItem.dispatchWeight || 0;
      if (stock.unitWeight <= 0) {
        preparationSelectionItem.dispatchUnitCount = 0;
        preparationSelectionItem.dispatchParcelCount = 0;
      } else {
        preparationSelectionItem.dispatchUnitCount = dispatchWeight * 1000 / stock.unitWeight;
        preparationSelectionItem.dispatchParcelCount = preparationSelectionItem.dispatchUnitCount / stock.unitsPerParcel;
      }
      preparationSelectionItem.valid$ = this.validTakenValues(preparationSelectionItem);
      this.savePreparationSelection(preparationSelectionItem);
    })
  }

  onTakenUnitsChanged(preparationSelection: PreparationSelectionItem) {
    preparationSelection.stock$.pipe(take(1))
      .subscribe(stock => {
        const dispatchUnitCount = preparationSelection.dispatchUnitCount || 0;
        preparationSelection.dispatchParcelCount = dispatchUnitCount / stock.unitsPerParcel;
        preparationSelection.dispatchWeight = dispatchUnitCount * stock.unitWeight / 1000;
        preparationSelection.valid$ = this.validTakenValues(preparationSelection);
        this.savePreparationSelection(preparationSelection);
      })
  }

  onTakenParcelsChanged(stockPreparationItem: PreparationSelectionItem) {
    stockPreparationItem.stock$.pipe(take(1))
      .subscribe(stock => {
        const dispatchParcelCount = stockPreparationItem.dispatchParcelCount || 0;
        stockPreparationItem.dispatchUnitCount = dispatchParcelCount * stock.unitsPerParcel;
        stockPreparationItem.dispatchWeight = stockPreparationItem.dispatchUnitCount * stock.unitWeight / 1000;
        stockPreparationItem.valid$ = this.validTakenValues(stockPreparationItem);
        this.savePreparationSelection(stockPreparationItem);
      })
  }

  private savePreparationSelection(preparationSelection: PreparationStockSelection) {
    this.savePreparationSelectionSink$.next(preparationSelection);
  }

  resetValues() {
    this.refreshTrigger.update(i => i + 1);
  }

  isInteger(value?: number) {
    return Number.isInteger(value);
  }

  validTakenValues(preparationSelection: PreparationStockSelection) {
    return preparationSelection.stock$.pipe(
      take(1),
      map((stock) => {
        if (this.selectedPackageDistribution() === 'UNIT' && !this.isInteger(preparationSelection.dispatchUnitCount) && stock.unitWeight > 0) {
          return false;
        }

        if (this.selectedPackageDistribution() === 'PARCEL' && !this.isInteger(preparationSelection.dispatchParcelCount) && stock.unitWeight > 0) {
          return false;
        }

        const dispatchWeight = preparationSelection.dispatchWeight || 0;
        return dispatchWeight > 0 && dispatchWeight <= stock.quantity;
      })
    );
  }

  notifyStockDragStart(event: DragEvent, stock: PreparationStockSelection) {
    const value = this.selectedPreparationSelections.length === 0
      ? [stock]
      : this.selectedPreparationSelections;
    this.onStockDragStart.emit(value);

    const selectedPallets = this.selectedPreparationSelections.length === 0 ? 1 : this.selectedPreparationSelections.length
    const text = `Moving ${selectedPallets} pallet${selectedPallets > 1 ? 's' : ''}`;

    createSetAndRemoveDragImage(event, text);
  }

  isStockDraggable(stock: PreparationStockSelection): boolean {
    if (!this.selectionEnabled()) {
      return false;
    }

    return !this.selectedPreparationSelections || this.selectedPreparationSelections.length === 0 || this.selectedPreparationSelections.includes(stock);
  }

  notifyStockDragEnd(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.dropEffect !== 'none') {
      this.selectedPreparationSelections = [];
    }

    this.onStockDragEnd.emit();
  }

  notifySelectionChange(stock: PreparationStockSelection[]) {
    this.selectedPreparationSelections = stock;
    this.onStockSelect.emit(stock);
  }

  private mapToPreparationSelectionItem(preparationSelection: PreparationStockSelection): PreparationSelectionItem {
    return {
      ...preparationSelection,
      valid$: this.validTakenValues(preparationSelection)
    };
  }

  private getMinBestBeforeDate(selectedBestBeforeDateRange?: Date | Date[]): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange[0];
    }

    return undefined;
  }

  private getMaxBestBeforeDate(selectedBestBeforeDateRange?: Date | Date[]): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange.length === 2 ? selectedBestBeforeDateRange[1] : undefined;
    }

    return selectedBestBeforeDateRange;
  }
}
