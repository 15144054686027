import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {combineLatest, map, Observable, shareReplay, switchMap} from 'rxjs';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {MovementDto, StockAdjustmentDto} from '@typedefs/stock-rest';
import {Movement} from '@model/movement';
import {Article} from "@model/article";
import {Warehouse} from "@model/warehouse";
import {StockAdjustment} from "@model/stock-adjustment";
import {MovementService} from "@services/movement.service";

export type MovementPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class StockAdjustmentService {

  private httpClient = inject(HttpClient);
  private movementService = inject(MovementService);

  createStockAdjustmentForArticle(article: Article, warehouse: Warehouse, stockAdjustment: StockAdjustment): Observable<Movement> {
    const stockAdjustmentDto$: Observable<StockAdjustmentDto> = this.mapToStockAdjustmentDto$(stockAdjustment);
    return stockAdjustmentDto$.pipe(
      switchMap(stockAdjustmentDto => this.httpClient.post<MovementDto>(`${environment.apiUrl}/articles/${article.id}/stocks/warehouses/${warehouse.id}/movements`, stockAdjustmentDto)),
      map(createdMovementDto => this.movementService.mapToMovement(createdMovementDto)),
      shareReplay(),
    );
  }

  mapToStockAdjustmentDto$(stockAdjustment: StockAdjustment): Observable<StockAdjustmentDto> {
    return combineLatest([stockAdjustment.movementType$, stockAdjustment.organization$, stockAdjustment.targetWarehouse$, stockAdjustment.supplier$]).pipe(
      map(([movementType, organization, targetWarehouse, supplier]) => ({
          movementKind: movementType.movementKind,
          quantity: stockAdjustment.quantity,
          dateTime: stockAdjustment.dateTime,
          organizationId: organization?.id,
          supplierId: supplier?.id,
          targetWarehouseId: targetWarehouse?.id,
          comment: stockAdjustment.comment,
        })
      )
    );
  }

}
