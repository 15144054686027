import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, combineLatest, defaultIfEmpty, EMPTY, forkJoin, map, mergeMap, Observable, of, shareReplay, take } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import { Stock } from '@model/stock';
import { WarehouseService } from './warehouse.service';
import { StockSearch } from '@model/search/stock-search';
import { ArticleService } from './article.service';
import { StockDto, StockPalletSummaryDto, StockSearchDto } from '@typedefs/stock-rest';
import { CompanyService } from '@services/company.service';
import { SupplierService } from '@services/supplier.service';
import { StockPalletSummary } from '@model/stock-pallet-summary';
import { StockPalletService } from '@services/stock-pallet.service';
import { Zone } from '@model/zone';
import { ZoneService } from "@services/zone.service";
import { StockPallet } from "@model/stock-pallet";
import { PreparationStockSelection } from "@model/preparation-stock-selection";

type StockPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private httpClient: HttpClient,
              private warehouseService: WarehouseService,
              private companyService: CompanyService,
              private articleService: ArticleService,
              private supplierService: SupplierService,
              private palletService: StockPalletService,
              private zoneService: ZoneService
  ) {
  }

  getStock$(stockId: number): Observable<Stock> {
    return this.httpClient.get<StockDto>(`${environment.apiUrl}/stocks/${stockId}`)
      .pipe(
        map(stockDto => this.mapToStock(stockDto)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  getStockByPallet$(pallet: StockPallet): Observable<Stock | undefined> {
    return this.httpClient.get<StockDto>(`${environment.apiUrl}/pallets/${pallet.id}/stock`)
      .pipe(
        map(stockDto => this.mapToStock(stockDto)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(undefined);
          }
          // Rethrow the error for other error status codes
          throw error;
        }),
        shareReplay(),
      );
  }

  public findStock(stockSearch: StockSearch, pagination?: StockPagination): Observable<Page<Stock>> {
    const stockSearchDto = this.mapToStockSearchDto(stockSearch);
    return this.httpClient.post<Page<StockDto>>(`${environment.apiUrl}/stocks/search`, stockSearchDto, {params: pagination})
      .pipe(map(stockPage => {
        const stockList: Stock[] = stockPage.content.map(stock => this.mapToStock(stock));
        return {
          ...stockPage,
          content: stockList
        }
      }));
  }

  private loadArticle$(articleId: string) {
    return this.articleService.getArticle$(articleId);
  }

  private loadWarehouse$(warehouseId: number) {
    return this.warehouseService.getWarehouse$(warehouseId);
  }

  private loadCompany$(companyId: string) {
    return this.companyService.getCompany$(companyId);
  }

  private loadSupplier$(supplierId?: string) {
    return supplierId ? this.supplierService.getSupplier$(supplierId)  : of(undefined);
  }

  private loadPallet$(palletId: string) {
    return this.palletService.getStockPallet$(palletId);
  }

  private loadPalletSummary$(stockId: number): Observable<StockPalletSummary> {
    return this.httpClient.get<StockPalletSummaryDto>(`${environment.apiUrl}/stocks/${stockId}/pallet-summary`)
      .pipe(
        map(stockDto => this.mapToStockPalletSummary(stockDto)),
        shareReplay()
      );
  }

  private loadPreparationZone$(preparationZoneId?: string): Observable<Zone | undefined> {
    return preparationZoneId ? this.zoneService.find$(preparationZoneId)  : of(undefined);
  }

  private mapToStockPalletSummary(stockPalletSummaryDto: StockPalletSummaryDto): StockPalletSummary {
    return {
      ...stockPalletSummaryDto,
    };
  }

  private mapToStock(stockDto: StockDto): Stock {
    return {
      ...stockDto,
      article$: this.loadArticle$(stockDto.articleId),
      warehouse$: this.loadWarehouse$(stockDto.warehouseId),
      company$: this.loadCompany$(stockDto.companyId),
      supplier$: this.loadSupplier$(stockDto.supplierId),
      pallet$: this.loadPallet$(stockDto.palletId),
      palletSummary$: this.loadPalletSummary$(stockDto.id),
      preparationZone$: this.loadPreparationZone$(stockDto.preparationZoneId),
    };
  }

  private mapToStockDto(stock: Stock): Observable<StockDto> {
    const preparationZone$ = stock.preparationZone$.pipe(defaultIfEmpty(undefined));
    return combineLatest([stock.article$, stock.pallet$, stock.warehouse$, stock.company$, stock.supplier$, preparationZone$])
      .pipe(map(([article, pallet, warehouse, company, supplier, preparationZone]) =>
        ({
          id: stock.id,
          palletId: pallet?.id,
          receptionDate: stock.receptionDate,
          quantity: stock.quantity,
          expirationDate: stock.expirationDate,
          bestBeforeDate: stock.bestBeforeDate,
          unitWeight: stock.unitWeight,
          deliverBeforeDate: stock.deliverBeforeDate,
          fullDescription: stock.fullDescription,
          unitsPerParcel: stock.unitsPerParcel,
          unitGrossWeight: stock.unitGrossWeight,
          sourceRecept: stock.sourceRecept,
          location: stock.location,
          preparationZoneId: preparationZone?.location,
          temporary: stock.temporary,
          articleId: article?.id,
          warehouseId: warehouse?.id,
          companyId: company?.id,
          supplierId: supplier?.id,
          internalBatch: stock.internalBatch,
          supplierBatch: stock.supplierBatch,
        })));
  }

  mapToStockSearchDto(stockSearch: StockSearch): StockSearchDto {
    return {
      ...stockSearch,
      warehouseSearchDto: stockSearch.warehouseSearch && this.warehouseService.mapToWarehouseSearchDto(stockSearch.warehouseSearch),
      articleSearchDto: stockSearch.articleSearch && this.articleService.mapToArticleSearchDto(stockSearch.articleSearch),
      preparationZoneId: stockSearch.preparationZone?.location,
      excludedPreparationId: stockSearch.excludedPreparation?.id
    }
  }

  moveStockOutOfPreparationZone(stockToMove: PreparationStockSelection[]) {
    const movedStocks = stockToMove
      .map(stock =>
        stock.stock$.pipe(take(1), map(stock => {
          stock.preparationZone$ = EMPTY;
          stock.temporary = false;

          return stock;
        })));

    return forkJoin(movedStocks).pipe(
      mergeMap(stocks => this.saveAllStocks(stocks))
    );
  }

  private saveAllStocks(stocks: Stock[]): Observable<Stock[]> {
    const stockDtoUpdateRequests$ = stocks.map(stock => this.saveStock(stock));
    return forkJoin(stockDtoUpdateRequests$);
  }

  private saveStock(stock: Stock): Observable<Stock> {
    const stockDto$ = this.mapToStockDto(stock);
    return stockDto$.pipe(
      mergeMap(stockDto => this.saveStockDto(stockDto)),
      map(stockDto => this.mapToStock(stockDto))
    );
  }

  private saveStockDto(stockDto: StockDto): Observable<StockDto> {
    return this.httpClient.put<StockDto>(`${environment.apiUrl}/stocks/${stockDto.id}`, stockDto);
  }
}
