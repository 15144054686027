<p-dropdown *foodbankLanguage="'fr'"
            [(ngModel)]="ngModel"
            [options]="zones()"
            optionLabel="nameFr"
            [placeholder]="loading ? 'Loading...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="nameFr"
            [showClear]="showClear()"
            dataKey="location"
            appendTo="body"
            class="w-12"
>
  <ng-template pTemplate="selectedItem">
    <foodbank-preparation-zone [ngModel]="ngModel()" ngDefaultControl></foodbank-preparation-zone>
  </ng-template>
  <ng-template let-zone pTemplate="item">
    <foodbank-preparation-zone [ngModel]="zone" ngDefaultControl></foodbank-preparation-zone>
  </ng-template>
</p-dropdown>

<p-dropdown *foodbankLanguage="'nl'"
            [(ngModel)]="ngModel"
            [options]="zones()"
            optionLabel="nameNl"
            [placeholder]="loading ? 'Loading...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="nameNl"
            [showClear]="showClear()"
            dataKey="location"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="selectedItem">
    <foodbank-preparation-zone [ngModel]="ngModel()" ngDefaultControl></foodbank-preparation-zone>
  </ng-template>
  <ng-template let-zone pTemplate="item">
    <foodbank-preparation-zone [ngModel]="zone" ngDefaultControl></foodbank-preparation-zone>
  </ng-template>
</p-dropdown>
