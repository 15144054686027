import {Component, input, model} from '@angular/core';
import {Warehouse} from "@model/warehouse";

@Component({
  selector: 'foodbank-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent {

  ngModel = model<Warehouse>();
  showId = input<boolean>(false);
  showDetails = input<boolean>(true);

}
