import {Component, computed, inject, model, Signal, WritableSignal} from '@angular/core';
import {mergeMap, pipe, tap} from "rxjs";
import {MovementType} from "@model/movement-type";
import {MovementTypeSearch} from "@model/search/movement-type-search";
import {Page} from "@typedefs/page";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {DEFAULT_SELECT_COMPONENT_ROWS_PER_PAGE, PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {MovementTypeService} from "@services/movement-type.service";

@Component({
  selector: 'foodbank-movement-type-single-selection',
  templateUrl: './movement-type-single-selection.component.html',
  styleUrls: ['./movement-type-single-selection.component.scss']
})
export class MovementTypeSingleSelectionComponent {

  ngModel = model<MovementType>();
  movementTypeSearch = model<MovementTypeSearch>({});

  movementTypePage: Signal<Page<MovementType> | undefined>;

  pagination: WritableSignal<Pagination>;
  loading = false;

  #movementTypeService = inject(MovementTypeService);
  #paginationService = inject(PaginationService);

  constructor() {
    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_SELECT_COMPONENT_ROWS_PER_PAGE);

    const movementTypeSearchParams: Signal<([MovementTypeSearch, Pagination])> = computed(() => [this.movementTypeSearch(), this.pagination()]);

    this.movementTypePage = pipeSignal(movementTypeSearchParams, pipe(
      tap(() => this.loading = true),
      mergeMap(([movementTypeSearch, pagination]) => this.#movementTypeService.findMovementTypes$(movementTypeSearch, pagination)),
      tap(() => this.loading = false),
    ));
  }

}
