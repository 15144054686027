<ng-container *ngIf="(articles$ | async) as articles">
  <ng-container *ngIf="preparationItemSearch.preparation as preparation">
    <div class="article" *ngFor="let article of articles">
      <div class="page-header">
        <span class="top-row">
          <img src="./assets/layout/images/foodbank.png" alt="Foodbank">
          <span class="title">
            <h2>Banque Alimentaire de Belgique</h2>
            <h2>{{ (preparation.company$ |async)?.compName }}</h2>
          </span>
        </span>
        <span class="preparation">
          <span class="top-row">
            <span class="preparation-details">
              <p>Bon de préparation n° {{ preparation.id }}</p>
              <p>Zone de préparation {{ preparation.location }}</p>
            </span>
            <span class="preparation-warehouse">
              <foodbank-warehouse ngDefaultControl [ngModel]="(preparation.warehouse$ | async) || undefined" [showId]="true"></foodbank-warehouse>
            </span>
          </span>
        </span>
      </div>
      <div class="article-details">
        <span class="top-row">
          <span class="preparation-article">
            <foodbank-article
              [ngModel]="article"
              ngDefaultControl
              [showId]="true"
            >
            </foodbank-article>
          </span>
        </span>
      </div>
      <div class="preparation-items" *ngIf="(articlePreparationItemsMap$ | async) as articlePreparationItemsMap">
        <ng-container *ngIf="articlePreparationItemsMap.get(article) as preparationItems">
          <foodbank-fead-preparation-report-table
            ngDefaultControl
            [showBarCode]="showBarCode"
            [preparationItems]="preparationItems"
            [showArticle]="false"
          >
          </foodbank-fead-preparation-report-table>
        </ng-container>
      </div>
      <div class="signature">
        Ce document a été traité par ...................
      </div>
    </div>
  </ng-container>
</ng-container>
