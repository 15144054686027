import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioButtonClickEvent} from 'primeng/radiobutton';

export type DateSelectionMode = 'single' | 'multiple' | 'range' | undefined;

@Component({
  selector: 'foodbank-date-selection-mode',
  templateUrl: './date-selection-mode.component.html'
})
export class DateSelectionModeComponent {

  @Input() dateSelectionMode: DateSelectionMode;

  @Output() dateSelectionModeChange: EventEmitter<DateSelectionMode> = new EventEmitter<DateSelectionMode>()

  constructor() { }

  onDateSelectionModeChanged($event: RadioButtonClickEvent) {
    this.dateSelectionMode = $event.value;
    this.dateSelectionModeChange.emit($event.value);
  }
}
