import { Injectable, signal, WritableSignal } from '@angular/core';
import { SortMeta } from 'primeng/api';
import { Pagination } from '@services/pagination';
import { TableLazyLoadEvent } from 'primeng/table';
import { Page } from '@typedefs/page';
import { map, Observable, shareReplay } from 'rxjs';

export const DEFAULT_ROWS_PER_PAGE = 50;
export const DEFAULT_SELECT_COMPONENT_ROWS_PER_PAGE = 200;

export function isSamePagination(pagination1: Pagination, pagination2: Pagination) {
  return pagination1.page === pagination2.page &&
    pagination1.size === pagination2.size &&
    pagination1.sort === pagination2.sort;
}

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  public getTablePagination(event: TableLazyLoadEvent, rowsPerPage = DEFAULT_ROWS_PER_PAGE): Pagination {
    return this.getPagination(event.first, event.rows, rowsPerPage, event.multiSortMeta);
  }

  getTablePaginationSignal(event: TableLazyLoadEvent, rowsPerPage = DEFAULT_ROWS_PER_PAGE): WritableSignal<Pagination> {
    const pagination = this.getPagination(event.first, event.rows, rowsPerPage, event.multiSortMeta)
    return signal(pagination);
  }

  getDefaultPagination(rowsPerPage = DEFAULT_ROWS_PER_PAGE) {
    return this.getPagination(0, rowsPerPage, rowsPerPage);
  }

  getDefaultPaginationSignal(rowsPerPage = DEFAULT_ROWS_PER_PAGE): WritableSignal<Pagination> {
    const defaultPagination = this.getDefaultPagination(rowsPerPage);
    return signal(defaultPagination);
  }

  public getPagination(first: number | undefined | null, rows: number | undefined | null, rowsPerPage = DEFAULT_ROWS_PER_PAGE, sortMeta?: SortMeta[] | null | undefined): Pagination {
    const size = rows || rowsPerPage;
    return {
      page: (first || 0) / size,
      size: size,
      sort: sortMeta ? sortMeta.map(sortMeta => this.getSortString(sortMeta)):[]
    };
  }

  public getPaginationSignal(first: number | undefined | null, rows: number | undefined | null, rowsPerPage = DEFAULT_ROWS_PER_PAGE, sortMeta?: SortMeta[] | null | undefined): WritableSignal<Pagination> {
    const pagination = this.getPagination(first, rows, rowsPerPage, sortMeta);
    return signal(pagination);
  }

  private getSortString(sortMeta: SortMeta) {
    const sortDirection = (sortMeta.order || 1) > 0 ? 'asc':'desc';
    return `${sortMeta.field},${sortDirection}`;
  }

  public mapPage<T, U>(page: Page<T>, mappingFunction: (source: T) => U): Page<U> {
    return {
      ...page,
      content: page.content.map(mappingFunction)
    }
  }

  public mapResults<T, U>(page$: Observable<Page<T>>, mappingFunction: (source: T) => U): Observable<Page<U>> {
    return page$.pipe(
      map(page => this.mapPage(page, mappingFunction)),
      shareReplay(1)
    );
  }

  getReportPagination(): Pagination {
    return {
      page: 0,
      size: 10000
    }
  }

  getSingleItemPatination() {
    return {
      page: 0,
      size: 1
    }
  }
}
