import {Component, computed, EventEmitter, inject, input, model, Signal, WritableSignal} from '@angular/core';
import {mergeMap, pipe} from 'rxjs';
import {SupplierCategorySearch} from '@model/search/supplier-category-search';
import {SupplierCategory} from '@model/supplier-category';
import {SupplierCategoryService} from '@services/supplier-category.service';
import {Page} from "@typedefs/page";
import {Pagination} from "@services/pagination";
import {PaginationService} from "@services/pagination.service";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";

@Component({
  selector: 'foodbank-supplier-category-selection',
  templateUrl: './supplier-category-selection.component.html',
  styleUrls: ['./supplier-category-selection.component.scss']
})
export class SupplierCategorySelectionComponent {

  ngModel = model<SupplierCategory[]>([]);
  supplierCategorySearch = input<SupplierCategorySearch>({});

  pagination: WritableSignal<Pagination>;
  supplierCategoryPage: Signal<Page<SupplierCategory> | undefined>;

  private supplierCategoryService = inject(SupplierCategoryService);
  private paginationService = inject(PaginationService);

  constructor() {
    this.pagination = this.paginationService.getDefaultPaginationSignal(200);

    const supplierCategoryPageParams: Signal<[SupplierCategorySearch, Pagination]> = computed(() => [this.supplierCategorySearch(), this.pagination()]);

    this.supplierCategoryPage = pipeSignal(supplierCategoryPageParams, pipe(
        mergeMap(([supplierCategorySearch, pagination]) => this.supplierCategoryService.findSupplierCategories$(supplierCategorySearch, pagination)),
      )
    );
  }

}
