<p-dropdown [ngModel]="selectedLocation"
            (onChange)="select($event.value)"
            [options]="(locations$ | async) || []"
            optionLabel="id"
            [placeholder]="selectedLocation ? selectedLocation.name : (loading ? 'Loading...' : 'None')"
            [filter]="true"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="name"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="filter">
    <input type="text" pInputText pAutoFocus [autofocus]="true" placeholder="search" [(ngModel)]="searchFilter" (keyup)="handleSearchFilter()"/>
  </ng-template>
  <ng-template pTemplate="selectedItem">
    <ng-container *ngIf="selectedLocation">
      <foodbank-location [location]="selectedLocation"></foodbank-location>
    </ng-container>
  </ng-template>
  <ng-template let-location pTemplate="item">
    <foodbank-location [location]="location"></foodbank-location>
  </ng-template>
</p-dropdown>
