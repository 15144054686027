<p-table #dt
         [value]="articlePage()?.content || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="(articlePage()?.totalElements || 0)"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         dataKey="id"
         sortMode="multiple">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Articles</h2>

      <div class="flex mt-2 md:mt-0">
        <span class="report-block p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" placeholder="Search..." class="w-full sm:w-auto"/>
        </span>
      </div>
    </div>


    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">

      <p-panel class="pl-0 md:col-4 panel-header-white" [style]="{ 'min-width': '550px' }" [class.bulk-movements-inactive]="!bulkMovementActive()">
        <ng-template pTemplate="header">
          <div class="ml-1 gap-1 borde" *ngIf="bulkMovementActive()">
            <i class="pi pi-truck mr-2"></i>
            <span class="font-bold">Bulk movements mode settings</span>
          </div>
        </ng-template>
        <div class="p-2 fadeinup animation-duration-100 animation-iteration-1 transition-linear"  *ngIf="bulkMovementActive()">
          <div class="flex field justify-content-left align-items-center">
            <label class="w-4 mb-0 mr-2" for="movementType">Movement *</label>
            <foodbank-movement-type-single-selection
              ngDefaultControl
              id="movementType"
              [(ngModel)]="bulkMovementType"
              [movementTypeSearch]="bulkMovementTypeSearch()"/>
          </div>

          <div class="flex field justify-content-left align-items-center">
            <label class="w-4 mb-0 mr-2" for="warehouse">Warehouse *</label>
            <foodbank-warehouse-selection
              id="warehouse"
              name="warehouse"
              [(ngModel)]="bulkMovementWarehouse"
              [warehouseSearch]="bulkMovementWarehouseSearch()"
              ngDefaultControl />
          </div>

          <div class="flex field justify-content-left align-items-center" *ngIf="bulkMovementNeedsSupplier()">
            <label class="w-4 mb-0 mr-2" for="supplier">Supplier *</label>
            <foodbank-supplier-selection
              id="supplier"
              name="supplier"
              [(ngModel)]="bulkMovementSupplier"
              [supplierSearch]="bulkMovementSupplierSearch()"
              ngDefaultControl />
          </div>

          <div class="flex field justify-content-left align-items-center" *ngIf="bulkMovementNeedsTargetWarehouse()">
            <label class="w-4 mb-0 mr-2" for="warehouse">Target warehouse *</label>
            <foodbank-warehouse-selection
              id="targetWarehouse"
              name="targetWarehouse"
              [(ngModel)]="bulkMovementTargetWarehouse"
              [warehouseSearch]="bulkMovementTargetWarehouseSearch()"
              ngDefaultControl />
          </div>

          <div class="flex field justify-content-left align-items-center">
            <label class="w-4 mb-0 mr-2" for="date">Date</label>
            <foodbank-date-picker
              id="date"
              name="date"
              [(ngModel)]="bulkMovementDate"
              [showSelectionOptions]="false"
              ngDefaultControl />
          </div>
        </div>
        <div class="flex flex-column md:flex-row md:justify-content-left md:align-items-center">
          <p-toggleButton [(ngModel)]="bulkMovementActive" offIcon="pi pi-truck" onIcon="pi pi-sign-out" offLabel="Enter bulk movements mode" onLabel="Exit bulk movements mode" />
        </div>
      </p-panel>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="ID">
        <span i18n>Id</span>
        <p-sortIcon field="ID"></p-sortIcon>
      </th>
      <th pSortableColumn="NAME">
        <span i18n>Name</span>
        <p-sortIcon field="NAME"></p-sortIcon>
      </th>
      <th *ngIf="bulkMovementActive()">Quantity</th>
      <th pSortableColumn="ARTICLE_CATEGORY_ID">
        <span i18n>Category</span>
        <p-sortIcon field="ARTICLE_CATEGORY_ID"></p-sortIcon>
      </th>
      <th pSortableColumn="ARTICLE_STORAGE_CONDITION_ID">
        <span i18n>Storage</span>
        <p-sortIcon field="ARTICLE_STORAGE_CONDITION_ID"></p-sortIcon>
      </th>
      <th>
        <span i18n>Bulk food</span>
      </th>
      <th>
        <span i18n>Campaign</span>
      </th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-multi-selection
              ngDefaultControl
              [selectedArticles]="articlesFilter()"
              (onArticleSelected)="articlesFilter.set($event)"
              [articleSearch]=filterArticlesSearch()
            ></foodbank-article-multi-selection>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="bulkMovementActive()"></th>
      <th>
        <p-columnFilter field="categories" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-category-selection
              [selectedArticleCategories]="this.articleCategoriesFilter()"
              (onArticleCategorySelected)="this.articleCategoriesFilter.set($event)"
              [articleCategorySearch]="filterArticleCategorySearch()"
            ></foodbank-article-category-selection>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="storageConditions" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-storage-condition-selection
              [selectedArticleStorageConditions]="this.articleStorageConditionsFilter()"
              (onArticleStorageConditionSelected)="this.articleStorageConditionsFilter.set($event)"
              [articleStorageConditionSearch]="filterArticleStorageConditionSearch()"
            ></foodbank-article-storage-condition-selection>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-checkbox [binary]="true" [(ngModel)]="bulkFood" ></p-checkbox>
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-article>
    <ng-container *ngIf="typing(article) as article">
      <tr>
        <td>
          <a (click)="openEditDialog(article)">{{ article.id }}</a>
        </td>
        <td class="white-space-normal">
          <foodbank-article ngDefaultControl [ngModel]="article" [showId]="false"></foodbank-article>
        </td>
        <td *ngIf="bulkMovementActive()">
          <input pInputText type="number" [(ngModel)]="article.quantity" placeholder="Quantity" class="w-full sm:w-auto" [disabled]="!bulkMovementValid()" (blur)="saveArticleQuantity(article)"/>
        </td>
        <td>
          <foodbank-article-category
            [articleCategory]="article.articleCategory$ | async"></foodbank-article-category>
        </td>
        <td>
          <foodbank-article-storage-condition
            [articleStorageCondition]="article.articleStorageCondition$ | async"></foodbank-article-storage-condition>
        </td>
        <td>
          <p-checkbox [binary]="true" [ngModel]="article.bulkFood" [disabled]="true"></p-checkbox>
        </td>
        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(article)"></button>
          <p-button *ngIf="article.fead" (click)="openFeadCampaignDialog(article)" label="{{article.feadYear}}"
                    icon="pi pi-calendar" styleClass="p-button p-button-rounded p-button-text"></p-button>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
