import {Signal} from "@angular/core";
import {OperatorFunction} from "rxjs";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";

export function pipeSignal<T, U>(signal: Signal<T>, pipe: OperatorFunction<T, U>): Signal<U | undefined> {
  const signalAsObservable = toObservable(signal);
  const signalPipe = signalAsObservable.pipe(pipe);

  return toSignal(signalPipe);
}

export function pipeSignalWithDefault<T, U>(signal: Signal<T>, pipe: OperatorFunction<T, U>, initialValue: U): Signal<U> {
  const signalAsObservable = toObservable(signal);
  const signalPipe = signalAsObservable.pipe(pipe);

  return toSignal(signalPipe, {initialValue});
}
