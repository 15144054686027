<p-dropdown *foodbankLanguage="'nl'" [ngModel]="selectedArticleCategory"
            (onChange)="select($event.value)"
            [options]="(articleCategories$ | async) || []"
            optionLabel="id"
            [placeholder]="loading ? 'Loading...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="nameNl"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="id"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="selectedItem">
    <ng-container *ngIf="selectedArticleCategory">
      {{ selectedArticleCategory.nameNl }}
    </ng-container>
  </ng-template>
  <ng-template let-movementType pTemplate="item">
    {{ movementType.nameNl }}
  </ng-template>
</p-dropdown>

<p-dropdown *foodbankLanguage="'fr'" [ngModel]="selectedArticleCategory"
            (onChange)="select($event.value)"
            [options]="(articleCategories$ | async) || []"
            optionLabel="id"
            [placeholder]="loading ? 'Loading...' : 'None'"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="nameFr"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="movementKind"
            appendTo="body"
            class="w-12">
  <ng-template pTemplate="selectedItem">
    <ng-container *ngIf="selectedArticleCategory">
      {{ selectedArticleCategory.nameFr }}
    </ng-container>
  </ng-template>
  <ng-template let-movementType pTemplate="item">
    {{ movementType.nameNl }}
  </ng-template>
</p-dropdown>
