<table>
  <tr>
    <th *ngIf="showBarCode" class="barcode-column"></th>
    <th *ngIf="showArticle" class="centered-column">Article</th>
    <th *ngIf="showOrganization" class="centered-column">Organization</th>
    <th class="quantity-column">
      <span i18n>Unit weight</span>
    </th>
    <th class="quantity-column">
      <span i18n>Pallet</span>
    </th>
    <th class="quantity-column">
      <span i18n>Weight</span>
    </th>
    <th class="quantity-column">
      <span i18n>Location</span>
    </th>
    <th class="quantity-column">
      <span i18n># units</span>
    </th>
    <th class="quantity-column">
      <span i18n># parcels</span>
    </th>
    <th class="quantity-column">
      <span i18n>Weight per parcel</span>
    </th>
  </tr>
  <tr *ngFor="let preparationItem of preparationItems">
    <td style="padding: 0;" *ngIf="showBarCode">
      <ng-container *ngIf="(preparationItem.organization$ | async) as organization">
        <ngx-barcode6
          [bc-format]="'CODE128'"
          [bc-value]="organization.id + '-' + preparationItem.palletId"
          [bc-display-value]="true"
          [bc-height]="30"
          [bc-width]="1"
          [bc-font-size]="10"
          [bc-margin-bottom]="0"
          [bc-text-margin]="0"
          bc-element-type="img"
        ></ngx-barcode6>
      </ng-container>
    </td>
    <td *ngIf="showArticle" class="white-space-normal">
      <foodbank-article [showId]="true" [ngModel]="(preparationItem.article$ | async) || undefined" ngDefaultControl></foodbank-article>
      {{ preparationItem.fullDescription }}
    </td>
    <td *ngIf="showOrganization" class="white-space-normal">
      <foodbank-organization [organization]="(preparationItem.organization$ | async) || undefined" ngDefaultControl></foodbank-organization>
    </td>
    <td class="aligned-quantity" style="width: 8%;">
      {{ preparationItem.unitWeight }}
    </td>
    <td class="aligned-quantity" style="width: 8%;">
      {{ preparationItem.palletId }}
    </td>
    <td class="aligned-quantity" style="width: 8%;">
      <foodbank-number [value]="preparationItem.quantity"></foodbank-number>
    </td>
    <td class="aligned-quantity" style="width: 8%;">
      <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
        {{ stock.location }}
      </ng-container>
    </td>
    <td class="aligned-quantity" style="width: 8%;">
      <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
        {{ preparationItem.numberOfUnits % stock.unitsPerParcel | number: '1.0-0' }}
      </ng-container>
    </td>
    <td class="aligned-quantity" style="width: 8%;">
      <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
        <ng-container *ngIf="stock.unitsPerParcel >= 1">
          {{ preparationItem.numberOfUnits / stock.unitsPerParcel | number: '1.0-0' }}
        </ng-container>
      </ng-container>
    </td>
    <td class="aligned-quantity" style="width: 8%;">
    </td>
  </tr>
</table>
