<span *ngIf="ngModel() as movementType">
  <i class="pi pi-arrow-up-right" *ngIf="movementType.movementKind === 'DONATION_TO_VOLUNTEERS'"></i>
  <i class="pi pi-arrow-right-arrow-left" *ngIf="movementType.movementKind === 'LOCATION_CHANGE'"></i>
  <i class="pi pi-sign-out" *ngIf="movementType.movementKind === 'KITCHEN_OUT'"></i>
  <i class="pi pi-trash" *ngIf="movementType.movementKind === 'DESTRUCTION'"></i>
  <i class="pi pi-directions" *ngIf="movementType.movementKind === 'DISTRIBUTION'"></i>
  <i class="pi pi-truck" *ngIf="movementType.movementKind === 'EXPEDITION'"></i>
  <i class="pi pi-truck" *ngIf="movementType.movementKind === 'FROZEN_EXPEDITION'"></i>
  <i class="pi pi-minus" *ngIf="movementType.movementKind === 'INVENTORY_MINUS'"></i>
  <i class="pi pi-plus" *ngIf="movementType.movementKind === 'INVENTORY_PLUS'"></i>
  <i class="pi pi-question" *ngIf="movementType.movementKind === 'ARTICLE_CHANGE'"></i>
  <i class="pi pi-list" *ngIf="movementType.movementKind === 'RECEIPT'"></i>
  <i class="pi pi-list" *ngIf="movementType.movementKind === 'RECEIPT_CORRECTION'"></i>
  <i class="pi pi-cloud-download" *ngIf="movementType.movementKind === 'INTER_WAREHOUSE_RECEIPT'"></i>
  <i class="pi pi-cloud-upload" *ngIf="movementType.movementKind === 'INTER_WAREHOUSE_TRANSFER'"></i>
  <i class="pi pi-power-off" *ngIf="movementType.movementKind === 'OFF_NETWORK_TRANSFER'"></i>
  <i class="pi pi-chevron-circle-right" *ngIf="movementType.movementKind === 'INTERASSOCIATION_TRANSFER'"></i>
  <span class="name" *foodbankLanguage="'nl'">
    {{ movementType.languageNameMap.DUTCH }}
  </span>
  <span class="name" *foodbankLanguage="'fr'">
    {{ movementType.languageNameMap.FRENCH }}
  </span>
</span>
