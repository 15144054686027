import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {SupplierCategorySearch} from '@model/search/supplier-category-search';
import {Page} from '@typedefs/page';
import {SupplierCategoryDto, SupplierCategorySearchDto} from '@typedefs/stock-rest';
import {SupplierCategory} from '@model/supplier-category';

@Injectable({
  providedIn: 'root'
})
export class SupplierCategoryService {

  constructor(private httpClient: HttpClient) {
  }

  public findSupplierCategory$(id: string): Observable<SupplierCategory> {
    return this.httpClient.get<SupplierCategoryDto>(`${environment.apiUrl}/suppliers/categories/${id}`)
      .pipe(map(supplierCategoryDto => this.loadSupplierCategory(supplierCategoryDto)));
  }

  public findSupplierCategories$(supplierCategorySearch: SupplierCategorySearch, pagination?: Pagination): Observable<Page<SupplierCategory>> {
    const supplierCategorySearchDto = this.convertToSearchDto(supplierCategorySearch);
    return this.httpClient.post<Page<SupplierCategoryDto>>(`${environment.apiUrl}/suppliers/categories/search`, supplierCategorySearchDto, {params: pagination})
      .pipe(map(supplierCategoryPage => this.loadSupplierCategoryPage(supplierCategoryPage)));
  }

  public convertToSearchDto(supplierCategorySearch: SupplierCategorySearch): SupplierCategorySearchDto {
    return {
      ...supplierCategorySearch,
      supplierCategoryIds: supplierCategorySearch.supplierCategoryList?.map(supplierCategory => supplierCategory.id)
    };
  }

  private loadSupplierCategoryPage(supplierCategoryPage: Page<SupplierCategoryDto>): Page<SupplierCategory> {
    return {
      ...supplierCategoryPage,
      content: supplierCategoryPage.content.map(supplierCategory => this.loadSupplierCategory(supplierCategory))
    };
  }

  public loadSupplierCategory(supplierCategoryDto: SupplierCategoryDto): SupplierCategory {
    return {
      ...supplierCategoryDto
    };
  }

}
