<ng-container *ngIf="ngModel() as warehouse">
  <i class="fa-solid fa-warehouse"></i>
  <span class="ml-1" (click)="showDetails() && warehousePanel.show($event)">{{warehouse.name}} <span *ngIf="showId">({{warehouse.id}})</span></span>
  <p-overlayPanel #warehousePanel [dismissable]="true">
    <div class="field grid" *ngIf="showDetails">
      <i class="pi pi-map-marker"></i>
      <div class="col address-block">
        <a href="http://maps.google.com/maps?q={{warehouse.address}} {{warehouse.address2}} {{warehouse.zip}} {{warehouse.city}}" target="_blank">
        <p>{{warehouse.address}}</p>
        <p *ngIf="warehouse.address2">{{warehouse.address2}}</p>
        <p>{{warehouse.zip}} {{warehouse.city}}</p>
        </a>
      </div>
    </div>

    @if (warehouse.phoneNo && showDetails()) {
      <div class="field grid">
        <i class="pi pi-phone"></i>
        <div class="col address-block">
          <p><a href="tel:{{warehouse.phoneNo}}">{{warehouse.phoneNo}}</a></p>
        </div>
      </div>
    }
  </p-overlayPanel>
</ng-container>
