import {Component, input, model, Signal} from '@angular/core';
import {filter, map, mergeMap, pipe, tap} from "rxjs";
import {Zone} from "@model/zone";
import {ZoneSearch} from "@model/search/zone-search";
import {ZoneService} from "@services/zone.service";
import {enrichListWithSelection} from "@util/foodbanks-list-utils";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";

@Component({
  selector: 'foodbank-preparation-zone-single-selection',
  templateUrl: './preparation-zone-single-selection.component.html',
  styleUrls: ['./preparation-zone-single-selection.component.scss']
})
export class PreparationZoneSingleSelectionComponent {

  ngModel = model<Zone>();
  zoneSearch = input<ZoneSearch>();
  showClear = input(true);
  zones: Signal<Zone[] | undefined>;

  loading = true;

  constructor(private zoneService: ZoneService) {
    this.zones = pipeSignal(this.zoneSearch, pipe(
        tap(() => this.loading = true),
        filter(zoneSearch => !!zoneSearch),
        mergeMap(zoneSearch => this.zoneService.findZones$(zoneSearch)),
        map(zones => enrichListWithSelection(zones, this.ngModel())),
        tap(() => this.loading = false),
      )
    );
  }

}
