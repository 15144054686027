import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {environment} from '@environments/environment';
import {Preparation} from '@model/preparation';
import {PreparationDto} from '@typedefs/stock-rest';
import {UserService} from '@services/user.service';
import {Organization} from "@model/organization";
import {PreparationService} from "@services/preparation.service";
import {Warehouse} from "@model/warehouse";

@Injectable({
  providedIn: 'root'
})
export class ExpeditionService {

  constructor(private httpClient: HttpClient,
              private preparationService: PreparationService,
              private userService: UserService) {
  }

  public autofillExpedition$(preparation: Preparation): Observable<Preparation> {
    return this.httpClient.post<PreparationDto>(`${environment.apiUrl}/preparations/${preparation.id}/expeditions/autofill`, {})
      .pipe(
        map(preparationDto => this.preparationService.mapToPreparation(preparationDto)),
        take(1),
        shareReplay()
      );
  }

  public dispatchExpedition$(preparation: Preparation, comment: string): Observable<Preparation> {
    return this.httpClient.post<PreparationDto>(`${environment.apiUrl}/preparations/${preparation.id}/expeditions`, { comment })
      .pipe(
        map(preparationDto => this.preparationService.mapToPreparation(preparationDto)),
        take(1),
        shareReplay()
      );
  }
}
