import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {environment} from '@environments/environment';
import {FeadPlanningByArticleDto} from '@typedefs/stock-rest';
import {ArticleService} from '@services/article.service';
import {Warehouse} from '@model/warehouse';
import {Organization} from '@model/organization';
import {FeadPlanningByArticle} from '@model/fead-planning-by-article';

@Injectable({
  providedIn: 'root'
})
export class OrganizationFeadPlanningService {

  constructor(private httpClient: HttpClient,
              private articleService: ArticleService) {
  }

  public getOrganizationFeadPlanning$(warehouse: Warehouse, year: number, organization: Organization): Observable<FeadPlanningByArticle[]> {
    return this.httpClient.get<FeadPlanningByArticleDto[]>(`${environment.apiUrl}/fead/plannings/warehouses/${warehouse.id}/${year}/organizations/${organization.id}`)
      .pipe(
        map(feadOrganizationPlanningEntryDtos => feadOrganizationPlanningEntryDtos.map(
            feadOrganizationPlanningEntryDto => this.mapToFeadOrganizationPlanningEntry(feadOrganizationPlanningEntryDto)
          )
        ),
        take(1),
        shareReplay(1)
      )
  }

  public deleteOrganizationFeadPlanning$(warehouse: Warehouse, year: number, organization: Organization){
    return this.httpClient.delete<void>(`${environment.apiUrl}/fead/plannings/warehouses/${warehouse.id}/${year}/organizations/${organization.id}`);
  }

  public saveOrganizationFeadPlanning$(warehouse: Warehouse, year: number, organization: Organization, planningData: FeadPlanningByArticleDto[]){
    return this.httpClient.put<void>(`${environment.apiUrl}/fead/plannings/warehouses/${warehouse.id}/${year}/organizations/${organization.id}`, planningData);
  }

  private mapToFeadOrganizationPlanningEntry(feadOrganizationPlanningEntryDto: FeadPlanningByArticleDto): FeadPlanningByArticle {
    return {
      ...feadOrganizationPlanningEntryDto,
      article$: this.articleService.getArticle$(feadOrganizationPlanningEntryDto.articleId).pipe(take(1), shareReplay(1))
    }
  }
}
