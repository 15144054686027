import {Component, computed, inject, input, model, signal, Signal, Type, WritableSignal} from '@angular/core';
import {pipe, switchMap} from "rxjs";
import {TableLazyLoadEvent} from "primeng/table";
import {Page} from '@typedefs/page';
import {MessageService} from 'primeng/api';

import {PreparationSearch} from '@model/search/preparation-search';
import {Preparation} from '@model/preparation';
import {PreparationPagination, PreparationService} from '@services/preparation.service';
import {UserService} from '@services/user.service';
import {Warehouse} from '@model/warehouse';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {FeadPreparationNewComponent} from "@components/fead/preparation/new/fead-preparation-new.component";
import {PreparationNewComponent} from "@components/preparation/new/preparation-new.component";
import {toSignal} from "@angular/core/rxjs-interop";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {Pagination} from "@services/pagination";

@Component({
  selector: 'foodbank-fead-preparation-list',
  templateUrl: './fead-preparation-list.component.html',
  styleUrls: ['./fead-preparation-list.component.scss']
})
export class FeadPreparationListComponent {

  // toggleable or from routing
  expedition = model(false);

  fead = input<boolean>();
  // selected filters
  warehouse = signal<Warehouse | undefined>(undefined);

  // main search
  preparationSearch: Signal<PreparationSearch>;

  pagination: WritableSignal<PreparationPagination>;
  preparationPage: Signal<Page<Preparation> | undefined>;

  // searches for table filters
  warehouseSearch: Signal<WarehouseSearch>;

  // selected table row
  selectedPreparation = signal<Preparation | undefined>(undefined);

  modalDialogRef?: DynamicDialogRef;

  private refreshTrigger = signal(0);
  refreshPreparationTrigger = signal(0); // child components won't see changes on same object instance if we don't cheat like this

  private readonly preparationService = inject(PreparationService);
  private readonly userService = inject(UserService);
  private readonly paginationService = inject(PaginationService);
  private readonly messageService = inject(MessageService);
  private readonly dialogService = inject(DialogService);

  constructor() {
    const defaultPagination = this.paginationService.getDefaultPagination();
    this.pagination = signal(defaultPagination);

    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    defaultWarehouse$.subscribe(this.warehouse.set)

    const company$ = this.userService.getCurrentUserCompany$();
    const company = toSignal(company$);

    this.preparationSearch = computed<PreparationSearch>(() => ({
        company: company(),
        type: this.fead() ? 'ESF_PLUS' : 'NON_ESF_PLUS',
        warehouse: this.warehouse(),
        closed: false,
      })
    );

    this.warehouseSearch = computed<WarehouseSearch>(() => ({
        company: company(),
        active: true,
        preparationSearch: {
          ...this.preparationSearch(),
          warehouse: undefined
        }
      })
    );

    const preparationParams: Signal<[PreparationSearch, Pagination, Number]> = computed(() => [this.preparationSearch(), this.pagination(), this.refreshTrigger()]);
    this.preparationPage = pipeSignal(preparationParams, pipe(
        switchMap(([preparationSearch, pagination]) => this.preparationService.find$(preparationSearch, pagination))
      )
    );
  }

  lazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  close(preparation: Preparation) {
    this.preparationService.setClosed$(preparation, true).subscribe({
      next: _ => {
        this.messageService.add({key: 'closed', severity: 'success', summary: 'Success', detail: `Preparation #${preparation.id} closed`, data: preparation});
        this.refresh();
      },
      error: _ => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to close preparation #${preparation.id}`})
      }
    });
  }

  reopenPreparation(preparation: Preparation) {
    this.preparationService.setClosed$(preparation, false).subscribe({
      next: _ => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: `Preparation #${preparation.id} reopened`, data: preparation});
        this.refresh();
      },
      error: _ => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to reopen preparation #${preparation.id}`})
      }
    });
  }

  openNewESFPreparationDialog() {
    return this.openGenericPreparationDialog('New ESF+ preparation', FeadPreparationNewComponent);
  }

  openNewPreparationDialog() {
    return this.openGenericPreparationDialog('New preparation', PreparationNewComponent);
  }

  openEditPreparationDialog(preparation: Preparation) {
    return this.openGenericPreparationDialog('Edit preparation ' + preparation.id, PreparationNewComponent, preparation);
  }

  openGenericPreparationDialog(title: string, componentType: Type<any>, preparation?: Preparation) {
    this.modalDialogRef = this.dialogService.open(componentType, {
      header: title,
      width: '90%',
      height: '90%',
      data: preparation
    });

    this.modalDialogRef.onClose.subscribe(preparation => this.onPreparationModalClosed(preparation));
  }

  private onPreparationModalClosed(preparation?: Preparation) {
    this.refresh();
    this.refreshPreparation();
  }

  private refresh() {
    this.refreshTrigger.update(i => i + 1);
  }

  private refreshPreparation() {
    this.refreshPreparationTrigger.update(i => i + 1);
  }
}
