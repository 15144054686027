import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Pagination} from './pagination';
import {ArticleStorageConditionSearch} from '@model/search/article-storage-condition-search';
import {map, Observable} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {ArticleStorageConditionDto, ArticleStorageConditionSearchDto} from '@typedefs/stock-rest';
import {ArticleStorageCondition} from '@model/article-storage-condition';

@Injectable({
  providedIn: 'root'
})
export class ArticleStorageConditionService {

  constructor(private httpClient: HttpClient) {
  }

  public findArticleStorageCondition$(id: string): Observable<ArticleStorageCondition> {
    return this.httpClient.get<ArticleStorageConditionDto>(`${environment.apiUrl}/articles/storage-conditions/${id}`)
      .pipe(map(articleStorageCondition => this.mapToArticleStorageCondition(articleStorageCondition)));
  }

  public findArticleStorageConditions$(pagination?: Pagination, articleStorageConditionSearch?: ArticleStorageConditionSearch): Observable<Page<ArticleStorageCondition>> {
    return this.httpClient.post<Page<ArticleStorageConditionDto>>(`${environment.apiUrl}/articles/storage-conditions/search`, articleStorageConditionSearch, {params: pagination})
      .pipe(map(articleStorageConditionPage => this.loadArticleStorageConditionPage(articleStorageConditionPage)));
  }

  private loadArticleStorageConditionPage(articleStorageConditionPage: Page<ArticleStorageConditionDto>): Page<ArticleStorageCondition> {
    return {
      ...articleStorageConditionPage,
      content: articleStorageConditionPage.content.map(articleStorageCondition => this.mapToArticleStorageCondition(articleStorageCondition))
    };
  }

  public mapToArticleStorageCondition(articleStorageConditionDto: ArticleStorageConditionDto): ArticleStorageCondition {
    return {
      ...articleStorageConditionDto
    };
  }

  mapToArticleStorageConditionSearchDto(articleStorageConditionSearch: ArticleStorageConditionSearch): ArticleStorageConditionSearchDto {
    return {
      articleStorageConditionIds: articleStorageConditionSearch.articleStorageConditions?.map(articleStorageCondition => articleStorageCondition.id)
    };
  }
}
