<p-table *ngIf="entryWithSummaryList" #dt [value]="entryWithSummaryList"
         responsiveLayout="scroll"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         [styleClass]="tableSizeStyleClass"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 60px">
        <h2 class="m-0">Article fead plannning</h2>
      </th>
      <th colSpan="6" style="text-align: right">
        <foodbank-table-size [(tableStyleClass)]="tableSizeStyleClass"/>
      </th>
      <th [colSpan]="periods.length + 1" style="text-align: center">
        Periods
      </th>
    </tr>
    <tr>
      <th class="white-space-normal" pResizableColumn>
        <span i18n>Organization</span>
      </th>
      <th class="aligned-quantity">
        <span i18n>Units per cart</span>
      </th>
      <th class="aligned-quantity">
        <span i18n>Unit Weight</span>
      </th>
      <th class="aligned-quantity">
        <span i18n>Planned</span>
      </th>
      <th class="aligned-quantity">
        <span i18n>Shipped</span>
      </th>
      <th class="aligned-quantity">
        <span i18n>Total</span>
      </th>
      <th class="aligned-quantity">
        <span i18n>Saldo</span>
      </th>
      <th class="aligned-quantity" *ngFor="let period of periods">
        <span i18n>{{ period }}</span>
      </th>
      <th style="width: 60px">
        <p-button
          [text]="true"
          icon="pi pi-plus"
          styleClass="p-button-primary"
          (click)="addPeriod()"
          *ngIf="!addPeriodVisible"
        >
        </p-button>
        <label #periodNameLabel for="newPeriodName"></label>
        <input
          pInputText
          class="period-edit"
          type="text"
          [(ngModel)]="newPeriodName"
          name="newPeriodName"
          id="newPeriodName"
          #periodName="ngModel"
          [placeholder]="year + '/ww'"
          periodFormat
          (keyup.enter)="periodName.valid && addSelectedPeriod(newPeriodName!)"
          (keyup.escape)="cancelAddPeriod()"
          [style.display]="addPeriodVisible ? 'block' : 'none'"
          autofocus
          tabindex="-1"
        />
        <p-button
          [text]="true"
          icon="pi pi-check"
          styleClass="p-button-primary"
          (click)="addSelectedPeriod(newPeriodName!)"
          [disabled]="!!periodName?.errors"
          type="submit"
          *ngIf="addPeriodVisible"
        ></p-button>
        <p-button
          [text]="true"
          icon="pi pi-times"
          styleClass="p-button-primary"
          (click)="cancelAddPeriod()"
          *ngIf="addPeriodVisible"
        ></p-button>
      </th>
    </tr>
    <tr *ngIf="generalSummary">
      <th colspan="3" style="text-align: right">
        TOTAL
      </th>
      <th class="aligned-quantity">
        {{ generalSummary.plannedQuantity }}
      </th>
      <th class="aligned-quantity">
        {{ generalSummary.shippedQuantity }}
      </th>
      <th class="aligned-quantity">
        {{ generalSummary.total }}
      </th>
      <th class="aligned-quantity">
        {{ generalSummary.saldo }}
      </th>
      <th class="aligned-quantity" *ngFor="let period of periods">
        {{ generalSummary.periodQuantityMap[period] }}
      </th>
      <th style="width: 60px">
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-entryWithSummary>
    <tr>
      <td class="white-space-normal">
        <foodbank-organization [organization]="(entryWithSummary.organization$ | async) || undefined" [showId]="true"></foodbank-organization>
      </td>
      <td class="aligned-quantity">
        <span *ngIf="($any(entryWithSummary.article$ | async)) as article">{{ article.feadUnitsPerParcel }}</span>
      </td>
      <td class="aligned-quantity">
        <span *ngIf="($any(entryWithSummary.article$ | async)) as article">{{ article.feadUnitWeight }}</span>
      </td>
      <td class="aligned-quantity">
        {{ entryWithSummary.plannedQuantity | number: '1.0-0' }}
      </td>
      <td class="aligned-quantity">
        {{ entryWithSummary.shippedQuantity | number: '1.0-0' }}
      </td>
      <td class="aligned-quantity">
        {{ entryWithSummary.summary.total | number: '1.0-0' }}
      </td>
      <td class="aligned-quantity">
        <ng-container *ngIf="entryWithSummary.summary.saldo as saldo; else zeroDisplay">
          <span [ngClass]="saldo > 0 ? 'todo' : 'bad'">
            {{ saldo | number: '1.0-0' }}
          </span>
        </ng-container>
        <ng-template #zeroDisplay>
          <span class="good">0</span>
        </ng-template>
      </td>
      <td class="aligned-quantity" [pEditableColumn]="entryWithSummary.periodQuantityMap[period]" *ngFor="let period of periods">
        <foodbank-fead-planning-quantity-cell-editor
          [organization]="(entryWithSummary.organization$ | async) || undefined"
          [period]="period"
          [quantity]="entryWithSummary.periodQuantityMap[period]"
          (quantityChange)="updateEntryPeriodQuantity(entryWithSummary, period, $event)">
        </foodbank-fead-planning-quantity-cell-editor>
      </td>
      <td>
      </td>
    </tr>
  </ng-template>
</p-table>
