import {Component, Input} from '@angular/core';
import {ArticleCategoryDto} from '@typedefs/stock-rest';
import {map} from 'rxjs';
import {LanguageService} from '@services/language.service';

@Component({
  selector: 'foodbank-article-category',
  templateUrl: './article-category.component.html',
  styleUrls: ['./article-category.component.scss']
})
export class ArticleCategoryComponent {

  @Input()
  articleCategory: ArticleCategoryDto | undefined | null;

  @Input()
  showId: boolean = true;

  showId$ = this.languageService.isCurrentLanguageDisplayable$(['nl', 'fr'])
    .pipe(map(isDisplayable => {
      if (!isDisplayable) {
        return true;
      } else {
        return this.showId;
      }
    }));

  constructor(private languageService: LanguageService) {
  }

}
