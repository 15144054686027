import {Component, Input} from '@angular/core';
import {Organization} from "@model/organization";

@Component({
  selector: 'foodbank-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent {

  @Input()
  organization?: Organization;
  @Input()
  showId = false;
  @Input()
  showCode = false;
  @Input()
  showAddress = false;

}
