<p-multiSelect [(ngModel)]="ngModel"
               [options]="supplierPage()?.content || []"
               placeholder="Any"
               filterMatchMode="contains"
               [showClear]="true"
               filterBy="id,nameFr,nameNl"
               appendTo="body">
  <ng-template let-selectedSuppliers pTemplate="selectedItems">
    <ng-container *ngFor="let selectedSupplier of selectedSuppliers">
      <foodbank-supplier ngDefaultControl [ngModel]="selectedSupplier"></foodbank-supplier>
      <br/>
    </ng-container>
    <ng-container *ngIf="!ngModel()?.length" i18n>Any</ng-container>
  </ng-template>
  <ng-template let-supplier pTemplate="item">
    <foodbank-supplier ngDefaultControl [ngModel]="supplier"></foodbank-supplier>
  </ng-template>
</p-multiSelect>
