import {combineLatest, EMPTY, map, Observable, of, shareReplay, switchMap, take} from "rxjs";
import {Reception} from "@model/reception";
import {ReceptionItemDto, ReceptionItemSummaryDto} from "@typedefs/stock-rest";
import {environment} from "@environments/environment";
import {inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {ReceptionItem} from "@model/reception-item";
import {Page} from "@typedefs/page";
import {ReceptionPagination, ReceptionService} from "@services/reception.service";
import {ReceptionItemSearch} from "@model/search/reception-item-search";
import {Article} from "@model/article";
import {Returnable} from "@model/returnable";
import {ArticleService} from "@services/article.service";
import {ReturnableService} from "@services/returnable.service";
import {ReceptionItemSummary} from "@model/reception-item-summary";

@Injectable({
  providedIn: 'root'
})
export class ReceptionItemService {

  private httpClient = inject(HttpClient);
  private receptionService = inject(ReceptionService);
  private articleService = inject(ArticleService);
  private returnableService = inject(ReturnableService);

  getReceptionItem$(receptionId: number, rank: number): Observable<ReceptionItem> {
    return this.httpClient.get<ReceptionItemDto>(`${environment.apiUrl}/receptions/${receptionId}/items/${rank}`)
      .pipe(
        map(receptionItem => this.mapToReceptionItem(receptionItem)),
        shareReplay()
      );
  }

  getReceptionItems$(receptionItemSearch: ReceptionItemSearch, pagination: ReceptionPagination): Observable<Page<ReceptionItem>> {
    const reception = receptionItemSearch.reception;
    return this.findReceptionItems(reception, pagination);
  }

  findReceptionItems(reception: Reception, pagination: ReceptionPagination) {
    return this.httpClient.get<Page<ReceptionItemDto>>(`${environment.apiUrl}/receptions/${reception.id}/items/search`, {params: pagination})
      .pipe(map(receptionPage => {
        return {
          ...receptionPage,
          content: receptionPage.content.map(receptionItem => this.mapToReceptionItem(receptionItem))
        }
      }));
  }

  deleteReceptionItem$(receptionItem: ReceptionItem): Observable<any> {
    return receptionItem.reception$.pipe(
      switchMap(reception => this.httpClient.delete(`${environment.apiUrl}/receptions/${reception.id}/items/${receptionItem.rank}`))
    )
  }

  getSummary$(receptionItem: ReceptionItemDto): Observable<ReceptionItemSummary> {
    const summary$ = receptionItem.receptionId && receptionItem.rank
      ? this.httpClient.get<ReceptionItemSummaryDto>(`${environment.apiUrl}/receptions/${receptionItem.receptionId}/items/${receptionItem.rank}/summary`)
      : EMPTY

    return summary$.pipe(
      map(receptionItemSummaryDto => this.mapToReceptionItemSummary(receptionItemSummaryDto)),
      shareReplay(),
    )
  }

  saveReceptionItem$(receptionItem: ReceptionItem): Observable<ReceptionItem> {
    return combineLatest([receptionItem.reception$.pipe(take(1))]).pipe(
      switchMap(([reception]) => this.saveReceptionItem(reception, receptionItem)),
      map(savedReceptionItem => this.mapToReceptionItem(savedReceptionItem))
    )
  }

  private saveReceptionItem(reception: Reception, receptionItem: ReceptionItem) {
    const receptionItemDto$ = this.mapToReceptionItemDto$(receptionItem);
    return receptionItemDto$.pipe(
      switchMap(receptionItemDto => this.httpClient.post<ReceptionItemDto>(`${environment.apiUrl}/receptions/${reception.id}/items`, receptionItemDto)),
      shareReplay(),
    );
  }

  mapToReceptionItem(receptionItemDto: ReceptionItemDto): ReceptionItem {
    return {
      receptionId: receptionItemDto.receptionId,
      rank: receptionItemDto.rank,
      supplierLot: receptionItemDto.supplierLot,
      internalLot: receptionItemDto.internalLot,
      palletQuantity: receptionItemDto.palletQuantity,
      quantity: receptionItemDto.quantity,
      unitWeight: receptionItemDto.unitWeight,
      description: receptionItemDto.description,
      unitsPerParcel: receptionItemDto.unitsPerParcel,
      unitGrossWeight: receptionItemDto.unitGrossWeight,
      expirationDate: receptionItemDto.expirationDate,
      bestBeforeDate: receptionItemDto.bestBeforeDate,

      reception$: this.loadReception$(receptionItemDto),
      article$: this.loadArticle$(receptionItemDto),
      returnable$: this.loadReturnable$(receptionItemDto),
      summary$: this.getSummary$(receptionItemDto)
    };
  }

  // private countParcels(pallets: ReceptionPalletCluster[]) {
  //   return pallets.reduce((acc, pallet) => acc + (pallet?.numberOfParcels ?? 0), 0);
  // }

  mapToReceptionItemDto$(receptionItem: ReceptionItem): Observable<ReceptionItemDto> {
    return combineLatest([receptionItem.reception$, receptionItem.article$, receptionItem.returnable$]).pipe(
      map(([reception, article, returnable]) => this.mapToReceptionItemDto(receptionItem, reception, article, returnable))
    );
  }

  private mapToReceptionItemDto(receptionItem: ReceptionItem, reception: Reception, article?: Article, returnable?: Returnable): ReceptionItemDto {
    return {
      rank: receptionItem.rank,
      receptionId: reception.id,
      articleId: article?.id,
      supplierLot: receptionItem.supplierLot,
      internalLot: receptionItem.internalLot,
      palletQuantity: receptionItem.palletQuantity,
      unitWeight: receptionItem.unitWeight,
      description: receptionItem.description,
      unitsPerParcel: receptionItem.unitsPerParcel,
      unitGrossWeight: receptionItem.unitGrossWeight,
      returnableId: returnable?.id,
      expirationDate: receptionItem.expirationDate,
      bestBeforeDate: receptionItem.bestBeforeDate,
      quantity: receptionItem.quantity
    };
  }

  private loadReception$(receptionItemDto: ReceptionItemDto) {
    const receptionId = receptionItemDto.receptionId;
    return this.receptionService.getReception$(receptionId);
  }

  private loadArticle$(reception: ReceptionItemDto): Observable<Article | undefined> {
    const articleId = reception.articleId;
    return articleId ? this.articleService.getArticle$(articleId) : of(undefined);
  }

  private loadReturnable$(receptionItemDto: ReceptionItemDto): Observable<Returnable | undefined> {
    const returnableId = receptionItemDto.returnableId;
    return returnableId ? this.returnableService.getReturnable$(returnableId) : of(undefined);
  }

  private mapToReceptionItemSummary(receptionItemSummaryDto: ReceptionItemSummaryDto): ReceptionItemSummary {
    return {
      showParcelsPerPallet: receptionItemSummaryDto.showParcelsPerPallet,
      netWeight: receptionItemSummaryDto.netWeight,
      grossWeight: receptionItemSummaryDto.grossWeight,
      palletCount: receptionItemSummaryDto.palletCount,
      parcelCount: receptionItemSummaryDto.parcelCount,
      unitCount: receptionItemSummaryDto.unitCount,
      receptionItemCount: receptionItemSummaryDto.receptionItemCount
    }
  }
}
