import {Component, Input} from '@angular/core';

@Component({
  selector: 'foodbank-user-type',
  templateUrl: './user-type.component.html',
  styleUrls: ['./user-type.component.scss']
})
export class UserTypeComponent {

  @Input() userType: string | undefined;

}
