import { Component, inject, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from '@services/authentication.service';
import { Observable } from "rxjs";
import { LayoutService } from "@components/layout/service/app.layout.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'foodbanks-stock-app';
  authenticationReady$!: Observable<boolean>;

  private primengConfig = inject(PrimeNGConfig);
  private titleService = inject(Title);
  private authenticationService = inject(AuthenticationService);
  private layoutService = inject(LayoutService);

  ngOnInit(): void {
    this.authenticationReady$ = this.authenticationService.authenticate$();
    this.primengConfig.ripple = true;

    this.titleService.setTitle($localize`${this.title}`);
    this.layoutService.loadPreferences();
  }

}
