import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {environment} from '@environments/environment';
import {CompanyStatus, FeadPlanningByOrganizationDto} from '@typedefs/stock-rest';
import {Warehouse} from '@model/warehouse';
import {FeadPlanningByOrganization} from '@model/fead-planning-by-organization';
import {Article} from '@model/article';
import {OrganizationService} from '@services/organization.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleFeadPlanningService {

  constructor(private httpClient: HttpClient,
              private organizationService: OrganizationService) {
  }

  public getArticleFeadPlanning$(warehouse: Warehouse, companyStatus: CompanyStatus, year: number, article: Article, round: number): Observable<FeadPlanningByOrganization[]> {
    return this.httpClient.get<FeadPlanningByOrganizationDto[]>(`${environment.apiUrl}/fead/plannings/${companyStatus}/warehouses/${warehouse.id}/${year}/articles/${article.id}/rounds/${round}`)
      .pipe(
        map(feadArticlePlanningEntryDtos => feadArticlePlanningEntryDtos.map(
            feadArticlePlanningEntryDto => this.mapToFeadArticlePlanningEntry(feadArticlePlanningEntryDto)
          )
        ),
        shareReplay(1)
      )
  }

  public deleteArticlePlanning$(warehouse: Warehouse, year: number, article: Article){
    return this.httpClient.delete<void>(`${environment.apiUrl}/fead/plannings/warehouses/${warehouse.id}/${year}/articles/${article.id}`);
  }

  public saveArticleFeadPlanning$(warehouse: Warehouse, year: number, article: Article, planningData: FeadPlanningByOrganizationDto[]){
    return this.httpClient.put<void>(`${environment.apiUrl}/fead/plannings/warehouses/${warehouse.id}/${year}/articles/${article.id}`, planningData);
  }

  private mapToFeadArticlePlanningEntry(feadArticlePlanningEntryDto: FeadPlanningByOrganizationDto): FeadPlanningByOrganization {
    return {
      ...feadArticlePlanningEntryDto,
      organization$: this.organizationService.getOrganization$(feadArticlePlanningEntryDto.organizationId).pipe(take(1), shareReplay(1))
    }
  }
}
