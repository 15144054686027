import {Component, Input} from '@angular/core';
import {Reception} from '@model/reception';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Observable, shareReplay} from 'rxjs';
import {Stock} from '@model/stock';
import {StockService} from '@services/stock.service';
import {Page} from '@typedefs/page';

@Component({
  selector: 'foodbank-reception-labels',
  templateUrl: './reception-labels.component.html',
  styleUrl: './reception-labels.component.scss'
})
export class ReceptionLabelsComponent {

  @Input()
  reception?: Reception

  stocks$: Observable<Page<Stock>>

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<Reception>,
              private stockService: StockService) {
    this.reception = this.config.data as Reception;

    this.stocks$ = this.stockService.findStock({ receptionId: this.reception?.id }, {
      page: 0,
      size: 1000
    }).pipe(shareReplay());
  }

  close() {
    this.ref.close({});
  }
}
