import {inject, Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {MovementKind, MovementTypeDto, MovementTypeSearchDto} from '@typedefs/stock-rest';
import {Page} from '@typedefs/page';
import {MovementType} from "@model/movement-type";
import {MovementTypeSearch} from "@model/search/movement-type-search";

@Injectable({
  providedIn: 'root'
})
export class MovementTypeService {

  private httpClient = inject(HttpClient);

  findMovementTypes$(movementTypeSearch: MovementTypeSearch, pagination: Pagination): Observable<Page<MovementType>> {
    const movementTypeSearchDto = this.mapToMovementTypeSearchDto(movementTypeSearch);
    return this.httpClient.post<Page<MovementTypeDto>>(`${environment.apiUrl}/movements/types/search`, movementTypeSearchDto, {
      params: pagination
    })
      .pipe(
        map(movementTypePage => {
          const content = movementTypePage.content.map(movementTypeDto => this.mapToMovementType(movementTypeDto));
          return ({
            ...movementTypePage,
            content: content
          });
        }),
        shareReplay()
      );
  }

  getMovementType$(movementKind: MovementKind) {
    return this.httpClient.get<MovementTypeDto>(`${environment.apiUrl}/movements/types/${movementKind}`)
      .pipe(
        map(movementTypeDto => this.mapToMovementType(movementTypeDto)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  private mapToMovementTypeSearchDto(movementTypeSearch: MovementTypeSearch): MovementTypeSearchDto {
    return {
      ...movementTypeSearch,
    }
  }

  public mapToMovementType(movementTypeDto: MovementTypeDto): MovementType {
    return {
      ...movementTypeDto,
    }
  }

}
