import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {combineLatest, map, Observable, shareReplay, switchMap} from 'rxjs';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {Preparation} from '@model/preparation';
import {PreparationDistributionLineDto, PreparationDistributionSummaryDto} from '@typedefs/stock-rest';
import {PreparationDistributionLine} from "@model/preparation-distribution-line";
import {PreparationService} from "@services/preparation.service";
import {OrganizationService} from "@services/organization.service";
import {Page} from "@typedefs/page";
import {PreparationDistributionSummary} from "@model/preparation-distribution-summary";

export type PreparationPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class PreparationDistributionService {

  constructor(private httpClient: HttpClient,
              private organizationService: OrganizationService,
              private preparationService: PreparationService) {
  }

  public getDistribution$(preparation: Preparation, pagination: Pagination): Observable<Page<PreparationDistributionLine>> {
    const preparationId = preparation.id;
    return this.httpClient.get<Page<PreparationDistributionLineDto>>(`${environment.apiUrl}/preparations/distributions`, {
      params: {
        ...pagination,
        preparationId
      }
    })
      .pipe(
        map(distributionDtoPage => ({
          ...distributionDtoPage,
          content: distributionDtoPage.content.map(distributionDto => this.mapToPreparationDistributionLine(distributionDto))
        })),
        shareReplay()
      );
  }

  public getDistributionSummary$(preparation: Preparation): Observable<PreparationDistributionSummary> {
    const preparationId = preparation.id;
    return this.httpClient.get<PreparationDistributionSummaryDto>(`${environment.apiUrl}/preparations/distributions/summary`, {params: {preparationId}})
      .pipe(shareReplay());
  }

  public createDistribution$(preparation: Preparation): Observable<PreparationDistributionLine[]> {
    const createPreparationDistributionDto = {
      preparationId: preparation.id,
    };

    return this.httpClient.post<PreparationDistributionLineDto[]>(`${environment.apiUrl}/preparations/distributions`, createPreparationDistributionDto).pipe(
      map(distributionDtos => distributionDtos.map(distributionDto => this.mapToPreparationDistributionLine(distributionDto))),
      shareReplay()
    );
  }

  save$(preparationDistributionLine: PreparationDistributionLine) {
    return this.mapToPreparationDistributionLineDto$(preparationDistributionLine).pipe(
      switchMap(preparationDistributionLineDto => this.httpClient.put<PreparationDistributionLineDto>(`${environment.apiUrl}/preparations/distributions`, preparationDistributionLineDto)),
      map(preparationDistributionLineDto => this.mapToPreparationDistributionLine(preparationDistributionLineDto)),
      shareReplay()
    );
  }

  private mapToPreparationDistributionLine(preparationDistributionLineDto: PreparationDistributionLineDto): PreparationDistributionLine {
    return {
      ...preparationDistributionLineDto,
      organization$: this.organizationService.getOrganization$(preparationDistributionLineDto.organizationId),
      preparation$: this.preparationService.getPreparation$(preparationDistributionLineDto.preparationId)
    }
  }

  private mapToPreparationDistributionLineDto$(preparationDistributionLine: PreparationDistributionLine): Observable<PreparationDistributionLine> {
    return combineLatest([
      preparationDistributionLine.organization$,
      preparationDistributionLine.preparation$
    ]).pipe(
      map(([organization, preparation]) => ({
        ...preparationDistributionLine,
        organizationId: organization.id,
        preparationId: preparation.id
      }))
    );
  }
}
