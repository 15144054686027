
export function createSetAndRemoveDragImage(event: DragEvent, text: string) {
  // Create a drag image element
  const dragImage = document.createElement("div");
  dragImage.style.width = "130px";
  dragImage.style.padding = "10px";
  dragImage.style.backgroundColor = "#f0f0f0";
  dragImage.style.border = "1px #000";
  dragImage.textContent = text;

  document.body.appendChild(dragImage);
  const dataTransfer = event.dataTransfer
  if (dataTransfer) {
    event.dataTransfer.setDragImage(dragImage, 0, 0);
  }

  // Remove the drag image from the DOM after the drag starts
  setTimeout(() => {
    document.body.removeChild(dragImage);
  }, 0);
}
