import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { combineLatest, EMPTY, map, mergeMap, Observable, shareReplay, switchMap, take } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import { WarehouseService } from './warehouse.service';
import { PreparationSearch } from '@model/search/preparation-search';
import { Company } from '@model/company';
import { Preparation } from '@model/preparation';
import { PreparationDistributionLineDto, PreparationDto, PreparationSearchDto, PreparationType } from '@typedefs/stock-rest';
import { CompanyService } from '@services/company.service';
import { Warehouse } from '@model/warehouse';
import { UserService } from '@services/user.service';
import { PreparationDistributionLine } from "@model/preparation-distribution-line";
import { OrganizationService } from "@services/organization.service";
import { Zone } from "@model/zone";
import { PreparationSelectionItem } from "@model/preparation-selection-item";
import { ZoneService } from "@services/zone.service";
import {toSignal} from "@angular/core/rxjs-interop";

export type PreparationPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class PreparationService {

  constructor(private httpClient: HttpClient,
              private warehouseService: WarehouseService,
              private companyService: CompanyService,
              private organizationService: OrganizationService,
              private zoneService: ZoneService,
              private userService: UserService) {
  }

  public getPreparation$(id: number): Observable<Preparation> {
    return this.httpClient.get<PreparationDto>(`${environment.apiUrl}/preparations/${id}`)
      .pipe(
        map(preparation => this.mapToPreparation(preparation)),
        take(1),
        shareReplay()
      );
  }

  public createPreparation$(year: number, period: string, warehouse: Warehouse): Observable<Preparation> {
    const currentUser$ = this.userService.getCurrentUser$();
    const warehouseId = warehouse.id;
    return currentUser$.pipe(
      mergeMap(currentUser => currentUser.company$),
      mergeMap(company => this.createPreparationForCompany$(company, warehouseId, year, period))
    );
  }

  private createPreparationForCompany$(company: Company, warehouseId: number, year: number, period: string) {
    return this.httpClient.post<PreparationDto>(`${environment.apiUrl}/fead-preparations/companies/${company.id}/warehouses/${warehouseId}/year/${year}?period=${period}`, {})
      .pipe(
        map(preparationDto => this.mapToPreparation(preparationDto)),
        take(1),
        shareReplay(),
      );
  }

  setClosed$(preparation: Preparation, closed: boolean) {
    const preparationDto$ = this.mapToPreparationDto$(preparation).pipe(map(preparationDto => ({
      ...preparationDto,
      closed: closed
    })));

    return preparationDto$.pipe(
      take(1),
      switchMap(preparationDto => this.httpClient.put<PreparationDto>(`${environment.apiUrl}/preparations/${preparation.id}`, preparationDto)),
      map(preparationDto => this.mapToPreparation(preparationDto)),
      shareReplay()
    );
  }

  public find$(preparationSearch: PreparationSearch, pagination?: PreparationPagination): Observable<Page<Preparation>> {
    const preparationSearchDto = this.mapToPreparationSearchDto(preparationSearch);
    return this.httpClient.post<Page<PreparationDto>>(`${environment.apiUrl}/preparations/search`, preparationSearchDto, {params: pagination})
      .pipe(map(preparationPage => {
          return {
            ...preparationPage,
            content: preparationPage.content.map(preparation => this.mapToPreparation(preparation))
          }
        }),
        shareReplay());
  }

  public createDistribution$(preparation: Preparation): Observable<PreparationDistributionLine[]> {
    const createPreparationDistributionDto = {
      preparationId: preparation.id,
    };

    return this.httpClient.post<PreparationDistributionLineDto[]>(`${environment.apiUrl}/preparations/distributions`, createPreparationDistributionDto).pipe(
      map(distributionDtos => distributionDtos.map(distributionDto => this.mapToPreparationDistributionLine(distributionDto))),
      shareReplay()
    );
  }

  public mapToPreparation(preparationDto: PreparationDto): Preparation {
    const warehouse$ = this.loadWarehouse$(preparationDto);
    return {
      ...preparationDto,
      date: new Date(preparationDto.date),
      warehouse$: warehouse$,
      company$: this.loadCompany$(preparationDto),
      zone$: this.loadZone$(preparationDto)
    };
  }

  private loadCompany$(preparation: PreparationDto): Observable<Company> {
    const companyId = preparation.companyId;
    return this.companyService.getCompany$(companyId);
  }

  private loadWarehouse$(preparation: PreparationDto) {
    const warehouseId = preparation.warehouseId;
    return this.warehouseService.getWarehouse$(warehouseId);
  }

  private loadZone$(preparationDto: PreparationDto): Observable<Zone> {
    const location = preparationDto.location;
    if (!preparationDto.fead && location) {
      return this.zoneService.find$(location);
    }

    return EMPTY;
  }

  public mapToPreparationSearchDto(preparationSearch: PreparationSearch): PreparationSearchDto {
    return {
      ...preparationSearch,
      companyId: preparationSearch.company?.id,
      warehouseId: preparationSearch.warehouse?.id,
    }
  }

  findPreparationInProgress$(type: PreparationType, warehouse: Warehouse, preparationZone: Zone): Observable<Preparation | undefined> {
    return this.find$({type, location: preparationZone.location, warehouse, closed: false}).pipe(
      map(page => page?.content?.length === 1 ? page.content[0] : undefined));
  }

  private mapToPreparationDistributionLine(preparationDistributionLineDto: PreparationDistributionLineDto): PreparationDistributionLine {
    return {
      ...preparationDistributionLineDto,
      organization$: this.organizationService.getOrganization$(preparationDistributionLineDto.organizationId),
      preparation$: this.getPreparation$(preparationDistributionLineDto.preparationId)
    }
  }

  saveNonFeadPreparation$(preparation: Preparation) {
    const preparationDto$: Observable<PreparationDto> = this.mapToPreparationDto$(preparation);
    return preparationDto$.pipe(
      switchMap(preparationDto => this.httpClient.post<PreparationDto>(`${environment.apiUrl}/preparations`, preparationDto)),
      map(preparationDto => this.mapToPreparation(preparationDto))
    );
  }
  //
  // private mapCreatePreparation(createPreparation: CreatePreparation): Observable<CreatePreparationDto> {
  //   const preparation$ = this.mapToPreparationDto$(createPreparation.preparation).pipe(tap(item => console.log('preparation', item)), shareReplay())
  //   const preparationItems$ = forkJoin(createPreparation.preparationItems.map(preparationItem => this.mapToStockPreparationItemDto$(preparationItem)))
  //     .pipe(tap(item => console.log('preparationItems', item)), shareReplay());
  //   const distributions$ = forkJoin(createPreparation.distributions.map(distribution => this.mapToDistribution$(distribution)))
  //     .pipe(tap(item => console.log('distributions', item)), shareReplay());
  //
  //   return combineLatest([preparation$, preparationItems$, distributions$]).pipe(
  //     map(([preparation, preparationItems, distributions]) => ({
  //       ...createPreparation,
  //       preparation: preparation,
  //       preparationItems: preparationItems,
  //       distributions: distributions
  //     })));
  // }

  private mapToPreparationDto$(preparation: Preparation): Observable<PreparationDto> {
    return combineLatest([preparation.warehouse$, preparation.company$]).pipe(
      map(([warehouse, company]) => ({
        ...preparation,
        companyId: company.id,
        warehouseId: warehouse.id
      })));
  }

  private mapToDistribution$(preparationDistributionLine: PreparationDistributionLine): Observable<PreparationDistributionLineDto> {
    const organization$ = preparationDistributionLine.organization$;
    const preparation$ = preparationDistributionLine.preparation$;
    return combineLatest([organization$, preparation$]).pipe(
      map(([organization, preparation]) => ({
        ...preparationDistributionLine,
        organizationId: organization.id,
        preparationId: preparation.id
      })));
  }

  private mapToStockPreparationItemDto$(stockPreparationItem: PreparationSelectionItem) {
    return stockPreparationItem.stock$.pipe(
      take(1),
      map(stock => ({
        ...stockPreparationItem,
        stockId: stock.id
      })));
  }

  createPreparationLines$(preparation: Preparation) {
    return this.httpClient.post<void>(`${environment.apiUrl}/preparations/${preparation.id}/distributions`, {});
  }
}
