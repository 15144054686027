import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UserDto, UserSearchDto } from '@typedefs/stock-rest';
import { distinctUntilChanged, filter, map, mergeMap, Observable, of, shareReplay } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { UserSearch } from '@model/search/user-search';
import { Pagination } from './pagination';
import { User } from '@model/user';
import { WarehouseService } from './warehouse.service';
import { OrganizationService } from '@services/organization.service';
import { CompanyService } from '@services/company.service';
import { isSameUser } from "@model/comparator/user-comparator";
import { AuthenticationService } from "@services/authentication.service";
import { toSignal } from "@angular/core/rxjs-interop";

type UserPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly currentUser$: Observable<User>;

  constructor(private httpClient: HttpClient,
              private authenticationService: AuthenticationService,
              private warehouseService: WarehouseService,
              private organizationService: OrganizationService,
              private companyService: CompanyService) {
    this.currentUser$ = this.authenticationService.isReady$()
      .pipe(
        filter(ready => ready),
        mergeMap(_ => this.loadCurrentUser$()),
        distinctUntilChanged(isSameUser),
        shareReplay(), // removing this one seems to break stuff in the reception edit dialog
      );
  }

  public findUsers(userSearch: UserSearch, pagination?: UserPagination): Observable<Page<User>> {
    return this.httpClient.post<Page<UserDto>>(`${environment.apiUrl}/users/search`, this.mapToUserSearchDto(userSearch), {params: pagination})
      .pipe(
        map(usersPage => {
          const users: User[] = usersPage.content.map(userDto => this.loadUser(userDto));
          return {
            ...usersPage,
            content: users
          }
        }),
        shareReplay(),
      );
  }

  private loadCurrentUser$() {
    return this.httpClient.get<UserDto>(`${environment.apiUrl}/users/me`).pipe(
      map(userDto => this.loadUser(userDto)),
    );
  }

  public getUser$(id: string) {
    return this.httpClient.get<UserDto>(`${environment.apiUrl}/users/${id}`).pipe(
      map(userDto => this.loadUser(userDto)),
      shareReplay()
    );
  }

  public getCurrentUser$(): Observable<User> {
    return this.currentUser$;
  }

  public getCurrentUserCompany$() {
    return this.currentUser$.pipe(
      mergeMap(user => user.company$),
      shareReplay()
    );
  }

  public getCurrentUserCompany() {
    const currentUserCompany$ = this.getCurrentUserCompany$();
    return toSignal(currentUserCompany$);
  }

  public getDefaultWarehouse$() {
    return this.currentUser$.pipe(
      mergeMap(user => user.warehouse$),
      shareReplay()
    );
  }

  private loadUser(userDto: UserDto): User {
    const company$ = this.companyService.getCompany$(userDto.companyId);

    const userWarehouse$ = of(userDto.warehouseId).pipe(mergeMap(warehouseId => warehouseId ? this.warehouseService.getWarehouse$(userDto.warehouseId) : of(undefined)));
    const companyWarehouse$ = company$.pipe(mergeMap(company => company.warehouse$));
    const userOrCompanyWarehouse$ = userWarehouse$.pipe(
      mergeMap(userWarehouse => userWarehouse ? of(userWarehouse) : companyWarehouse$)
    );

    const organization$ =
      userDto.organizationId ? this.organizationService.getOrganization$(userDto.organizationId) : undefined;
    return {
      ...userDto,
      company$,
      warehouse$: userOrCompanyWarehouse$,
      organization$: organization$,
    };
  }

  private mapToUserSearchDto(userSearch: UserSearch): UserSearchDto {
    return {
      id: userSearch.id,
      userName: userSearch.userName,
      companyId: userSearch.company?.id,
      warehouseSearchDto: userSearch.warehouseSearch ? this.warehouseService.mapToWarehouseSearchDto(userSearch.warehouseSearch) : undefined
    }
  }
}
