import {Component, OnInit} from '@angular/core';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';

@Component({
  selector: 'foodbank-fead-planning-summary',
  templateUrl: './fead-planning-summary.component.html',
  styleUrls: ['./fead-planning-summary.component.scss']
})
export class FeadPlanningSummaryComponent implements OnInit {

  feadPlanningSearch!: FeadPlanningSearch
  relevantOnly = true;

  constructor() {
  }

  ngOnInit(): void {
    const year = new Date().getFullYear();
    this.feadPlanningSearch = {
      year: year,
      startPeriod: `${year}/01`,
      endPeriod: `${year}/52`,
    }
  }

  selectCampaign(campaign: FeadCampaignIdentifier) {
    this.feadPlanningSearch = {
      ...this.feadPlanningSearch,
      year: campaign.year,
    }
  }
}
