import {ZoneSearchDto} from '@typedefs/stock-rest';
import {SafeOmit} from '@model/mapping-utils';
import {Company} from '@model/company';
import {Warehouse} from '@model/warehouse';

export interface ZoneSearch extends SafeOmit<ZoneSearchDto, 'companyId' | 'warehouseId'> {

  company?: Company;
  warehouse?: Warehouse;

}

export function zoneSearchByCompany(company: Company): ZoneSearch {
  return {
    company
  }
}
export function zoneSearchByCompanyAndWarehouse(company: Company, warehouse: Warehouse): ZoneSearch {
  return {
    ...zoneSearchByCompany(company),
    warehouse
  }
}
export function zoneSearchByCompanyAndWarehouseAvailable(company: Company, warehouse: Warehouse): ZoneSearch {
  return {
    ...zoneSearchByCompanyAndWarehouse(company, warehouse),
    available: true
  }
}
