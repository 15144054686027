import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {combineLatest, map, Observable, shareReplay, switchMap, take} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {WarehouseService} from './warehouse.service';
import {ReceptionSearch} from '@model/search/reception-search';
import {Company} from '@model/company';
import {Reception} from '@model/reception';
import {ReceptionDto, ReceptionSearchDto} from '@typedefs/stock-rest';
import {CompanyService} from '@services/company.service';
import {SupplierService} from '@services/supplier.service';

export type ReceptionPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class ReceptionService {

  constructor(private httpClient: HttpClient,
              private warehouseService: WarehouseService,
              private companyService: CompanyService,
              private supplierService: SupplierService) {
  }

  getReception$(id: number): Observable<Reception> {
    return this.httpClient.get<ReceptionDto>(`${environment.apiUrl}/receptions/${id}`)
      .pipe(
        map(reception => this.mapToReception(reception)),
        shareReplay()
      );
  }

  getInterWarehouseReceptionCount$(companyId: string, warehouseId?: number): Observable<number> {
    const params: any = warehouseId ? {companyId, warehouseId} : {companyId};

    return this.httpClient.get<number>(`${environment.apiUrl}/receptions/inter-warehouse/count`, {params})
      .pipe(
        take(1),
        shareReplay(1)
      );
  }

  saveReception(reception: Reception): Observable<Reception> {
    const receptionDto$ = this.mapToReceptionDto$(reception);

    return receptionDto$.pipe(
      switchMap(receptionDto => this.httpClient.post<ReceptionDto>(`${environment.apiUrl}/receptions`, receptionDto)),
      map(receptionDto => this.mapToReception(receptionDto))
    );
  }

  private mapToReceptionDto$(reception: Reception): Observable<ReceptionDto> {
    return combineLatest([reception.warehouse$, reception.supplier$, reception.company$]).pipe(
      map(([warehouse, supplier, company]) => {
        return {
          id: reception.id,
          warehouseId: warehouse.id,
          supplierId: supplier.id,
          companyId: company.id,
          date: reception.date,
          valid: reception.valid
        } as ReceptionDto
      })
    )
  }

  public find(receptionSearch: ReceptionSearch, pagination?: ReceptionPagination): Observable<Page<Reception>> {
    const receptionSearchDto = this.mapToReceptionSearchDto(receptionSearch);
    return this.httpClient.post<Page<ReceptionDto>>(`${environment.apiUrl}/receptions/search`, receptionSearchDto, {params: pagination})
      .pipe(map(receptionPage => {
        return {
          ...receptionPage,
          content: receptionPage.content.map(reception => this.mapToReception(reception))
        }
      }));
  }

  private loadCompany$(reception: ReceptionDto): Observable<Company> {
    const companyId = reception.companyId;
    return this.companyService.getCompany$(companyId);
  }

  private loadWarehouse$(reception: ReceptionDto) {
    const warehouseId = reception.warehouseId;
    return this.warehouseService.getWarehouse$(warehouseId);
  }

  private loadSupplier$(reception: ReceptionDto) {
    const supplierId = reception.supplierId;
    return this.supplierService.getSupplier$(supplierId);
  }

  private mapToReception(reception: ReceptionDto): Reception {
    return {
      ...reception,
      warehouse$: this.loadWarehouse$(reception),
      supplier$: this.loadSupplier$(reception),
      company$: this.loadCompany$(reception),
    };
  }

  public mapToReceptionSearchDto(receptionSearch: ReceptionSearch): ReceptionSearchDto {
    return {
      ...receptionSearch,
      companyId: receptionSearch.company?.id,
      warehouseId: receptionSearch.warehouse?.id,
      supplierId: receptionSearch.supplier?.id,
      warehouseSearchDto: receptionSearch.warehouseSearch ? this.warehouseService.mapToWarehouseSearchDto(receptionSearch.warehouseSearch) : undefined,
      supplierSearchDto: receptionSearch.supplierSearch ? this.supplierService.convertToSearchDto(receptionSearch.supplierSearch) : undefined,
    }
  }

  delete(reception: Reception) {
    return this.httpClient.delete(`${environment.apiUrl}/receptions/${reception.id}`);
  }
}
