import {Component, OnInit} from '@angular/core';
import {Warehouse} from '@model/warehouse';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {BehaviorSubject, combineLatestWith, distinctUntilChanged, filter, map, mergeMap, Observable} from 'rxjs';
import {UserService} from '@services/user.service';
import {Company} from '@model/company';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ArticleSearch} from '@model/search/article-search';
import {Article} from '@model/article';
import {FeadPlanningByOrganization} from '@model/fead-planning-by-organization';
import {ArticleFeadPlanningService} from '@services/article-fead-planning.service';
import {FeadCampaignService} from '@services/fead-campaign.service';
import {FeadCampaign} from '@model/fead-campaign';
import {Page} from '@typedefs/page';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';
import {CompanyStatus} from '@typedefs/stock-rest';
import {StockService} from '@services/stock.service';
import {StockSearch} from '@model/search/stock-search';

@Component({
  selector: 'foodbank-fead-planning-article',
  templateUrl: './fead-planning-article.component.html',
  styleUrls: ['./fead-planning-article.component.scss']
})
export class FeadPlanningArticleComponent implements OnInit {

  // user selected or entered values
  warehouseSearch$: Observable<WarehouseSearch> | undefined;
  articlesByCampaign: ArticleSearch | undefined;

  warehouse$: BehaviorSubject<Warehouse | undefined> = new BehaviorSubject<Warehouse | undefined>(undefined);
  campaign$: BehaviorSubject<FeadCampaignIdentifier | undefined> = new BehaviorSubject<FeadCampaignIdentifier | undefined>(undefined);
  associationType$: BehaviorSubject<CompanyStatus | undefined> = new BehaviorSubject<CompanyStatus | undefined>('NON_PROFIT');
  article$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);
  round$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  // loaded data
  planningData$: Observable<FeadPlanningByOrganization[]> | undefined;
  articleRoundOptions$ = new BehaviorSubject<number[]>([1]);
  articleStockData$ = new BehaviorSubject({quantity: 0, pallets: 0, parcels: 0});

  updatedData: FeadPlanningByOrganization[] | undefined;

  constructor(
    private userService: UserService,
    private articleFeadPlanningService: ArticleFeadPlanningService,
    private feadCampaignService: FeadCampaignService,
    private stockService: StockService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {

  }

  ngOnInit(): void {
    const currentUser$ = this.userService.getCurrentUser$();
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    defaultWarehouse$.subscribe(defaultWarehouse => this.selectWarehouse(defaultWarehouse!));
    this.warehouseSearch$ = currentUser$.pipe(
      mergeMap(user => user.company$),
      map(company => this.getWarehouseSearch(company))
    );

    const selectedWarehouse = this.warehouse$.pipe(filter(warehouse => !!warehouse));
    const selectedAssociationType = this.associationType$.pipe(filter(associationType => !!associationType));
    const selectedCampaign = this.campaign$.pipe(filter(campaign => !!campaign));
    const selectedArticle = this.article$.pipe(filter(article => !!article));
    const selectedRound = this.round$.pipe(filter(round => !!round));

    selectedWarehouse.pipe(
      combineLatestWith(selectedArticle),
      distinctUntilChanged()
    )
      .subscribe(([warehouse, article]) => {
        this.loadArticleStockInfo(article!, warehouse!);
      });

    selectedWarehouse.pipe(
      combineLatestWith(selectedCampaign, selectedArticle),
      distinctUntilChanged()
    )
      .subscribe(([warehouse, campaign, article]) => {
        this.loadRoundOptions(warehouse!.id, article!.id, campaign!.year);
        this.loadArticleStockInfo(article!, warehouse!);
      });

    selectedWarehouse.pipe(
      combineLatestWith(selectedAssociationType, selectedCampaign, selectedArticle, selectedRound),
      distinctUntilChanged())
      .subscribe(([warehouse, associationType, campaign, article, round]) => {
        this.fetchPlanningData(warehouse!, associationType!, campaign!.year, article!, round!);
      });
  }

  selectWarehouse(warehouse?: Warehouse) {
    this.warehouse$.next(warehouse);
  }

  selectArticle(article: Article) {
    this.article$.next(article);
  }

  selectCampaign(campaign: FeadCampaignIdentifier) {
    this.createArticleSearch(campaign);
    this.campaign$.next(campaign)
  }

  selectAssociationType(associationType: CompanyStatus) {
    this.associationType$.next(associationType)
  }

  selectRound(round: number) {
    this.round$.next(round);
  }

  save() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to save this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Save',
      rejectLabel: 'Cancel',
      accept: () => {
        const warehouse = this.warehouse$.getValue()!;
        const year = this.campaign$.getValue()!.year;
        const article = this.article$.getValue()!;
        const planningData = this.updatedData!;
        this.articleFeadPlanningService.saveArticleFeadPlanning$(warehouse, year, article, planningData)
          .subscribe(() => {
            this.messageService.add({severity: 'success', summary: 'Saved', detail: 'Planning data has been saved.'});
            this.reloadPlanningData();
          });
      }
    });
  }

  cancel() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Reload',
      rejectLabel: 'Stay',
      accept: () => {
        this.reloadPlanningData();
        this.messageService.add({severity: 'warn', summary: 'Canceled', detail: 'Planning data has been reset.'});
      }
    });
  }

  delete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.articleFeadPlanningService.deleteArticlePlanning$(this.warehouse$.getValue()!, this.campaign$.getValue()!.year, this.article$.getValue()!)
          .subscribe(() => {
            this.messageService.add({severity: 'info', summary: 'Deleted', detail: 'Planning data has been reset.'});
            this.reloadPlanningData();
          });
      }
    });
  }

  private createArticleSearch(campaign?: FeadCampaignIdentifier) {
    this.articlesByCampaign = this.getArticleSearch(campaign);
  }

  private getWarehouseSearch(company: Company): WarehouseSearch {
    return {
      company: company
    }
  }

  private getArticleSearch(campaign?: FeadCampaignIdentifier): ArticleSearch {
    return {
      feadYear: campaign?.year
    }
  }

  private reloadPlanningData() {
    this.fetchPlanningData(this.warehouse$.getValue()!, this.associationType$.getValue()!, this.campaign$.getValue()!.year, this.article$.getValue()!, this.round$.getValue()!);
  }

  private fetchPlanningData(warehouse: Warehouse, companyStatus: CompanyStatus, year: number, article: Article, round: number) {
    this.planningData$ = this.articleFeadPlanningService.getArticleFeadPlanning$(warehouse, companyStatus, year, article, round);
    this.planningData$.subscribe(data => this.planningDataUpdates(data));
  }

  planningDataUpdates(planningData: FeadPlanningByOrganization[]) {
    this.updatedData = planningData;
  }

  private loadRoundOptions(organizationId: number, articleId: string, year: number) {
    const pagination = {size: 1, page: 1};
    const feadCampaignSearch = {organizationId, articleId, year};

    this.feadCampaignService.findFeadCampaigns$(pagination, feadCampaignSearch)
      .pipe(map(page => this.getCampaignRounds(page)))
      .subscribe(campaignRounds => {
        this.articleRoundOptions$.next(campaignRounds);

        if (campaignRounds.length > 0) {
          this.round$.next(campaignRounds[0]);
        }
      });
  }

  private getCampaignRounds(page: Page<FeadCampaign>): number[] {
    const pageRounds = page.content.length > 0 && !!page.content[0] ? page.content[0].rounds : 0
    const campaignRounds = pageRounds < 1 ? 1 : pageRounds;
    return Array.from({length: campaignRounds}, (_, i) => i + 1);
  }

  private loadArticleStockInfo(article: Article, warehouse: Warehouse) {
    const stockSearch: StockSearch = {
      warehouseSearch: {
        warehouses: [ warehouse ]
      },
      articleSearch: {
        articles: [ article ]
      }
    };

    const pagination = {page: 0, size: 1000};
    const articleStockData$ = this.stockService.findStock(stockSearch, pagination)

    articleStockData$.subscribe(stockPage => {
      const quantity = stockPage.content.reduce((acc, stock) => acc + stock.quantity / stock.unitWeight, 0)
      const parcels = stockPage.content.reduce((acc, stock) => acc + stock.quantity / stock.unitsPerParcel, 0)

      this.articleStockData$.next({
        quantity, parcels,
        pallets: stockPage.totalElements
      });
    });
  }
}
