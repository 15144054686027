import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {Bank} from '@model/bank';
import {BankDto, BankSearchDto} from '@typedefs/stock-rest';
import {BankSearch} from '@model/search/bank-search';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(private httpClient: HttpClient) {
  }

  public getBank$(id: number): Observable<Bank> {
    return this.httpClient.get<BankDto>(`${environment.apiUrl}/banks/${id}`)
      .pipe(
        map(bankDto => this.mapToBank$(bankDto)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  public findBanks$(bankSearch: BankSearch, pagination?: Pagination): Observable<Page<Bank>> {
    const bankSearchDto = this.mapToBankSearchDto(bankSearch);
    return this.httpClient.post<Page<BankDto>>(`${environment.apiUrl}/banks/search`, bankSearchDto, {params: pagination})
      .pipe(map(bankPage => this.loadBankPage(bankPage)));
  }

  private loadBankPage(bankDtoPage: Page<BankDto>): Page<Bank> {
    return {
      ...bankDtoPage,
      content: this.loadBankDetailsList(bankDtoPage.content)
    };
  }

  public loadBankDetailsList(bankDtos: BankDto[]) {
    return bankDtos.map(bank => this.mapToBank$(bank));
  }

  updateBank(bank: BankDto): Observable<BankDto> {
    return this.httpClient.put<BankDto>(`${environment.apiUrl}/banks/${bank.id}`, bank)
  }

  public mapToBank$(bankDto: BankDto): Bank {
    return {
      ...bankDto,
    };
  }

  mapToBankSearchDto(bankSearch: BankSearch): BankSearchDto {
    return {
      nameContains: bankSearch.nameContains,
      id: bankSearch.id,
    }
  }

}
