import {inject, Injectable} from '@angular/core';
import {ReceptionPalletCluster} from '@model/reception-pallet-cluster';
import {ReceptionPalletClusterDto, ReceptionPalletClusterSearchDto} from "@typedefs/stock-rest";
import { combineLatest, map, Observable, shareReplay, switchMap, take } from "rxjs";
import {Page} from "@typedefs/page";
import {environment} from "@environments/environment";
import {copyCommonFields} from "@model/mapping-utils";
import {ReceptionItem} from "@model/reception-item";
import { HttpClient } from "@angular/common/http";
import {PalletTypeService} from "@services/pallet-type.service";
import {ReceptionItemService} from "@services/reception-item.service";
import {ReceptionPalletClusterSearch} from "@model/search/reception-pallet-cluster-search";
import {Pagination} from "@services/pagination";
import {PalletType} from "@model/pallet-type";
import {ReceptionPalletClusterSummary} from '@model/reception-pallet-cluster-summary';

@Injectable({
  providedIn: 'root'
})
export class ReceptionPalletClusterService {

  private httpClient = inject(HttpClient);
  private palletTypeService = inject(PalletTypeService);
  private receptionItemService = inject(ReceptionItemService);

  findReceptionPalletClusters(receptionPalletClusterSearch: ReceptionPalletClusterSearch, pagination: Pagination): Observable<Page<ReceptionPalletCluster>> {
    const receptionPalletClusterSearchDto = this.mapToReceptionPalletClusterSearchDto(receptionPalletClusterSearch);
    return this.httpClient.post<Page<ReceptionPalletClusterDto>>(`${environment.apiUrl}/receptions/pallets/search`, receptionPalletClusterSearchDto, {params: pagination}).pipe(
      map(page => ({
          ...page,
          content: page.content.map(palletDto => this.mapToReceptionPalletCluster(palletDto))
        })
      ),
      shareReplay(),
    );
  }

  saveReceptionPalletCluster$(receptionPalletCluster: ReceptionPalletCluster): Observable<ReceptionPalletCluster> {
    const receptionPalletClusterDto$ = this.mapToReceptionPalletClusterDto$(receptionPalletCluster);
    return receptionPalletClusterDto$.pipe(
      switchMap(receptionPalletClusterDto => this.httpClient.post<ReceptionPalletClusterDto>(`${environment.apiUrl}/receptions/pallets`, receptionPalletClusterDto)),
      map(savedReceptionPalletClusterDto => this.mapToReceptionPalletCluster(savedReceptionPalletClusterDto)),
    )
  }

  deleteReceptionPalletCluster$(receptionPalletCluster: ReceptionPalletCluster): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/receptions/pallets/${receptionPalletCluster.id}`);
  }

  private loadReceptionItem$(receptionPalletClusterDto: ReceptionPalletClusterDto): Observable<ReceptionItem> {
    const receptionId = receptionPalletClusterDto.receptionId;
    const receptionItemRank = receptionPalletClusterDto.receptionItemRank;
    return this.receptionItemService.getReceptionItem$(receptionId, receptionItemRank);
  }

  mapToReceptionPalletCluster(receptionPalletClusterDto: ReceptionPalletClusterDto): ReceptionPalletCluster {
    const commonFields: ReceptionPalletClusterDto | ReceptionPalletCluster = copyCommonFields(receptionPalletClusterDto, ['typeId', 'receptionId']);
    return {
      ...commonFields,
      type$: this.loadPalletType$(receptionPalletClusterDto),
      receptionItem$: this.loadReceptionItem$(receptionPalletClusterDto),
      summary$: this.getSummary$(receptionPalletClusterDto),
    };
  }

  mapToReceptionPalletClusterDto$(receptionPalletCluster: ReceptionPalletCluster): Observable<ReceptionPalletClusterDto> {
    const type$ = receptionPalletCluster.type$;
    const receptionItem$ = receptionPalletCluster.receptionItem$;
    const reception$ = receptionItem$.pipe(
      switchMap(receptionItem => receptionItem.reception$)
    );

    return combineLatest([type$, receptionItem$, reception$]).pipe(
      take(1), // only take the first value in case the observables emit multiple values
      map(([type, receptionItem, reception]) => ({
        typeId: type?.id,
        receptionId: reception.id,
        receptionItemRank: receptionItem.rank,
        id: receptionPalletCluster.id,
        numberOfPallets: receptionPalletCluster.numberOfPallets,
        order: receptionPalletCluster.order,
        quantity: receptionPalletCluster.quantity,
        location: receptionPalletCluster.location,
        bestBeforeDate: receptionPalletCluster.bestBeforeDate,
      })),
    );
  }

  mapToReceptionPalletClusterSearchDto(receptionPalletClusterSearch: ReceptionPalletClusterSearch): ReceptionPalletClusterSearchDto {
    return {
      receptionItemSearchDto: {
        receptionId: receptionPalletClusterSearch.receptionItem?.receptionId,
        rank: receptionPalletClusterSearch.receptionItem?.rank,
      },
    };
  }

  private loadPalletType$(receptionPalletClusterDto: ReceptionPalletClusterDto): Observable<PalletType | undefined> {
    return this.palletTypeService.getPalletType$(receptionPalletClusterDto.typeId);
  }

  private getSummary$(receptionPalletClusterDto: ReceptionPalletClusterDto): Observable<ReceptionPalletClusterSummary> {
    const url = `${environment.apiUrl}/receptions/pallets/${receptionPalletClusterDto.id}/summary`;
    return this.httpClient.get<ReceptionPalletClusterSummary>(url).pipe(shareReplay());
  }
}
