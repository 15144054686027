import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'foodbank-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnChanges {

  @Input()
  value?: number;
  @Input()
  decimals = 2;
  @Input()
  decimalsColor?: string;

  integerPart?: number;
  integerPartStr?: string;
  decimalPart?: number;
  decimalPartStr?: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value != undefined) {
      const roundedValue = this.roundToDecimals(this.value, this.decimals);
      this.integerPart = Math.floor(roundedValue);
      this.decimalPart = this.decimalToInt(roundedValue - this.integerPart, this.decimals);
      this.integerPartStr = this.integerPart.toString();
      this.decimalPartStr = this.decimalPart.toString().padStart(this.decimals, '0');
    } else {
      this.integerPartStr = undefined;
      this.decimalPartStr = undefined;
    }
  }

  private decimalToInt(value: number, decimals: number) {
    const factor = Math.pow(10, decimals);
    return Math.floor(value  * factor);
  }

  roundToDecimals(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  }

}
