import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Directive} from '@angular/core';

@Directive({
  selector: '[validWeek][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: WeekValidatorDirective,
      multi: true,
    },
  ],
})
export class WeekValidatorDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && /^\d{4}\/\d{1,2}$/.test(control.value)) {
      const parts = control.value.split('/');
      const [year, week] = [...parts].map(part => parseInt(part));

      if (year >= 2000 && year <= 2100 && week >= 1 && week <= 52) {
        return null; // Valid
      }
    }

    return {invalidWeekIndex: true}; // Invalid
  }
}
