import {Injectable} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {BehaviorSubject, from, map, mergeMap, Observable} from 'rxjs';
import {OidcConfigurationDto} from '@typedefs/stock-rest';
import {environment} from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private ready$ = new BehaviorSubject(false);

  constructor(private oauthService: OAuthService,
              private httpClient: HttpClient) {

  }

  public authenticate$(): Observable<boolean> {
    this.getAuthConfig$().pipe(
      mergeMap(oidcConfigurationDto => this.authenticateWithConfig$(oidcConfigurationDto)),
    ).subscribe(_ => this.ready$.next(true))
    return this.ready$;
  }

  public logout() {
    this.oauthService.logOut();
    this.ready$.next(false);
  }

  public isReady$(): Observable<boolean> {
    return this.ready$;
  }

  private authenticateWithConfig$(authConfig: AuthConfig): Observable<void> {
    this.oauthService.configure(authConfig);
    const promise: Promise<void> = this.oauthService.loadDiscoveryDocumentAndLogin()
      .then(_ => this.oauthService.setupAutomaticSilentRefresh());

    return from(promise);
  }

  private getAuthConfig$(): Observable<AuthConfig> {
    return this.getOidcConfiguration$()
      .pipe(
        map(oidcConfigurationDto => this.getAuthConfig(oidcConfigurationDto))
      );
  }

  private getOidcConfiguration$(): Observable<OidcConfigurationDto> {
    return this.httpClient.get<OidcConfigurationDto>(`${environment.apiUrl}/config/oidc`);
  }

  private getAuthConfig(oidcConfigurationDto: OidcConfigurationDto): AuthConfig {
    return {
      // Url of the Identity Provider
      issuer: oidcConfigurationDto.issuer,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin + '/stock/app/', // hardcoded for now, we'll see

      // The SPA's id. The SPA is registerd with this id at the auth-server
      // clientId: 'server.code',
      clientId: oidcConfigurationDto.clientId,

      // Just needed if your auth server demands a secret. In general, this
      // is a sign that the auth server is not configured with SPAs in mind
      // and it might not enforce further best practices vital for security
      // such applications.
      // dummyClientSecret: 'secret',

      responseType: 'code',

      // set the scope for the permissions the client should request
      // The first four are defined by OIDC.
      // Important: Request offline_access to get a refresh token
      // The api scope is a usecase specific one
      scope: 'openid profile email offline_access',

      showDebugInformation: true,
      requireHttps: false, // for localdev on k8s with custom domain over http, for now
    }
  }
}
