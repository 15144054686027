<p-table #dt [value]="(((receptionPage$ | async)?.content) || [])" responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="((receptionPage$ | async)?.totalElements || 0)"
         [globalFilterFields]="['ID']" [paginator]="true" [rowsPerPageOptions]="[50,100,200]"
         [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true" (onLazyLoad)="lazyLoad($event)" [rowHover]="true" [resizableColumns]="true" columnResizeMode="expand" sortMode="multiple"
         [autoLayout]="true" dataKey="id" [filters]="filters"
         [multiSortMeta]="[{ field: 'SUPPLIER', order: 1 },{ field: 'WAREHOUSE', order: 1 },{ field: 'DATE', order: -1 }]"
         styleClass="p-datatable-sm p-datatable-striped">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div class="grid">
        <h2 class="col-fixed m-0 mt-2" i18n>Receptions</h2>
        <p-button class="col-fixed mt-2" icon="pi pi-plus" label="New reception" (click)="openNewReceptionDialog()"
                  [rounded]="true"></p-button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th style="width: 5rem"></th>
      <th pSortableColumn="ID">
        <span i18n>Reception</span>
        <p-sortIcon field="ID"></p-sortIcon>
      </th>
      <th pSortableColumn="SUPPLIER">
        <span i18n>Supplier</span>
        <p-sortIcon field="SUPPLIER"></p-sortIcon>
      </th>
      <th pSortableColumn="WAREHOUSE">
        <span i18n>Warehouse</span>
        <p-sortIcon field="WAREHOUSE"></p-sortIcon>
      </th>
      <th pSortableColumn="DATE">
        <span i18n>Date</span>
        <p-sortIcon field="DATE"></p-sortIcon>
      </th>
      <th pSortableColumn="VALID" style="min-width: 185px">
        <span i18n>Status</span>
      </th>
    </tr>
    <tr>
      <th></th>
      <th>
        <p-columnFilter field="id" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-id let-filter="filterCallback">
            <input pInputText type="text" placeholder="Id..." style="width: 100px;" id="reception-id-filter" name="reception-id-filter"
                   [ngModel]="id" [ngModelOptions]="{ updateOn: 'change' }"
                   (ngModelChange)="filter($event)" ngDefaultControl/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="supplier" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-supplier let-filter="filterCallback">
            <ng-container *ngIf="(supplierSearch$ | async) as supplierSearch">
              <foodbank-supplier-selection
                ngDefaultControl
                id="supplier-filter" name="supplier-filter"
                [ngModel]="supplier"
                [supplierSearch]="supplierSearch"
                (ngModelChange)="filter($event)"
              ></foodbank-supplier-selection>
            </ng-container>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="warehouse" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-warehouse let-filter="filterCallback">
            <ng-container *ngIf="(warehouseSearch$ | async) as warehouseSearch">
              <foodbank-warehouse-selection id="warehouse-filter" name="warehouse-filter"
                                            [ngModel]="warehouse"
                                            [warehouseSearch]="warehouseSearch"
                                            (ngModelChange)="filter($event)"
                                            ngDefaultControl
              ></foodbank-warehouse-selection>
            </ng-container>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="date" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-rangeDate let-filter="filterCallback">
            <foodbank-date-picker [inputId]="'foodbank-reception-list-date-picker'" [ngModel]="rangeDate?.value"
                                  id="date-filter" name="date-filter"
                                  (onSelect)="filter($event)" ngDefaultControl/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="valid" matchMode="in" [showMenu]="false" [showClearButton]="false">
          <ng-template pTemplate="filter" let-valid let-filter="filterCallback">
            <p-dropdown [options]="validOptions" id="status-filter" name="status-filter" ngDefaultControl
                        [ngModel]="valid" (ngModelChange)="filter($event)" optionLabel="name" appendTo="body" dataKey="value"></p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-reception let-expanded="expanded">
    <tr [pSelectableRow]="reception">
      <td>
        <button #rowToggleButton type="button" pButton pRipple [pRowToggler]="reception"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
      </td>
      <td class="id-col">
        {{ reception.id }}
      </td>
      <td>
        <foodbank-supplier ngDefaultControl [ngModel]="(reception.supplier$ | async) || undefined" [showId]="true"></foodbank-supplier>
      </td>
      <td>
        <foodbank-warehouse ngDefaultControl [ngModel]="(reception.warehouse$ | async) || undefined" [showId]="true"></foodbank-warehouse>
      </td>
      <td>
        {{ reception.date }}
      </td>
      <td>
        <div *ngIf="reception.valid">
          <button pButton pRipple icon="pi pi-lock" class="p-button-rounded p-button-success mr-2 pointer-events-auto"
                  (click)="invalidateAfterConfirmation(reception); !expanded ? rowToggleButton.click() : ''"
                  [pTooltip]="reception.tooltipMessage" tooltipPosition="top" [showDelay]="300"
                  [disabled]="reception.validationStatusChangeInProgress || !reception.canBeInvalidated"
                  [loading]="reception.validationStatusChangeInProgress"></button>
          <button pButton pRipple icon="pi pi-print" class="p-button-rounded p-button-primary"
                  (click)="openPrintLabelsDialog(reception)"
                  [pTooltip]="reception.tooltipMessage" tooltipPosition="top" [showDelay]="300"
                  [disabled]="reception.validationStatusChangeInProgress"
                  [loading]="reception.validationStatusChangeInProgress"
          ></button>
        </div>
        <div *ngIf="!reception.valid" class="flex">
          <span>
            <button pButton pRipple icon="pi pi-lock-open" class="p-button-rounded p-button-info mr-2"
                    (click)="validate(reception, rowToggleButton);"></button>
          </span>
          <div class="fadeinleft fadeoutrigth animation-duration-200 animation-iteration-1 transition-linear">
            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(reception)"></button>
            <button pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-secondary mr-2" (click)="openNewReceptionItemDialog(reception); !expanded ? rowToggleButton.click() : ''"></button>
            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" (click)="deleteReception(reception)"></button>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-reception>
    <ng-container *ngIf="identity(reception) as reception">
      <tr>
        <td colspan="6">
          <div class="p-1">
            <p-tabView>
              <p-tabPanel>
                <ng-template pTemplate="header"><i class="pi pi-list mr-2"></i>Items</ng-template>
                <foodbank-reception-item-list [reception]="reception"></foodbank-reception-item-list>
              </p-tabPanel>
              <p-tabPanel [disabled]="!reception.valid">
                <ng-template pTemplate="header">
                  <div style="pointer-events: auto" pTooltip="Available for valid receptions" tooltipPosition="top" [showDelay]="300"><i class="fa-solid fa-boxes-stacked mr-2"></i>Stock</div>
                </ng-template>
                <ng-template pTemplate="content">
                  <foodbank-stock-list ngDefaultControl [stockSearch]="{ receptionId: reception.id }" emptyMessage="All stock has been redistributed. See Movements tab for details"></foodbank-stock-list>
                </ng-template>
              </p-tabPanel>
              <p-tabPanel>
                <ng-template pTemplate="header"><i class="fa-solid fa-arrow-right-arrow-left mr-2"></i>Movements</ng-template>
                <ng-template pTemplate="content">
                  <foodbank-movement-list ngDefaultControl [movementSearch]="{reception: reception}"></foodbank-movement-list>
                </ng-template>
              </p-tabPanel>
            </p-tabView>
          </div>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
