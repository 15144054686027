import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, mergeMap, Observable, shareReplay, tap } from 'rxjs';
import {SupplierSearch} from '@model/search/supplier-search';
import {Supplier} from '@model/supplier';
import {SupplierService} from '@services/supplier.service';
import {SupplierSearchDto} from "@typedefs/stock-rest";
import {ComponentChanges} from "@util/component-change";

@Component({
  selector: 'foodbank-supplier-selection',
  exportAs: 'supplierSelection',
  templateUrl: './supplier-selection.component.html',
  styleUrls: ['./supplier-selection.component.scss']
})
export class SupplierSelectionComponent implements OnInit, OnChanges {

  @Input()
  ngModel?: Supplier;
  @Input()
  required: boolean = false;
  @Input()
  supplierSearch?: SupplierSearch; // you could for example create a supplier filter to get suppliers of a specific user
  supplierSearch$!: BehaviorSubject<SupplierSearch>;
  @Output()
  ngModelChange: EventEmitter<Supplier> = new EventEmitter<Supplier>();

  suppliers$?: Observable<Supplier[]>;

  searchFilter = '';
  searchFilter$!: BehaviorSubject<SupplierSearchDto>;

  loading = true;

  constructor(private supplierService: SupplierService) {
  }

  ngOnInit(): void {
    this.supplierSearch$ = new BehaviorSubject<SupplierSearch>(this.supplierSearch || {});

    this.searchFilter$ = new BehaviorSubject<SupplierSearchDto>({nameContains: this.searchFilter});

    this.suppliers$ = combineLatest([this.supplierSearch$, this.searchFilter$]).pipe(
      debounceTime(100),
      map(([supplierSearch, searchFilter]) => ({...supplierSearch, ...searchFilter})),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      mergeMap(supplierSearch =>
        this.supplierService.findSuppliers$(supplierSearch, {page: 0, size: 1000})),
      tap(() => this.loading = false),
      map(page => page.content),
      shareReplay()
    );
  }

  ngOnChanges(changes: ComponentChanges<SupplierSelectionComponent>): void {
    if (changes.supplierSearch && this.supplierSearch$) {
      this.supplierSearch$.next(this.supplierSearch || {})
    }
  }

  select(supplier: Supplier) {
    this.ngModel = supplier;
    this.ngModelChange.emit(supplier);
  }

  clear() {
    this.ngModel = undefined;
    this.ngModelChange.emit(undefined);
  }

  handleSearchFilter() {
    this.searchFilter$.next({nameContains: this.searchFilter});
  }
}
