import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map, Observable} from 'rxjs';
import {ArticleCategorySearch} from '@model/search/article-category-search';
import {ArticleCategory} from '@model/article-category';
import {ArticleCategoryService} from '@services/article-category.service';

@Component({
  selector: 'foodbank-article-category-selection',
  templateUrl: './article-category-selection.component.html',
  styleUrls: ['./article-category-selection.component.scss']
})
export class ArticleCategorySelectionComponent implements OnInit {

  @Input()
  selectionMode: 'multiple' | 'single' = 'multiple';
  @Input()
  selectedArticleCategory: ArticleCategory | undefined | null;
  @Output()
  selectedArticleCategoryChange: EventEmitter<ArticleCategory> = new EventEmitter<ArticleCategory>();

  @Input()
  selectedArticleCategories: ArticleCategory[] = [];
  @Input()
  articleCategorySearch: ArticleCategorySearch = {};
  @Output()
  onArticleCategorySelected: EventEmitter<ArticleCategory[]> = new EventEmitter<ArticleCategory[]>();

  articleCategories$: Observable<ArticleCategory[]> | undefined | null;

  constructor(private articleCategoryService: ArticleCategoryService) {
  }

  ngOnInit(): void {
    this.articleCategories$ = this.articleCategoryService.findArticleCategories$(this.articleCategorySearch, {page: 0, size: 1000})
      .pipe(map(page => page.content));
  }

  select(selectedArticleCategories: ArticleCategory[]) {
    // const articleCategories = this.articleCategories.filter(articleCategory => articleCategoryIds.includes(articleCategory.id));
    this.selectedArticleCategories = selectedArticleCategories;
    this.onArticleCategorySelected.emit(selectedArticleCategories);
  }

  selectOne(selectedArticleCategoryEvent: ArticleCategory) {
    this.selectedArticleCategory = selectedArticleCategoryEvent;
    this.selectedArticleCategoryChange.emit(selectedArticleCategoryEvent);
  }

  clear() {
    this.selectedArticleCategories = [];
    this.onArticleCategorySelected.emit([]);
  }
}
