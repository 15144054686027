<ng-container *ngIf="(preparation$ | async) as preparation">
  <foodbank-fead-preparation
    [preparation]="preparation"
  >
  </foodbank-fead-preparation>
  <foodbank-fead-preparation-details
    [preparation]="preparation"
    [expedition]="expedition"
  >
  </foodbank-fead-preparation-details>
</ng-container>
