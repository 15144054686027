<div class="card p-fluid" *ngIf="article">
    <div class="field grid">
        <label for="article-id" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Id</label>
        <div class="col-12 md:col-10">
            <input id="article-id" type="text" pInputText [(ngModel)]="article.id" [disabled]="true">
        </div>
    </div>
    <div class="field grid">
        <label for="article-name-nl" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Name (NL)</label>
        <div class="col-12 md:col-10">
            <input id="article-name-nl" type="text" pInputText [(ngModel)]="article.nameNl">
        </div>
    </div>
    <div class="field grid">
        <label for="article-name-fr" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Name (FR)</label>
        <div class="col-12 md:col-10">
            <input id="article-name-fr" type="text" pInputText [(ngModel)]="article.nameFr">
        </div>
    </div>
    <div class="field grid">
        <label for="article-category" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Category</label>
        <div class="col-12 md:col-10">
            <foodbank-article-category-selection id="article-category" [selectedArticleCategory]="articleCategory$ | async" (selectedArticleCategoryChange)="updateArticleCategoryId($event)" selectionMode="single"></foodbank-article-category-selection>
        </div>
    </div>
    <div class="field grid">
        <label for="article-storage-condition" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Storage condition</label>
        <div class="col-12 md:col-10">
            <foodbank-article-storage-condition-selection id="article-storage-condition" [selectedArticleStorageCondition]="articleStorageCondition$ | async" (selectedArticleStorageConditionChange)="updateArticleStorageConditionId($event)" selectionMode="single"></foodbank-article-storage-condition-selection>
        </div>
        <!--        <foodbank-article-category-selection id="article-category" [sel]="article.category"></foodbank-article-category-selection>-->
    </div>
    <div class="field grid">
        <label for="article-fead" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Campaign</label>
        <div class="col-12 md:col-10">
          <div class="flex align-items-center">
            <p-inputSwitch id="article-fead" class="mr-2" [(ngModel)]="article.fead"></p-inputSwitch>
            <span>{{ article.fead ? 'Yes' : 'No' }}</span>
          </div>
        </div>
    </div>
    <ng-container *ngIf="article.fead">
        <div class="field grid">
            <label for="article-fead-year" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Year</label>
            <div class="col-12 md:col-10">
                <input id="article-fead-year" type="text" pInputText [(ngModel)]="article.feadYear">
            </div>
        </div>
        <div class="field grid">
            <label for="article-fead-weight-unit" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Net weight/unit</label>
            <div class="col-12 md:col-10">
                <input id="article-fead-weight-unit" type="text" pInputText [(ngModel)]="article.feadUnitWeight">
            </div>
        </div>
        <div class="field grid">
            <label for="article-fead-gross-weight-unit" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Gross weight/unit</label>
            <div class="col-12 md:col-10">
                <input id="article-fead-gross-weight-unit" type="text" pInputText [(ngModel)]="article.feadUnitGrossWeight">
            </div>
        </div>
        <div class="field grid">
            <label for="article-fead-u-cart" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Units/cart</label>
            <div class="col-12 md:col-10">
                <input id="article-fead-u-cart" type="text" pInputText [(ngModel)]="article.feadUnitsPerParcel">
            </div>
        </div>
        <div class="field grid">
            <label for="article-fead-cart-pallete" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Carts/pallet</label>
            <div class="col-12 md:col-10">
                <input id="article-fead-cart-pallete" type="text" pInputText [(ngModel)]="article.feadParcelsPerPallet">
            </div>
        </div>
        <div class="field grid">
            <label for="article-fead-bank-delivery" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Bank delivery</label>
            <div class="col-12 md:col-10">
                <p-inputSwitch id="article-fead-bank-delivery" [trueValue]="1" [falseValue]="0" [(ngModel)]="article.alwaysDeliveredByBank"></p-inputSwitch> <label for="article-fead-bank-delivery">{{ article.alwaysDeliveredByBank ? 'Yes' : 'No' }}</label>
            </div>
        </div>
    </ng-container>
    <div class="field grid">
        <div class="col-offset-3 col-3">
            <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(article)"></p-button>
        </div>
        <div class="col-3">
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"></p-button>
        </div>
    </div>
</div>
