import {trim} from "lodash";

export function notEmpty(value: any) {
  return !!value;
}

export function joinWhenPresent(separator: string = " ", values: string[]) {
  return values
    .filter(notEmpty)
    .map(trim)
    .filter(notEmpty)
    .join(separator);
}

