import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ReceptionItem} from '@model/reception-item';
import {BehaviorSubject, mergeMap, Observable} from 'rxjs';
import {ReceptionItemSummary} from "@model/reception-item-summary";

@Component({
  selector: 'foodbank-reception-item',
  templateUrl: './reception-item.component.html',
  styleUrls: ['./reception-item.component.scss']
})
export class ReceptionItemComponent implements OnInit, OnChanges {

  @Input() receptionItem!: ReceptionItem;

  receptionItem$!: BehaviorSubject<ReceptionItem>;
  receptionItemSummary$!: Observable<ReceptionItemSummary | undefined>;

  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  private initialized = false;

  ngOnInit() {
    this.receptionItem$ = new BehaviorSubject<ReceptionItem>(this.receptionItem);

    this.receptionItemSummary$ = this.receptionItem$.pipe(mergeMap(receptionItem => receptionItem.summary$));
    this.initialized = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.initialized) {
      return;
    }
    if (changes['receptionItem']) {
      const receptionItem = changes['receptionItem'].currentValue;
      this.receptionItem$.next(receptionItem);
    }
    this.refresh();
  }

  private refresh() {
    this.refreshTrigger$.next();
  }

}
