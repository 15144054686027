<ng-container *ngIf="(groups$ | async) as groups">
  <ng-container *ngIf="preparationItemSearch.preparation as preparation">
    <div class="group" *ngFor="let group of groups">
      <div class="preparation-item" *ngIf="group.preparationItem as preparationItem">
        <span class="preparation">
          <img class="logo" src="./assets/layout/images/foodbank.png" alt="Foodbank">
          <span class="title">
            <h2>{{ (preparation.company$ |async)?.compName }}</h2>
          </span>
          <span class="preparation-details">
            <p class="preparation-id">Bon de préparation n° {{ preparation.id }}</p>
            <p class="preparation-date">Date : {{ preparation.date }} / Semaine {{ preparation.location }}</p>
          </span>
          <span class="preparation-warehouse">
            <foodbank-warehouse ngDefaultControl [ngModel]="(preparation.warehouse$ | async) || undefined" [showId]="true"></foodbank-warehouse>
          </span>
        </span>
        <div class="organization">
          <foodbank-organization
            [organization]="group.organization"
            [showId]="false"
            [showAddress]="false  "
          >
          </foodbank-organization>
        </div>
        <div class="article white-space-normal">
          <foodbank-article [showId]="true" [ngModel]="group.article" ngDefaultControl></foodbank-article>
          {{ preparationItem.fullDescription }}
        </div>
        <div class="preparation-item-details" *ngIf="(preparationItem.stock$ | async) as stock">
          <div class="quantity label">
            Nb unités
          </div >
          <div class="quantity aligned-quantity">
            <foodbank-number [value]="preparationItem.quantity"></foodbank-number>
          </div >
          <div class="unitsPerParcel label">
            Nb unités/colis
          </div>
          <div class="unitsPerParcel aligned-quantity">
            <foodbank-number [value]="stock.unitsPerParcel" [decimals]="0"></foodbank-number>
          </div>
          <div class="unitWeight label">
            Poids net/unité (gr)
          </div>
          <div class="unitWeight aligned-quantity">
            <foodbank-number [value]="preparationItem.unitWeight" [decimals]="0"></foodbank-number>
          </div>
          <div class="unitWeight label">
            Poids brut (kg)
          </div>
          <div class="unitWeight aligned-quantity">
            <foodbank-number [value]="stock.unitGrossWeight" [decimals]="0"></foodbank-number>
          </div>
        </div>
        <div class="info">
          Le poids de la palette n’est pas inclus dans le poids brut
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
